export default {
  title: {
    acao_planejamento: 'Ação em Cadastro de Ações',
    acao_ag_analise_planejamento: 'Ação Aguardando Análise no Cadastro de Ações',
    acao_ag_aprovacao_planejamento: 'Ação Aguardando Aprovação no Cadastro de Ações',
    acao_ag_orcamento: 'Ação Aguardando Orçamento',
    acao_orcamento_ag_analise: 'Ação com Orçamento Aguardando Análise',
    acoes_faturamento: 'Ações vs. Faturamento',
    acesso_cliente: 'Acesso cliente',
    acesso_divisao: 'Acesso divisão',
    acesso_estrutura_usuario: 'Acesso estrutura de usuário',
    acao: 'Ação | Ações',
    acompanhamento: 'Acompanhamento',
    acordo: 'Acordo | Acordos',
    acordo_crescimento: 'Acordo de crescimento | Acordos de crescimento',
    acordo_fixo: 'Acordo fixo',
    acordo_metas: 'Acordos e metas',
    acordo_negociacao_preco: 'Acordo Negociação de Preço',
    acordo_positivacao: 'Acordo Positivação',
    acordo_tabloide: 'Acordo tablóide',
    ajustar_acordo: 'Ajustar acordo',
    ajustar_contrato: 'Ajustar contrato',
    ajuste_verba: 'Ajuste de verba',
    ajuste_verba_reducao_massiva: 'Redução de Verba Massiva',
    ano_fiscal: 'Ano fiscal',
    analisar_acordo: 'Analisar acordo',
    analisar_contrato: 'Analisar contrato',
    analise_investimento: 'Análise de Investimento',
    analitico_acoes: 'Analítico de ações',
    anexos: 'Anexos',
    ano: 'Ano',
    aporte: 'Aporte | Aportes',
    aporte_verba: 'Aporte de verbas',
    aporte_verba_cliente: 'Aporte de verbas cliente',
    aporte_verba_divisao: 'Aporte de verbas divisão',
    aprovacoes_contratos: 'Aprovações de contratos',
    apuracao: 'Apuração',
    apuracao_pendente: 'Pend. Apuração',
    apuracao_rejeitada: 'Apuração Rejeitada',
    apuracoes: 'Apurações',
    apurado: 'Apurado',
    aviso: 'Aviso',
    apuracoes_finalizadas: 'Apurações Finalizadas',
    apuracoes_pendentes: 'Apurações Pendentes',
    apuracoes_lote: 'Apurações em lote',
    atencao: 'Atenção',
    atualizacao_entrega: 'Atualização de entrega',
    atualizacao_status_pagamento: 'Atualização do status do pagamento da ação e/ou informações complementares',
    base_calculo: 'Base de cálculo',
    cadastrar: 'Cadastrar',
    cadastrar_contato: 'Cadastrar contato',
    cadastrar_ano_fiscal: 'Cadastrar período',
    cadastrar_nova_area_atuacao: 'Cadastrar área de atuação',
    cadastrar_nova_alcada: 'Cadastrar nova alçada',
    cadastrar_nova_divisao: 'Cadastrar nova divisão',
    cadastrar_novo_canal: 'Cadastrar novo canal',
    cadastrar_novo_cliente: 'Cadastrar novo cliente',
    cadastrar_novo_concorrente: 'Cadastrar novo concorrente',
    cadastrar_novo_centro_custo: 'Cadastrar novo centro custo',
    cadastrar_novo_contato: 'Cadastrar novo contato',
    cadastrar_nova_estrutura_usuario: 'Cadastrar nova estrutura de usuário',
    cadastrar_nova_extensao: 'Cadastrar nova Extensao',
    cadastrar_nova_filial: 'Cadastrar nova Filial',
    cadastrar_novo_funcionario: 'Cadastrar funcionário',
    cadastrar_novo_grupo_produto: 'Cadastrar grupo de produto',
    cadastrar_nova_loja: 'Cadastrar nova loja',
    cadastrar_nova_marca: 'Cadastrar nova marca',
    cadastrar_novo_material: 'Cadastar novo material',
    cadastrar_novo_orcamento: 'Cadastrar novo orçamento',
    cadastrar_nova_peca: 'Cadastrar nova peça',
    cadastrar_novo_planejamento: 'Cadastrar novo planejamento',
    cadastrar_novo_produto: 'Cadastrar novo produto',
    cadastrar_nova_regiao: 'Cadastrar nova região',
    cadastrar_novo_reporte: 'Cadastrar novo reporte',
    cadastrar_novo_segmento: 'Cadastrar novo segmento',
    cadastrar_novo_tipo_acao: 'Cadastrar novo tipo de ação',
    cadastrar_novo_tipo_investimento: 'Cadastrar novo tipo de investimento',
    cadastrar_novo_tipo_orcamento: 'Cadastrar novo tipo de orçamento',
    cadastrar_nova_unidade_medida: 'Cadastrar nova unidade de medida',
    cadastrar_nova_unidade_negocio: 'Cadastrar nova unidade de negócio',
    cadastrar_meta: 'Cadastrar meta',
    cadastrar_intervalo_meta: 'Cadastrar intervalo da meta',
    cadastrar_negociacao: 'Cadastrar negociação',
    cadastrar_pre_usuario: 'Pré-cadastro de usuário',
    cadastrar_usuario: 'Cadastrar usuário',
    cadastro_peca: 'Cadastro de Peças',
    calculo_apuracao: 'Cálculo da apuração',
    calculo_bonificacao: 'Cálculo da bonificação',
    calculo_roi: 'Cálculo de ROI',
    calendario: 'Calendário',
    calendario_aprovacoes: 'Calendário de aprovações',
    campos_customizados: 'Campo customizado | Campos customizados',
    campo_geral: 'Campo geral | Campos gerais',
    campo_geral_planejamento_acao: 'Campo geral | Campos gerais',
    campo_padrao: 'Campo padrão | Campos padrões',
    cancelamento_lote: 'Cancelamento em lote',
    cancelar_acordo: 'Cancelar acordo',
    cancelar_apuracao: 'Cancelar apuração',
    cancelar_contrato: 'Cancelar contrato',
    carteira_cliente: 'Carteira de cliente',
    catalogo_log: 'Log de Download de arquivos',
    categorias: 'Categoria | Categorias',
    comportamento: 'Comportamento',
    cenario: 'Cenário',
    cliente: 'Cliente | Clientes',
    cliente_conta_corrente: 'Cliente Conta Corrente',
    criacao_pagamento: 'Criação do pagamento',
    concluir_pedido_pecas: 'Concluir pedido de peças',
    configuracao: 'Configuração',
    confirmar: 'Confirmar',
    configuracao_orcamento: 'Configuração orçamento',
    configuracao_visao: 'Configuração da visão',
    conta_corrente: 'Conta Corrente',
    consolidado_verbas_aprovadas: 'Consolidado de verbas aprovadas',
    consumo_saldo_orcamento: 'Consumo de saldo Orçamento',
    contato: 'Contato | Contatos',
    contrato: 'Contrato | Contratos',
    contrato_acordos_metas: 'Acordos e metas de crescimento',
    contrato_fechamento: 'Contrato - Fechamento',
    copiar_contrato: 'Copia contrato',
    dados_ajuste: 'Dados do ajuste',
    dados_importacoes: 'Dados das importações',
    dados_orcamento: 'Dados do orçamento',
    dashboard_acao: 'Dashboard de ação | Dashboard de ações',
    desconto_calculo: 'Desconto no cálculo | Descontos no cálculo',
    detalhe_acao_planejamento_acao: 'Detalhes da ação',
    documentos_ag_aprovacao_pagamento: 'Documentos Aguardando Aprovação no Pagamento',
    documentos_ag_analise_pagamento: 'Documentos Aguardando Análise no Pagamento',
    editar: 'Editar',
    editar_acordo: 'Editar acordo',
    editar_acao: 'Editar ação',
    editar_alcada: 'Editar alçada',
    editar_cliente: 'Editar cliente',
    editar_concorrente: 'Editar concorrente',
    editar_divisao: 'Editar divisão',
    editar_centro_custo: 'Editar centro de custo',
    editar_estrutura_usuario: 'Editar estrutura de usuário',
    editar_filial: 'Editar filial',
    editar_funcionario: 'Editar funcionário',
    editar_loja: 'Editar loja',
    editar_grupo_produto: 'Editar grupo de produto',
    editar_contrato: 'Editar contrato',
    editar_material: 'Editar material',
    editar_meta: 'Editar meta',
    editar_intervalo_meta: 'Editar intervalo da meta',
    editar_negociacao: 'Editar negociação',
    editar_peca: 'Editar peça',
    editar_produto: 'Editar produto',
    editar_planejamento: 'Editar planejamento',
    editar_regiao: 'Editar região',
    editar_reporte: 'Editar reporte',
    editar_orcamento: 'Editar orçamento',
    editar_usuario: 'Editar usuário',
    editar_periodo: 'Editar período',
    editar_perfil: 'Editar perfil',
    editar_tipo_investimento: 'Editar tipo de investimento',
    editar_unidade_medida: 'Editar unidade de medida',
    elegibilidade_adesao: 'Elegibilidade de adesão',
    execucao: 'Execução',
    execucao_orcamento: 'Orçamentos de Execução',
    execucao_incluir_pecas: 'Execução - Incluir peças',
    extrato: 'Extrato',
    erro: 'Erro | Erros',
    encerrar_contrato: 'Encerrar contrato',
    encerrar_acordo: 'Encerrar acordo',
    fechamento: 'Fechamento',
    fechamento_ano_fiscal: 'Fechamento de período',
    fechamento_pedido: 'Fechamento de pedido | Fechamento de pedidos',
    filtrar: 'Filtrar',
    filtro: 'Filtro | Filtros',
    filtros_da_entidade: 'Filtros de {entidade}',
    fluxo_aprovacao: 'Fluxo de aprovação',
    foco_da_acao: 'Foco da ação',
    foco_do_contrato: 'Foco do contrato',
    forca_vendas: 'Força de vendas | Forças de vendas',
    forma_bonificacao: 'Forma de bonificação',
    formulario: 'Formulário',
    fornecedor: 'Fornecedor | Fornecedores',
    gestao_aprovacao: 'Gestão De Aprovação',
    grafico_cliente_pagamento_acoes: 'Pagamento - Ações',
    grafico_cliente_top_5: 'TOP 5 Tipos de Ações - Liquidadas',
    heranca_configuracao: 'Herança de configuração',
    hierarquia_usuario: 'Hierarquia de usuário | Hierarquia de usuários',
    historico_aprovacoes: 'Histórico de aprovações',
    holdings_bloqueadas: 'Holdings Bloqueadas',
    inclusao_vendedor_orcamento: 'Inclusão de vendedor no orçamento',
    informacao_financeira: 'Informação financeira | Informações financeiras',
    informe_valor_reservado: 'Informe o valor reservado',
    imagem: 'Imagem',
    impacto_apuracoes_agrupadas: 'Ao confirmar o início do fluxo de a aprovação desta ação "{tipoAcao}" haverá impacto nas apurações de "{tipoAcaoAgrupadora}" listadas abaixo. Tem certeza que deseja continuar?',
    importar_arquivo: 'Importar arquivo',
    inclusao_divisao_rateio: 'Inclusão de divisão no rateio',
    inicio_processo_apuracao: 'Início do processo de apuração',
    integracao_software_terceiro: 'Integração com software terceiro',
    investimento_area_atuacao: 'Investimento por área de atuação',
    investimento_cliente: 'Investimento por Cliente',
    investimento_cultivo: 'Investimento por cultivo',
    investimento_materiais: 'Investimento por materiais',
    investimento_pecas: 'Investimento por peças',
    investimento_vendas: 'Investimento sobre vendas',
    justificativa: 'Justificativa',
    li_aceito_termos_condicoes: 'Li e Aceito Termos e Condições',
    liquidacao: 'Liquidação',
    loja: 'Loja | Lojas',
    marcas: 'Marca | Marcas',
    margemcontribuicao: 'Margem Contribuição ROI',
    material: 'Material | Materiais',
    materiais_a_produzir: 'Material à produzir',
    metas: 'Metas',
    meta_beneficio: 'Meta e benefício | Metas e benefícios',
    metas_crescimento: 'Metas de crescimento',
    minhas_aprovacoes: 'Minhas aprovações',
    minhas_aprovacoes_pendentes: 'Minhas aprovações pendentes',
    minhas_solicitacoes_aprovacoes: 'Minhas solicitações/aprovações',
    motivo: 'Motivo',
    negociacao: 'Negociação',
    negociacao_verbas: 'Negociação de Verbas Comerciais',
    negociacoes: 'Negociações',
    negociacoes_aguardando_aprovacao: 'Negociações aguardando aprovação',
    nota: 'Nota | Notas',
    novo_ajuste: 'Novo ajuste',
    nota_debito: 'Nota de débito | Notas de débito',
    operacao: 'Operação | Operações',
    orcamento: 'Orçamento | Orçamentos',
    orcamento_execucao: 'Orçamento de execução',
    orientacao_geral: 'Orientação geral | Orientações gerais',
    origem_da_acao: 'Origem da ação',
    orcamento_ag_aprovacao: 'Orçamento Aguardando Aprovação',
    pagamento: 'Pagamento | Pagamentos',
    pagamento_acao: 'Pagamento da ação',
    pagamento_exportacao: 'Ações para Pagamento',
    pagamentos_liquidados: 'Pagamentos Liquidados',
    para: 'Para',
    parametrizacao_acao: 'Parametrização de ação',
    parametrizacao_cliente: 'Parametrização de cliente',
    parametrizacao_contrato: 'Parametrização de contrato',
    parametrizacao_orcamento: 'Parametrização de orçamento',
    parametrizacao_periodo: 'Parametrização de período',
    participacao_investimento: 'Participação Sobre Investimento',
    planejado_x_realizado: 'Planejado x Realizado por Período',
    planejamento: 'Planejamento',
    percentual_rebate: 'Percentual de rebate',
    pendencias: 'Pendências',
    pendencia_ajuste_verba: 'Pendência de Ajuste de Verba | Pendências de Ajuste de Verba',
    pendencia_apuracao: 'Pendência de Apuração | Pendências de Apuração',
    pendencia_orcamento: 'Pendência de Orçamento | Pendências de Orçamento',
    pendencia_planejamento: 'Pendência de Planejamento | Pendências de Planejamento',
    peca: 'Peça | Peças',
    periodo: 'Período',
    periodo_apuracao: 'Período de apuração',
    periodo_da_apuracao: 'Período da apuração',
    planejamento_incluir_materiais: 'Planejamento - Incluir materiais',
    plano_comparativo: 'Plano Comparativo',
    produto: 'Produto | Produtos',
    periodo_orcamento: 'Período de orçamento',
    periodo_planejamento: 'Período de planejamento',
    programa_marketing: 'Programa Marketing',
    ranking: 'Ranking',
    ranking_recebimento: 'Ranking de recebimento | Ranking de recebimentos',
    ranking_pagamento: 'Ranking de pagamento | Ranking de pagamentos',
    rateio_orcamento: 'Rateio de orçamento',
    redefinicao_email_usuario: 'Redefinição do e-mail do usuário.',
    reducao_verba: 'Redução de verbas',
    reducao_verba_cliente: 'Redução de verbas cliente',
    reducao_verba_divisao: 'Redução de verbas divisão',
    reembolso: 'Reembolso',
    relatorio: 'Relatório | Relatórios',
    relatorio_acoes_de_contrato: 'Ações de Contrato',
    relatorio_provisao: 'Relatório de Provisão',
    relatorio_saldo: 'Relatório de Saldo',
    relatorio_volume_meta_tintig: 'Volume x Meta - Tinting',
    representante: 'Representante | Representantes',
    reporte: 'Reporte | Reportes',
    reportes_pendentes_de_criacao: 'Reportes Pendentes de Criação',
    resultado_fluxo: 'Resultado fluxo de aprovação',
    resumo: 'Resumo',
    roi: 'ROI',
    saldo: 'Saldo',
    saldo_disponivel: 'Saldo disponível',
    saving_total: 'Saving(Total)',
    selecao: 'Seleção',
    selecao_aprovador: 'Seleção de aprovador',
    selecao_divisao: 'Seleção de Divisão',
    selecao_aprovador_cliente: 'Seleção do cliente aprovador do acordo',
    selecao_aprovador_fornecedor: 'Seleção do fornecedor aprovador da ação',
    selecao_carteira: 'Seleção da carteira',
    selecao_cliente: 'Seleção do cliente | Seleção dos clientes',
    selecao_orcamento: 'Seleção de orçamento | Seleção de orçamentos',
    selecao_usuario: 'Seleção do usuário | Seleção dos usuários',
    sellin_consolidado: 'Sell In consolidado',
    sellin_detalhado: 'Sell In detalhado',
    sellout_consolidado: 'Sell Out consolidado',
    sellout_detalhado: 'Sell Out detalhado',
    senha: 'Senha',
    sessao_expirada: 'Sessão expirada...',
    simulador_investimento: 'Simulador de Investimento',
    status_acao_exportacao: 'Status das Ações',
    status_criacao_pagamento: 'Status Criação do Pagamento',
    status_alterado: 'Status alterado com sucesso!',
    status_acoes: 'Status das ações',
    termos_condicoes: 'Termos e condições',
    transferencia_carteira: 'Transferência de carteira',
    sintetico_acoes: 'Sintético de ações',
    transferencia_passo: 'Transferências do passo',
    transferencia_valores: 'Transferência de valores',
    transferencia_conta_corrente: 'Transferência entre Contas Correntes',
    transferencia_responsabilidade_faturamento: 'Transferência de responsabilidade de faturamento',
    transferencia_verba: 'Transferência de verba',
    transferir_de: 'Transferir De',
    thumbnail: 'Thumbnail',
    tipo_linha_orcamento: 'Tipo Linha Orçamento',
    tipo_pagamento: 'Tipo de pagamento',
    top_10: 'TOP 10',
    top_20: 'TOP 20',
    unidades: 'Unidade | Unidades',
    unidades_negocio: 'Unidades de negócio',
    upload_arquivo: 'Upload de arquivo',
    upload_evidencia: 'Upload de evidência',
    upload_pagamento: 'Faça o upload do arquivo com as ações para atualização de status do pagamento',
    usuario: 'Usuário | Usuários',
    usuario_x_cliente: 'Usuário de Cliente | Usuários de Clientes',
    usuarios_x_uni_negocio: 'Usuários X Unid. Negócio',
    valor_acao: 'Valor da ação',
    valor_base_crescimento: 'Valor base de crescimento',
    valor_contrato: 'Valor do contrato',
    valor_pagamento: 'Valor do Pagamento',
    validade_configuracao_campos: 'Validade e configuração dos campos',
    vencimento_apuracao_acao: 'Vencimento Apuração - Ação',
    visibilidade_configuracao_campos: 'Visibilidade e configuração dos campos',
    vinculo_acao_tipo_orcamento: 'Vínculo da ação com tipo de orçamento',
    vincular_representante: 'Vincular representante',
    workflow: 'Workflow | Workflows',
  },
  icon: {
    negociacao: {
      NEGOCIACAO_PRECO: 'attach_money',
      TABLOIDE: 'import_contacts',
      FIXO: 'gavel',
      CRESCIMENTO: 'trending_up',
      POSITIVACAO: 'thumb_up',
    },
  },
  subtitle: {
    aprovacoes_ultima_semana: 'Aprovações feitas na última semana',
    linhas_investimento: 'Linhas de orçamento',
    linhas_orcamento: 'Linhas de orçamento',
    pack_comercial: 'Pack comercial',
    somente_leitura: 'Somente leitura',
    importar_arquivo: 'Utilize a área abaixo para realizar a importação de {thing}',
  },
  label: {
    a: 'a | à',
    a_partir_inicio_vigencia: 'A partir do início da vigência',
    a_partir_aprovacao_acao: 'A partir da aprovação da ação',
    a_partir_pgto_liquidado: 'A partir do pagamento liquidado',
    a_partir_vigencia_concluida: 'A partir da vigência concluída',
    aba_divisao: 'Aporte de verbas Divisão',
    acerto_preco: 'Acerto de preço',
    acao: 'Ação',
    acao_agrupadora: 'Ação agrupadora',
    acao_avulsa: 'Ação avulsa',
    acao_contrato: 'Ação de Contrato',
    acao_projeto: 'Ação de Projeto | Ações de Projetos',
    acao_pendente: 'Ação Pendentes | Ações Pendentes',
    acao_para_cliente: 'Ação para cliente',
    acao_verba_fixa: 'Ação com verba fixa',
    acao_passo_cliente: 'Cliente aprovador',
    acao_passo_fornecedor: 'Fornecedor aprovador',
    acao_tatica: 'Ação tática | Ações táticas',
    aceites_realizados: 'Aceites realizados',
    acesso_usuario: 'Acessos do usuário',
    acoes: 'Ação | Ações',
    acoes_adicionadas: 'Ações adicionadas',
    acompanhamento: 'Acompanhamento',
    acompanhamento_fluxo: 'Acompanhamento do fluxo',
    acordo: 'Acordo',
    acordo_passo_cliente: 'Cliente aprovador',
    acordo_acima_minimo: 'Acordo acima do mínimo',
    adendo_reducao: 'Adendo/Redução',
    adendo: 'Adendo',
    adicionar: 'Adicionar',
    adicionar_item: 'Adicionar item',
    adicionar_acao: 'Adicionar ação',
    adicionar_canal: 'Adicionar canal',
    adicionar_categoria: 'Adicionar categoria',
    adicionar_cliente: 'Adicionar cliente',
    adicionar_desdobramento: 'Adicionar desdobramento',
    adicionar_justificativa: 'Adicionar justificativa',
    adicionar_linha_investimento: 'Adicionar linha de orçamento',
    adicionar_linha: 'Adicionar linha na planilha | Adicionar linhas na planilha',
    adicionar_meta: 'Adicionar meta',
    adicionar_negociacao: 'Adicionar negociação',
    adicionar_orcamento: 'Adiconar orçamento',
    adicionar_passo: 'Adicionar passo',
    adicionar_produto: 'Adicionar produto',
    adicionar_regiao: 'Adicionar região',
    adicionar_segmento: 'Adicionar segmento',
    adicione_opcoes_lista: 'Adicione',
    aditivo: 'Aditivo',
    administracao: 'Administração',
    agencia: 'Agência',
    agendamento_tarefa: 'Agendamento tarefa',
    aguardando_aprovacao: 'Aguardando aprovação',
    aguardando_apuracao: 'Aguardando apuração',
    ag_apuracao: 'Ag. apuração',
    aguardando_execucao_metas: 'Aguardando execução metas',
    aguardando_pagamento: 'Aguardando pagamento',
    ajustar: 'Ajustar',
    ajustar_verba: 'Ajustar verba',
    ajuste_saldo_orcamento: 'Ajuste saldo Orçamento',
    ajuste_saldo_representante: 'Ajuste saldo Representante',
    ajuste: 'Ajuste | Ajustes',
    ajuste_verba: 'Ajuste de Verba',
    alcada: 'Alçada | Alçadas',
    aliquota: 'Alíquota',
    alterar: 'Alterar',
    alterar_orcamento: 'Alterar Orçamento',
    alterar_parametrizacao: 'Alterar Parametrização',
    alteracao_saldo_representante: 'Alteração saldo representante',
    alterar_selecionados: 'Alterar selecionados',
    alterar_todos: 'Alterar todos',
    altura_cm: 'Altura (cm)',
    analise_investimento: 'Análise de Investimento',
    anexar: 'Anexar',
    anexo: 'Anexo | Anexos',
    ano: 'Ano',
    anterior: 'Anterior',
    ano_fiscal: 'Ano fiscal',
    ano_fiscal_Atual: 'Ano fiscal atual',
    ano_fiscal_anterior: 'Ano fiscal anterior',
    anual: 'Anual',
    aplicar_alteracao: 'Aplicar alteração | Aplicar alterações',
    aplicar: 'Aplicar',
    aplicar_periodo: 'Aplicar período',
    aplicar_se_a: 'Aplicar-se à',
    aporte_massivo: 'Aporte Massivo',
    aprova_acordo: 'Aprova acordo',
    aprova_workflow: 'Aprova workflow',
    aprovacoes: 'Aprovações',
    aprovado: 'Aprovado',
    aprovado_por: 'Aprovado por',
    aprovador: 'Aprovador',
    aprovador_atual: 'Aprovador atual',
    aprovador_anterior: 'Aprovador anterior',
    aprovadores: 'Aprovadores',
    aprovar: 'Aprovar',
    aprovacao: 'Aprovação',
    aprovacao_layout: 'Aprovação de Layout',
    aprovacao_orcamento: 'Aprovação de Orçamento',
    aprovador_conforme_modalidade: 'Aprovador Conforme Modalidade',
    aprovar_contrato: 'Aprovar contrato',
    aprovar_todas: 'Aprovar todas',
    aprovar_todos: 'Aprovar todos',
    aprovacoes_pendentes: 'Aprovações pendentes',
    aportado: 'Aportado',
    aportado_pdi: 'Aportado PDI',
    apuracao: 'Apuração | Apurações',
    apuracao_acao_detalhes_foco: 'Foco da Ação',
    apuracao_acao_detalhes_resumo: 'Ação - {idAcao} :: Apuração - {idApuracao} :: {tipoAcao} :: {divisao}',
    apuracao_contrato: 'Apuração',
    apuracao_acima_minimo: 'Apuração acima do mínimo',
    apuracao_aprovada: 'Apuração aprovada',
    apuracao_iniciou_fluxo: 'Apuração iniciou Fluxo',
    apuracao_nao_conforme: 'Apuração não conforme',
    apuracao_previa: 'Apuração prévia',
    apuracao_rejeitada: 'Apuração rejeitada',
    apuracao_valor_acima_planejado: 'Apuração acima do planejado',
    apurado: 'Apurado | Apurado ?',
    apurar: 'Apurar | Apurar ?',
    area_atuacao: 'Área de Atuação | Áreas de Atuação',
    area_basf: 'Área BASF',
    armazenar_historico: 'Armazenar histórico',
    arquivo: 'Arquivo',
    ate: 'até | Até',
    atencao: 'Atenção',
    atingimento: 'Atingimento',
    atingimento_marca_forte: 'Atingimento em marca forte | Atingimento em marcas fortes',
    atingimento_total: 'Atingimento total',
    ativar: 'Ativar',
    ativar_inativa: 'Ativar/Inativar',
    ativar_inativar_reg: 'Ativar/Inativar área de atuação | Ativar/Inativar divisão | Ativar/Inativar estrutura do usuário',
    ativo: 'Ativo',
    atualizar: 'Atualizar',
    atualizar_vigencia: 'Atualizar vigência',
    autenticacao_externa: 'Autenticação externa',
    automatico: 'Automático',
    avaliado_por: 'Avaliado por',
    avancar: 'Avançar',
    aviso_credito: 'Aviso de crédito',
    bairro: 'Bairro',
    baixar_todos: 'Baixar todos',
    banco: 'Banco',
    banco_agencia_conta: 'Banco - Agência - Conta Corrente',
    bandeira: 'Bandeira',
    bloqueado: 'Bloqueado',
    atualizador: 'Atualizador',
    base_calculo: 'Base de cálculo',
    base_calculo_apuracao: 'Base de cálculo (Apuração)',
    base_calculo_da_apuracao: 'Base de cálculo da apuração',
    base_calculo_roi: 'Base de cálculo (ROI)',
    basf: 'BASF',
    bloquear: 'Bloquear',
    boleto: 'Boleto',
    bonificacao: 'Forma de Bonificação',
    bonificacao_pagamento: 'Bonificação/Pagamento',
    bonificacao_produtos: 'Bonificação em produtos',
    budget: 'Budget',
    budget_previsto: 'Budget Previsto',
    buscar: 'Buscar',
    buscar_por: 'Buscar por',
    buscar_canal: 'Buscar canal',
    buscar_categoria: 'Buscar categoria | Buscar categorias',
    buscar_cliente: 'Buscar cliente',
    buscar_estrutura: 'Buscar estrutura | Buscar estruturas',
    buscar_perfil: 'Buscar perfil | Buscar perfis',
    buscar_produto: 'Buscar produto',
    buscar_regiao: 'Buscar região',
    buscar_segmento: 'Buscar segmento | Buscar segmentos',
    buscar_tipo_orcamento: 'Buscar tipo de orçamento',
    buscar_tipo_investimento: 'Buscar tipo de linha de Orçamento',
    buscar_usuario: 'Buscar usuário | Buscar usuários',
    buscar_5_itens: 'Buscar por 05 itens',
    buscar_10_itens: 'Buscar por 10 itens',
    buscar_15_itens: 'Buscar por 15 itens',
    buscar_20_itens: 'Buscar por 20 itens',
    buscar_50_itens: 'Buscar por 50 itens',
    buscar_100_itens: 'Buscar por 100 itens',
    cadastrar_carteira_cliente: 'Cadastrar carteira de cliente',
    cadastrar_editar_campo_customizado: 'Cadastrar/editar campo customizado',
    cadastrar_editar: 'Cadastrar/editar',
    cadastrar_extensao: 'Não encontrado. Clique para cadastrar',
    cadastro: 'Cadastro | Cadastros',
    cadastro_acao: 'Cadastro de Ação | Cadastro de Ações',
    cadastro_orcamento: 'Cadastro de orçamento',
    calcular: 'Calcular',
    cadastrar_pre_usuario: 'Pré-cadastro de usuário',
    calculo_apuracao: 'Cálculo da apuração',
    calculo_bonificacao: 'Cálculo da bonificação',
    calculado_sistema: 'Calculado pelo sistema',
    calendario: 'Calendário',
    campo: 'Campo | Campos',
    campo_busca: 'Campo de busca',
    campo_cliente: 'Campo Cliente',
    campo_customizado: 'Campo customizado | Campos customizados',
    contratosacoespend: 'Filtrar contratos com ações pendentes',
    campos_customizados: 'Campos customizados',
    campo_nao_preenchido_ou_invalido: 'Campo não preenchido ou inválido',
    campo_obrigatorio: 'Campo obrigatório',
    campo_obrigatorio_ou_invalido: 'Campo obrigatório ou inválido',
    campos_gerais: 'Campos Gerais',
    campo_pesquisar_vencimento: 'Campo de pesquisa (Data vencimento e Número da ND) - campos separados',
    campo_reservado: 'Campo reservado | Campos reservados',
    calculado_automaticamente: 'Calculado automaticamente',
    canal: 'Canal',
    canal_venda: 'Canal Venda',
    cancelado: 'Cancelado',
    cancelar: 'Cancelar',
    cancelar_acordo: 'Cancelar Acordo',
    cancelar_apuracao: 'Cancelar Apuração',
    cancelar_apuracoes: 'Cancelar apurações',
    cancelar_contrato: 'Cancelar Contrato',
    cancelar_entrega_materiais: 'Cancelar Entrega de Materiais',
    cancelar_execucao: 'Cancelar Execução',
    cancelar_pedido_materiais: 'Cancelar Pedido de Materiais',
    cancelar_selecionados: 'Cancelar Selecionados',
    cancelar_tudo: 'Cancelar Tudo',
    card: 'Card',
    carregar_de: 'Carregar de',
    carregar_linhas: 'Carregar {qtdeLinhas} linhas',
    cargo: 'Cargo',
    carteira: 'Carteira | Carteiras',
    carteira_cliente: 'Carteira de cliente',
    carteira_cliente_ativar: 'ativado(s) com sucesso.',
    carteira_cliente_inativar: 'Inativado(s) com sucesso.',
    catalogo_material: 'Catálogo de material | Catálogo de materiais',
    catalogo_peca: 'Catálogo de peça | Catálogo de peças',
    categoria: 'Categoria | Categorias',
    categoria_acao: 'Categoria da ação',
    categoria_marca: 'Categoria da marca',
    categoria_produto: 'Categoria do produto',
    categoria_produto_apuracao: 'Categoria de produto da apuração',
    categoria_produto_contrato: 'Categoria de produto do contrato',
    centro_custo: 'Centro de custo',
    celular: 'Celular',
    celula: 'Célula',
    cep: 'CEP',
    cidade: 'Cidade',
    cidade_loja: 'Cidade da loja',
    classificacao: 'Classificação',
    classificacao_crm: 'Classificação CRM',
    cliente: 'Cliente | Clientes',
    cliente_aprovador: 'Cliente aprovador do passo {sequencia}',
    clientes_carteira: 'Clientes de acordo com a carteira',
    cliente_dash: 'Cliente',
    cliente_fornecedor: 'Cliente/Fornecedor',
    distribuidor_cliente: 'Distribuidor/Cliente',
    cliente_pagador: 'Cliente pagador',
    cliente_prospect: 'Cliente prospect',
    cnpj: 'CNPJ',
    cnpj_cpf: 'CNPJ/CPF',
    cod_area_atuacao: 'Código área de atuação',
    codigo: 'Código',
    cod_acao: 'Cód. Ação',
    cod_acordo: 'Cód. Acordo',
    cod_alcada: 'Cód. Alçada',
    cod_apuracao: 'Cód. Apuração',
    cod_canal: 'Cód. Canal',
    cod_categoria: 'Cód. Categoria',
    cod_centro_custo: 'Cód. Centro custo',
    cod_cliente: 'Cód. Cliente',
    cod_cliente_pagador: 'Cód. Cliente Pagador',
    cod_contato: 'Cód. Contato',
    cod_contrato: 'Cód. Contrato',
    cod_divisao: 'Cód. Divisão',
    cod_filial: 'Cód. Filial',
    cod_fornecedor: 'Cód. Fornecedor',
    cod_loja: 'Cód. Loja',
    cod_marca: 'Cód. Marca',
    cod_meta: 'Cód. Meta',
    cod_pagamento: 'Cód. Pagamento',
    cod_produto: 'Cód. produto',
    cod_regiao: 'Cód. Região',
    cod_representante: 'Cód. Representante',
    cod_segmento: 'Cód. Segmento',
    cod_tipo_acao: 'Cód. Tipo Ação',
    cod_tipo_investimento: 'Cód. tipo Linha de Orçamento',
    cod_tipo_orcamento: 'Cód. tipo orçamento',
    codigo_tipo_investimento: 'Código do tipo de Linha de Orçamento',
    codigo_cliente: 'Código do Cliente',
    codigo_matriz: 'Código Matriz',
    cod_unidade_negocio: 'Cód. unidade negócio',
    cod_usuario: 'Cód. Usuário',
    codigo_acao: 'Código Ação',
    codigo_pagamento: 'Código Pagamento',
    codigo_planejamento: 'Código Planejamento',
    concluido: 'Concluído',
    concordo: 'Concordo',
    conf_apuracao: 'Conf. apuração',
    conf_negociacao: 'Conf. negociação',
    coordenador: 'Coordenador',
    cotacao: 'Cotação',
    cnpj_distribuidor: 'Cnpj distribuidor',
    cnpj_cliente: 'Cnpj cliente',
    cnpj_loja: 'Cnpj loja',
    com_anexo: 'Com anexo',
    com_erros: 'Com erros',
    comparacao: 'Comparação',
    competencia: 'Competência',
    competencia_sellin_consolidado: 'Competência',
    complemento: 'Complemento',
    complemento_informacao: 'Complemento de informação | Complemento de informações',
    composicao: 'Composição',
    compra: 'Compra | Compras',
    comprimento_cm: 'Comprimento (cm)',
    comprometido: 'Comprometido',
    comprovacao: 'Comprovação',
    comprovante: 'Comprovante',
    comprovante_pagamento: 'Comprovante Pgto',
    configuracao: 'Configuração | Configurações',
    configuracao_planejamento_acao: 'Configuração | Configurações',
    configuracao_do_sistema: 'Configurações do Sistema',
    concluir: 'concluir',
    concluir2: 'Concluir',
    concluir_acoes: 'Concluir ações',
    concluir_apuracao: 'Concluir apuração',
    concluir_este_cliente: 'concluir este cliente',
    concorrente: 'Concorrente',
    condicao: 'Condição',
    condicoes_percentuais: 'Condições percentuais',
    confirmacao: 'Confirmação',
    confirmado_apuracao: 'Confirmado apuração',
    confirmar: 'Confirmar',
    consolidado: 'Consolidado',
    consulta: 'Consulta | Consultas',
    consultar: 'Consultar',
    consultar_todas: 'Consultar todas',
    consumido: 'Consumido',
    conta: 'Conta',
    conta_corrente: 'Conta Corrente | Contas Correntes',
    conta_corrente_cliente: 'Conta corrente de cliente',
    conta_corrente_destino: 'Conta Corrente Destino',
    conta_corrente_origem: 'Conta Corrente Origem',
    contabilizacao: 'Contabilização',
    contagem: 'Contagem',
    contato: 'Contato',
    contexto: 'Contexto',
    continuar: 'Continuar',
    contrato: 'Contrato | Contratos',
    contrato_cliente: 'Contrato cliente (KA)',
    contrato_passo_cliente: 'Cliente aprovador',
    controlador_carteira_cliente: 'Controlador da carteira de cliente',
    controle_estoque: 'Controle Estoque',
    copiar: 'Copiar',
    copiar_acao: 'Copiar Ação',
    copiar_acordo: 'Copiar Acordo',
    copiar_contrato: 'Copiar contrato',
    cor: 'Cor | Cores',
    cpf: 'CPF',
    cpf_cnpj: 'CPF/CNPJ',
    credito: 'Crédito',
    credit_memo_request: 'Credit Memo Request',
    crescimento: 'Crescimento',
    criacao_acao: 'Criação de Ação',
    criacao_contrato: 'Criação de Contrato',
    criador: 'Criador | Criadores',
    criar_recebimento_status_liquidado: 'Criar recebimento no status liquidado',
    criar_pagamento_status_liquidado: 'Criar pagamento no status liquidado',
    cultivo: 'Cultivo',
    cultivo_foco_acao: 'Cultivo / Foco da Ação',
    cultura: 'Cultura',
    dash: 'Dashboard',
    dashboard: 'Dashboard Operacional',
    dashboard_gerencial: 'Dashboard Gerencial',
    dashboard_inicial: 'Dashboard Inicial',
    dashboard_orcamento: 'Dashboard Orçamento',
    data: 'Data',
    data_acao: 'Data da ação',
    data_alteracao: 'Data de alteração',
    data_aniversario: 'Data de aniversário',
    data_aprovacao: 'Data Aprovação',
    data_arquivo: 'Data do arquivo',
    data_atualizacao: 'Data da atualização',
    data_avaliacao: 'Data da avaliação',
    data_cancelamento: 'Data do cancelamento: ',
    data_conferencia: 'Data de conferência',
    data_contabilizacao: 'Data da contabilização',
    data_do_cancelamento: 'Data do cancelamento',
    data_emissao: 'Data de emissão',
    data_emissao_lista_detalhado: 'Data de emissão',
    data_emissao_exportacao: 'Data de emissão',
    data_encerramento: 'Data do encerramento: ',
    data_entrega: 'Data entrega',
    data_entrega_desejada: 'Data de entrega desejada',
    data_hora: 'Data/Hora',
    data_indefinida: 'N/A',
    data_inicio: 'Data início',
    data_inicio_acao: 'Data inicial da ação',
    data_inicio_apu: 'Data início',
    data_inicio_cont: 'Data início',
    data_inicio_grafico: 'Data início (gráfico)',
    data_inicio_pagamento: 'Data inicial do pagamento',
    data_inicio_venc: 'Data início',
    data_inicio_vigencia: 'Data início da vigência',
    data_fim: 'Data fim',
    data_fim_acao: 'Data final da ação',
    data_fim_apu: 'Data fim',
    data_fim_cont: 'Data fim',
    data_fim_grafico: 'Data fim (gráfico)',
    data_fim_pagamento: 'Data final do pagamento',
    data_fim_venc: 'Data fim',
    data_fim_vigencia: 'Data final da vigência',
    data_criacao: 'Data de criação',
    data_liquidacao: 'Data liquidação',
    data_montagem: 'Data da montagem',
    data_pagamento: 'Data de pagamento',
    data_pedido: 'Data de pedido',
    data_prevista_fim: 'Data prevista fim',
    data_proxima_execucao: 'Data da próxima execução',
    data_original: 'Data original',
    data_remessa: 'Data da Remessa',
    data_solicitacao: 'Data de solicitação',
    data_transferencia: 'Data de transferência',
    data_ultima_alteracao: 'Data da última alteração.',
    data_ultima_aprovacao: 'Data da última aprovação',
    data_ultima_execucao: 'Data da última execução',
    data_vencimento: 'Data de vencimento',
    data_vencimento_nf: 'Data vencimento NF',
    data_Upload: 'Data de upload',
    data_Upload_NF: 'Data de upload NF',
    data_Upload_Orcamento: 'Data de upload Orçamento',
    data_prorrogacao: 'Data de prorrogação',
    de: 'de | De',
    debito: 'Débito',
    de_gestor: 'De (Gestor)',
    de_usuario_distribui_verba: 'De (Usuário que distribui verba)',
    de_vendedor: 'De (Vendedor)',
    definitiva: 'Definitiva',
    definir_data: 'Definir data',
    deposito_conta: 'Depósito em conta',
    departamento: 'Departamento',
    desbloquear: 'Desbloquear',
    desconto: 'Desconto | Descontos',
    desconto_nota: 'Desconto em nota',
    descreva_oque_deve_ser_alterado: 'Descreva o que deve ser alterado',
    descricao: 'Descrição',
    descricao_detalhada: 'Descrição detalhada',
    descricao_planejamento_acao: 'Descrição',
    desc_acordo: 'Descrição do acordo',
    desc_produto: 'Descrição do produto',
    descricao_acao: 'Descrição da ação',
    descricao_campo: 'Descrição do campo',
    descricao_contrato: 'Descrição do contrato',
    descricao_impacto: 'Descrição do impacto',
    descricao_linha_investimento: 'Descrição da linha de investimento',
    descricao_painel: 'Status: {status} <br />Valor: {valor} <br />Foco: {foco} <br />Período: {periodo} <br />Descrição: {descricao}',
    desdobramento: 'Desdobramento | Desdobramentos',
    destino: 'Destino',
    destino_acao: 'Destino da ação',
    destino_verba: 'Destino verba',
    detalhado: 'Detalhado',
    detalhamento: 'Detalhamento',
    detalhamento_briefing: 'Detalhamento de Briefing',
    detalhe: 'Detalhe | Detalhes',
    detalhe_acao: 'Detalhe da ação',
    detalhe_campo: 'Detalhes do campo',
    detalhe_orcamento: 'Detalhes do orçamento',
    devolucao: 'Devolução | Devoluções',
    devolucoes: 'Devoluções',
    dias_uteis_producao: 'Dias úteis Produção',
    diagnostico_aprovacao: 'Diagnóstico/Aprovação',
    digite_para_buscar: 'Digite para buscar',
    diretoria: 'Diretoria',
    distribuidor: 'Distribuidor',
    distribuicao_valor: 'Distribuição de valor | Distribuição de valores',
    disponivel: 'Disponível',
    disponivel_planejado: 'Disponível planejado',
    divisao: 'Divisão | Divisões',
    divisao_hierarquica: 'Divisão hierárquica',
    divisao_responsavel: 'Divisão responsável',
    divisao_superior: 'Divisão superior | Divisões superiores',
    doacao: 'Doação',
    documento: 'Documento',
    dolar: 'Dólar | Dólares',
    dominio: 'Dominio',
    download: 'Download',
    donwload_evidencia_apuracao: 'Download evidência',
    donwload_evidencia_reivindicacao: 'Fluxo de Aprovação de Reinvindicação em andamento para a meta.',
    download_arquivo_negociacao: 'Download Arquivo de Negociação',
    download_projeto_fachadas: 'Download Projeto Fachadas',
    dta_liquidacao: 'Data de liquidação',
    duracao: 'Duração',
    duracao_orcamento: 'Duração do orçamento',
    e: 'e',
    e_representante: 'É Representante?',
    ean: 'EAN',
    ean_produto: 'EAN do produto',
    edicao_status_aprovado: 'Edição no status Aprovado',
    editar: 'Editar',
    editar_a_acao: 'Editar a ação',
    editar_campo_customizado: 'Editar campo customizado',
    editar_carteira_cliente: 'Editar carteira de cliente',
    editar_categoria: 'Editar categoria',
    editar_cliente: 'Editar cliente',
    editar_item: 'Editar item',
    editar_negociacao: 'Editar negociação',
    editar_produto: 'Editar produto',
    editar_status: 'Editar status',
    editar_divisao: 'Editar divisao',
    editar_marca: 'Editar marca',
    editar_unidade: 'Editar unidade',
    editar_unidadenegocio: 'Editar unidade de negócio',
    efetivado: 'Efetivado',
    elegivel: 'Elegível',
    email: 'Email',
    em_analise: 'Em análise',
    em_andamento: 'Em andamento',
    em_cadastro: 'Em cadastro',
    em_execucao: 'Em execução',
    em_comum: 'Em comum',
    em_reivindicacao: 'Em reivindicação',
    em_processo_apuracao: 'Em processo de apuração',
    encargo: 'Encargo | Encargos',
    encerrado: 'Encerrado',
    encerrar_apuracao: 'Encerrar apuração',
    encerrar_contrato: 'Encerrar contrato',
    encerrar_acordo: 'Encerrar acordo',
    encontro_contas: 'Encontro de contas',
    endereco: 'Endereço',
    endereco_acao: 'Endereço ação',
    endereco_entrega: 'Endereço entrega',
    endereco_entrega_padrao: '',
    entidade: 'Entidade',
    enviado: 'Enviado | Enviados',
    enviar: 'Enviar',
    enviar_alteracao: 'Enviar para alteração',
    enviar_analise: 'Enviar para análise',
    enviar_aprovacao: 'Enviar para aprovação | Enviar aprovação',
    erro: 'Erro | Erros',
    escolha_importacao_acima: 'Escolha uma forma de importação acima',
    escolha_periodo_importacao: 'Escolha um periodo para importação',
    escolha_tipo_importacao_acima: 'Escolha um tipo de importação acima',
    escolha_solicitante: 'Escolha um solicitante',
    especifico: 'Específico',
    estado: 'Estado',
    estoque_atual: 'Estoque atual',
    estoque_minimo: 'Estoque mínimo',
    estrutura: 'Estrutura',
    estrutura_comercial: 'Estrutura Comercial',
    estrutura_cliente: 'Estrutura cliente',
    estrutura_divisao: 'Estrutura da divisão',
    estrutura_pai: 'Estrutura pai',
    estrutura_verba: 'Estrutura da verba',
    estrutura_usuario: 'Estrutura de usuário | Estrutura usuário',
    estrutura_usuario_clique: 'clique para alterar',
    etapa_fluxo: 'Etapa do fluxo',
    evento: 'Evento',
    eventos_cancelados: 'Eventos cancelados',
    evidencia: 'Evidência',
    evidencia_apuracao: 'Evidência',
    evidencias: 'Evidências',
    excecao: 'Exceção | Exceções',
    excluir: 'Excluir',
    excluir_a_acao: 'Excluir a ação',
    excluir_perfil: 'Excluir perfil',
    execucao: 'Execução | Execuções',
    execucao_criador_da_acao: 'Condicional de criador da ação',
    execucoes_meta: 'Execuções da meta',
    executar: 'Executar',
    executar_analise: 'Executar análise',
    executar_metas: 'Executar metas | Executar metas ?',
    execucao_orcamento: 'Orçamentos de execução',
    exibe_catalogo: 'Exibe no catálogo',
    exibicao: 'Exibição',
    exibir_todos_clientes_ativos: 'Exibir todos os clientes ativos',
    exibir_somente_selecionados: 'Exibir somente selecionados',
    exibir_usuario_ja_cadastrado: 'Exibir usuários já cadastrados',
    exibir_secao_apuracao: 'Exibir seção na apuração',
    explicita: 'Explícita',
    exportacao: 'Exportação',
    exportar: 'Exportar',
    exportacoes: 'Exportações',
    exportar_conteudo: 'Exportar Conteúdo',
    exportar_modelo: 'Exportar Modelo',
    exportar_relatorio_acesso: 'Exportar relatório de acesso',
    extrato: 'Extrato',
    extrato_conta_corrente: 'Extrato conta corrente',
    extrato_conta_corrente_resumido: 'Resumido',
    extrato_conta_corrente_detalhado: 'Detalhado',
    fechamento_parcial: 'Fechamento parcial',
    foco_acao_cliente: 'Cliente',
    foco_divisao: 'Divisão',
    fornecedor_escolhido: 'Fornecedor escolhido',
    fornecedor_escolhido_orcamento: 'Fornecedor escolhido no orçamento',
    fornecedor_pagador: 'Fornecedor pagador',
    fornecedor_valor_final: 'Fornecedor/Valor Final',
    familia: 'Família',
    faturamento: 'Faturamento',
    faturamento_canal: 'Faturamento por Canal',
    faturamento_marca: 'Faturamento por Marca',
    faturamento_por_investimento: 'Faturamento x Investimento',
    fazer_nova_importacao: 'Fazer nova importação',
    fazer_nova_importacao_de: 'Fazer nova importação de',
    fechar: 'Fechar',
    filial: 'Filial',
    filtrar: 'Filtrar',
    filtrar_por: 'Filtrar por',
    filtro: 'Filtro | Filtros',
    filtro_apuracao_sem_evidencia: 'Filtrar apuração sem evidência.',
    filtro_apuracao_sem_reprocessamento: 'Filtrar apuração sem reprocessamento.',
    filtro_cliente: 'Filtro cliente',
    filtro_cliente_: 'Selecione um filtro de cliente',
    filtro_cliente_id_cliente: 'Cliente',
    filtro_cliente_id_regiao: 'Região',
    filtro_cliente_id_holding: 'Holding',
    filtro_produto_utilizado: 'Filtros de Produto utilizados',
    filtro_visao: 'Filtro da visão',
    filtro_usuario: 'Filtro usuário',
    fim: 'Fim',
    fim_apuracao: 'Fim apuração',
    fim_periodo_retorno: 'Fim período retorno',
    fim_solicitacao_reembolso: 'Fim Solicitação Reembolso',
    fim_vigencia: 'Fim de vigência | Fim vigência',
    finalizar: 'Finalizar',
    finalizar_apuracao: 'Finalizar apuração | Finalizar apurações',
    finalizar_selecionados: 'Finalizar selecionados',
    finalizar_tudo: 'Finalizar tudo',
    fisica: 'Física',
    finalizado: 'Finalizado | Finalizados',
    finalizado_por: 'Finalizado por',
    fixo: 'Fixo',
    foco: 'Foco',
    foco_acao: 'Foco da ação',
    foco_cliente: 'Cliente',
    foco_contrato: 'Foco do Contrato',
    forma_bonificacao: 'Forma de bonificação',
    forma_rateio: 'Forma de rateio',
    formato_data: 'Formato da data',
    fornecedor: 'Fornecedor',
    fornecedores: 'Fornecedores',
    fornecedor_sugerido: 'Fornecedor Sugerido',
    foto_catalogo: 'Foto Catálogo',
    fotos_evento: 'Fotos do evento',
    frequencia: 'Frequência',
    frete: 'Frete | Fretes',
    fluxo: 'Fluxo',
    fluxo_aprovacao: 'Fluxo de aprovação',
    fluxo_apuracao_concluido: 'Apuração concluída.',
    fluxo_descricao: 'Descrição fluxo',
    fluxo_reivindicacao_meta_andamento: 'Reivindicar | Reinvindicação em andamento',
    fluxo_trademarketing: 'Fluxo de trademarketing',
    funcionario: 'Funcionário | Funcionários',
    funcionalidade_bloqueada: 'Funcionalidade bloqueada',
    funcionarios_carteira: 'Funcionários de acordo com a carteira',
    gestao_aprovacao: 'Gestão de aprovação',
    gasto_fixo: 'Gasto fixo',
    gerar_grade: 'Gerar grade',
    geral: 'Geral',
    gerente: 'Gerente',
    gestao_usuario: 'Gestão de usuário | Gestão de usuários',
    gestor: 'Gestor',
    grid: 'Grid',
    grid_filtros: 'Grid e filtros',
    gr: 'GR',
    grupo_fornecedor: 'Grupo de fornecedor',
    grupo_produto: 'Grupo de produto',
    grupo: 'Grupo | Grupos',
    habilita: 'Habilita',
    habilita_informacoes_bancarias: 'Informações bancárias (Banco, Agência e Conta Corrente) obrigatórias para solicitar aprovação do acordo',
    habilitar_acao_agrupadora_automatica: 'Habilitar ação agrupadora automática',
    habilitar_acao_campo_cliente: 'Campo do Cliente',
    habilitar_acao_cliente: 'Cliente',
    habilitar_acao_cliente_pagador: 'Habilitar cliente pagador',
    habilitar_acao_divisao: 'Divisão',
    habilitar_acao_fluxo: 'Habilitar fluxo',
    habilitar_acao_fornecedor: 'Habilitar fornecedor',
    habilitar_acao_fornecedor_pagador: 'Habilitar fornecedor pagador',
    habilitar_acao_grupo_fornecedor: 'Habilitar grupo de fornecedor',
    habilitar_acao_holding: 'Holding',
    habilitar_acao_regional: 'Regional',
    habilitar_alocacao_verba_parcial: 'Habilitar alocação da verba parcial',
    habilitar_bloqueio_de_uso_verba: 'Habilitar bloqueio de uso da verba',
    habilitar_calculo_roi: 'Habilitar cálculo de ROI no planejamento da ação',
    habilitar_coeficiente_calculo: 'Habilitar coeficiente de cálculo',
    habilitar_complemento_informacao: 'Habilitar complemento de informação na aprovação',
    habilitar_divisao_orcamento: 'Habilitar divisão no cadastro do orçamento',
    habilitar_notificacao_vencimento: 'Habilitar notificação por email antes do vencimento',
    habilitar_programa_marketing: 'Liberado para modalidade 4',
    habilitar_fluxo_aprovacao: 'Habilitar fluxo de aprovação',
    habilitar_permite_duplicidade_acoes: 'Permitir duplicidade de ações',
    habilitar_uso_exclusivo_contrato: 'Habilitar uso exclusivo em contrato',
    habilitar_acao_projeto: 'Habilitar vínculo em projeto',
    habilitar_acao_projeto_obrigatorio: 'Vínculo em projeto obrigatório',
    habilitar_vencimento_apuracao_acao: 'Habilitar vencimento da apuração da ação',
    herdar_configuracao_apuracao_acao: 'Herdar configuração de apuração da ação',
    habilitar_unica_conta_corrente_acao: 'Habilitar uso de uma única conta corrente na ação',
    herdar_periodo_vigencia_acao: 'Herdar o período da vigência da ação | Herdar o período da vigência das ações',
    hierarquia: 'Hierarquia',
    hierarquia_de: 'Hierarquia de',
    hierarquia_funcionario: 'Hierarquia de funcionário',
    historico: 'Histórico',
    historico_ano_anterior: 'Histórico do ano anterior',
    historico_de: 'Histórico de ',
    historico_faturamento_investimento: 'Histórico - Faturamento e Investimento',
    historico_faturamento_investimento_marca: 'Histórico - Investimento e Faturamento - Por Marca',
    historico_importacoes_sellin_sellout: 'Histórico importações Sellin/Sellout',
    historico_meses: 'Histórico de meses',
    holding: 'Holding',
    honorario: 'Honorário | Honorários',
    hora: 'Hora',
    hora_final: 'Hora Final',
    hora_inicial: 'Hora Inicial',
    hora_montagem: 'Hora da Montagem',
    icms: 'ICMS',
    icone: 'Ícone',
    id: 'ID',
    id_acao: 'Cód. da ação',
    id_cliente: 'Id Cliente',
    id_externo_pagamento: 'Cód. Externo',
    id_orcamento: 'ID do orçamento',
    ids: 'ID',
    ie: 'IE',
    impacto_apuracoes_agrupadas_atualizacao: 'Atualização das metas desta apuração.',
    impacto_apuracoes_agrupadas_inclusao: 'Inclusão desta apuração.',
    imprimir: 'Imprimir',
    inativar: 'Inativar',
    inativar_usuario_migracao: 'Inativar usuário pós migração',
    incluir: 'Incluir',
    incluir_acao: 'Incluir ação',
    inclusao_execucao: 'Inclusão da execução',
    inconsistencia: 'Inconsistência | Inconsistências',
    indicador: 'Indicador | Indicadores',
    ind_rateio: 'Rateio',
    informado: 'Informado',
    informado_manualmente: 'Informado manualmente',
    informacoes_adicionais_acao: 'Informações adicionais da Ação',
    iniciar_periodo: 'Iniciar período',
    integracao: 'Integração',
    inicio_solicitacao_reembolso: 'Início Solicitação Reembolso',
    invest: 'Invest.',
    investimento_ambientacao: 'Investimento Ambientação',
    investimento_fachada: 'Investimento Fachada',
    investido: 'Investido',
    implicita: 'Implícita',
    importacoes_andamento: 'Importações em Andamento',
    importacoes_erros: 'Importações Com Erros',
    importacoes_finalizadas: 'Importações Finalizadas',
    importacao: 'Importação | Importações',
    importar: 'Importar',
    importar_conteudo: 'Importar Conteúdo',
    imposto: 'Imposto | Impostos',
    impressao_contrato: 'Impressão Contrato',
    inativo: 'Inativo',
    informacoes: 'Informações',
    informar_novo_endereco: 'Informar um Novo Endereço',
    inicio: 'Início',
    inicio_apuracao: 'Início apuração',
    inicio_periodo_retorno: 'Início período retorno',
    inicio_vigencia: 'Início de vigência | Início vigência',
    informacoes_bancarias: 'Informações bancárias',
    informacao_orcamento: 'Informações do Orçamento',
    informe_justificativa: 'Informe a justificativa.',
    info_contrato: 'Informação de Contrato',
    info_cliente: 'Informação de Cliente',
    inscricao_estadual: 'Inscrição estadual',
    intervalo: 'Intervalo',
    intervalo_meta: 'Intervalo da meta',
    instituicao_cor: 'Instituição (TUDO DE COR)',
    investimento: 'Investimento',
    invest_fat: 'Invest. sobre Fat.',
    investimento_margem: 'Investimento vs. Margem',
    investimento_x_faturamento: '% Inv/Fat',
    investimento_canal: 'Investimento por Canal',
    investimento_marca: 'Investimento por Marca',
    investimento_faturamento: 'Investimento sobre Faturamento',
    investimento_venda: 'Investimento sobre vendas',
    ipi: 'IPI',
    ir_topo: 'Ir para topo',
    item: 'Item',
    item_acao: 'Item Ação | Itens Ação',
    item_pagamento: 'Item do pagamento | Itens do pagamento',
    juridica: 'Jurídica',
    justificar_acao: 'Descreva o motivo de {acao} {extra}',
    justificativa: 'Justificativa',
    justificativa_analise: 'Justificativa obrigatória na análise',
    justificativa_reprovacao: 'Justificativa reprovação',
    justificativa_alteracao_valor_pagamento: 'Justificativa da alteração do pagamento',
    holding_regiao: 'Holding/Região',
    layout: 'Layout',
    largura_cm: 'Largura (cm)',
    li_aceito_condicoes: 'Li e aceito as condições',
    li_aceito_termos_condicoes: 'Li e aceito os termos e condições',
    li_concordo: 'Li e concordo',
    li_concordo_termos_condicoes: 'Li e concordo com os termos e condições',
    liberado: 'Liberado',
    lider_conta: 'Líder de conta',
    limpar: 'Limpar',
    limpar_filtros: 'Limpar filtros',
    linha: 'Linha',
    linha_investimento: 'Linha de orçamento | Linhas de Orçamento',
    linha_de_investimento: 'Linha de Investimento | Linhas de Investimento',
    linha_orcamento: 'Linha de orçamento',
    linha_produto: 'Linha de produto',
    liquidacao: 'Liquidação',
    liquidacao_manual: 'Liquidação manual',
    liquidado: 'Liquidado',
    liquidar_pagamento: 'Liquidar Pagamento',
    limpar_carrinho: 'Limpar carrinho',
    linhas_por_pagina: 'Linhas por página',
    lista: 'Lista',
    lista_passo1_parametrizacao_orcamento: 'Qualquer alteração nas configurações desse tipo de orçamento será aplicada para os novos orçamentos e os orçamentos que estão no status Em cadastro. Os demais status (Aprovado, Aguardando aprovação, Em análise, Reprovado e Cancelado) não serão impactados.',
    lista_passo2_parametrizacao_orcamento: 'Se um Tipo de Investimento for inativado ele não será apresentado em sua respectiva seção.',
    lista_presenca: 'Lista de presença',
    log: 'Log | Logs',
    login: 'Login',
    logradouro: 'Logradouro',
    local: 'Local | Locais',
    local_entrega: 'Local de entrega',
    local_entrega_padrao: '',
    localidade: 'Localidade (ex.: Hotel, Restaurante, Cidade)',
    loja: 'Loja | Lojas',
    logout: 'Logout',
    manual: 'Manual',
    matricula: 'Matrícula',
    mais_detalhes: 'Mais detalhes',
    mapa_provisao: 'Mapa de Provisão',
    marca: 'Marca',
    marca_produto: 'Marca do produto',
    marca_representante: 'Marcar como representante',
    margem: 'Margem',
    margemcontribuicao: 'Margem Contribuição ROI',
    material: 'Material | Materiais',
    media: 'Média',
    meio_acesso: 'Meio de acesso',
    mes: 'Mês',
    mes_fiscal: 'Mês Fiscal',
    mensal: 'Mensal',
    mes_referencia: 'Mês de referência',
    meta: 'Meta | Metas',
    meta_atingida: 'Meta atingida',
    meta_de: 'Meta de',
    meta_ate: 'Meta até',
    meta_beneficio: 'Meta e benefício | Metas e benefícios',
    metas_beneficios: 'Meta/Benefício',
    meta_nao_se_aplica: 'Meta não se aplica',
    meus_passos_aprovados: 'Meus passos aprovados',
    migrar_saldo_disponivel: 'Migrar saldo disponível',
    minhas_aprovacoes: 'Minhas aprovações',
    minhas_aprovacoes_pendentes: 'Minhas aprovações pendentes',
    minima: 'Mínima',
    modalidade: 'Modalidade',
    monetario: 'Monetário',
    motivo: 'Motivo',
    mover_passo: 'Mover passo',
    multipla_escolha: 'Múltipla escolha',
    multiplas_liquidacoes: 'Múltiplas liquidações',
    nao: 'Não',
    nao_aplica: 'N/A',
    nao_se_aplica: 'Não se aplica',
    nao_encontrou_clique_aqui: 'Não encontrou {text}? Clique aqui para cadastrar',
    nao_encontrou_campo_clique_aqui: 'Não encontrou o campo? Clique aqui para cadastrar',
    nao_encontrou_familia_clique_aqui: 'Não encontrou a família? Clique aqui para cadastrar',
    nao_enviado: 'Não enviado | Não enviados',
    nao_alocado: 'Não alocado',
    nao_existem_materiais_disponiveis: 'Não existem materiais disponíveis para essa ação.',
    nao_liberado: 'Não liberado',
    nao_informado: 'Não informado',
    nao_salvar: 'Não salvar',
    ncm: 'NCM',
    nd: 'ND',
    negociacao: 'Negociação',
    negociacao_faturamento: 'Negociação vs. Faturamento',
    negociacao_preco: 'Negociação Preço',
    negociado: 'Negociado',
    nivel_cliente: 'Nível cliente',
    nivel_fornecedor: 'Nível fornecedor',
    nivel_produto: 'Nível produto',
    nome: 'Nome',
    nome_acao: 'Nome da ação',
    nome_campo: 'Nome do campo',
    nome_canal: 'Nome do canal',
    nome_cliente: 'Nome do cliente',
    nome_categoria: 'Nome da categoria',
    nome_estrutura_pai: 'Nome da estrutura pai',
    nome_evento: 'Nome do evento',
    nome_fantasia: 'Nome fantasia',
    nome_fornecedor: 'Nome do fornecedor',
    nome_grupo_produto: 'Nome do grupo de produto',
    nome_interno: 'Nome interno',
    nome_loja: 'Nome da loja',
    nome_marca: 'Nome da marca',
    nome_produto: 'Nome do produto',
    nome_regiao: 'Nome da região',
    nome_segmento: 'Nome do segmento',
    nome_solicitante: 'Nome solicitante',
    nome_tipo_investimento: 'Nome do tipo de linha de orçamento',
    nome_tipo_orcamento: 'Nome do tipo de orçamento',
    nome_unidade_negocio: 'Nome da unidade de negócio',
    nota: 'Nota | Notas',
    nota_credito: 'Nota de crédito | Notas de crédito',
    nota_debito: 'Nota de débito | Notas de débito',
    nota_devolucao: 'Nota de devolução | Notas de devolução',
    nota_fiscal: 'Nota fiscal',
    nota_fiscal_envio_recebimento: 'Envio NF para o recebimento',
    nota_fiscal_vencimento: 'Vencimento NF',
    nota_fiscal_chave_cobertura: 'Chave da NF de cobertura',
    nova: 'Nova',
    nova_associacao_usuario_cliente: 'Nova associação usuário x cliente',
    nova_associacao_usuario_unidade_negocio: 'Nova associação usuário x un. negócio',
    nova_divisao: 'Nova divisão',
    nova_estrutura_usuario: 'Nova estrutura de usuário',
    nova_execucao: 'Nova execução',
    nova_parametrizacao: 'Nova parametrização',
    nova_peca: 'Nova peça',
    novo: 'Novo',
    novo_acordo: 'Novo acordo',
    novo_ajuste: 'Novo ajuste',
    novo_contrato: 'Novo contrato',
    novo_cliente: 'Novo cliente',
    novo_funcionario: 'Novo funcionário',
    novo_item_adicionado: 'Novo item adicionado',
    novo_material: 'Novo material',
    novo_orcamento: 'Novo orçamento',
    novo_pagamento: 'Novo pagamento',
    nova_perfil: 'Novo perfil',
    novo_periodo: 'Novo período',
    novo_planejamento: 'Novo planejamento',
    novo_programa_marketing: 'Novo Programa de marketing',
    novo_produto: 'Novo produto',
    novo_reporte: 'Novo reporte',
    novo_tipo: 'Novo tipo',
    novo_usuario: 'Novo usuário',
    num_requisicao_compra: 'Número da requisição de compra',
    numerico: 'Numérico',
    numero: 'Número',
    numero_abreviado: 'Nº',
    numero_acordo: 'Número do acordo',
    numero_documento: 'Número do Documento',
    numero_participantes: 'Número de participantes',
    numero_pedido: 'Número do Pedido',
    numero_oi: 'Número OI',
    numero_nf: 'Número NF.',
    numero_nota_fiscal: 'Número Nota Fiscal',
    objetivo: 'Objetivo',
    objetivo_acao: 'Objetivo da ação',
    objetivo_atingido: 'Objetivo atingido',
    obrigatorio: 'Obrigatório',
    observacao: 'Observação | Observações',
    observacoes_detalhamento_da_acao: 'Observações / Detalhamento da Ação (escreva maiores detalhes da Ação)',
    oi: 'OI',
    oi_cc: 'OI/CC',
    ok: 'Ok',
    opcao: 'Opção | Opções',
    opcoes_lista: 'Opções da lista',
    operacao: 'Operação',
    orcamento: 'Orçamento',
    orcamento_execucao: 'Orçamento de execução',
    ordem_interna: 'Ordem interna',
    orcamento_aguardando_aprovacao: 'Orçamento aguardando aprovação',
    orcamento_aprovado: 'Orçamento aprovado',
    orcamento_em_alteracao: 'Orçamento em alteração',
    orcamento_em_analise: 'Orçamento em análise',
    orcamento_em_cadastro: 'Orçamento em cadastro',
    orcamento_reprovado: 'Orçamento reprovado',
    ordem_interna_centro_custo: 'Ordem Interna Centro Custo',
    ordenacao: 'Ordenação',
    ordenar_por: 'Ordenar por',
    ordernar_crescente: 'Ordenar crescente',
    ordenar_decrescente: 'Ordenar decrescente',
    ordernar_limpar: 'Limpar ordenação',
    organizacao_vendas: 'Organização de vendas',
    orgao_expedidor: 'Órgão expedidor',
    orientacao: 'Orientação | Orientações',
    origem: 'Origem',
    origem_acao: 'Origem da ação',
    origem_contrato: 'Origem do contrato',
    origem_transferencia: 'Origem da transferência',
    origem_verba: 'Origem de verba',
    outro_reporte: 'Outro reporte | Outros reportes',
    pagina: 'Página',
    pai: 'Pai',
    papel: 'Papel',
    passo_transferido: 'Passo de aprovação transferido',
    passos: 'Passo | Passos',
    passos_workflow: 'Passos do workflow',
    pagamento: 'Pagamento | Pagamentos',
    pagamento_andamento: 'Pagamento em andamento',
    pagamento_concluido: 'Pagamento concluído',
    pagamento_efetivado: 'Pagamento efetivado',
    pagamento_rejeitado: 'Pagamento reprovado/bloqueado/não liquidado',
    pagamento_enviado: 'Pagamento enviado | Pagamentos enviados',
    pagamento_a_partir_aprovacao_acao: 'A partir da aprovação da ação',
    pagamento_a_partir_inicio_vigencia: 'A partir do início da vigência',
    pagamento_a_partir_vigencia_concluida: 'A partir da vigência concluída',
    pagamento_manual: 'Pagamento Manual',
    pagamento_nf: 'Pagamento NF',
    pagamento_pendente: 'Pagamento pendente',
    pago: 'Pago',
    para: 'Para',
    para_cadastrar: 'para cadastrar',
    parametrizacao: 'Parametrização',
    parametrizacao_permitir_transferencia_tipo_verba_diferente: 'Permite transferência de verbas entre orçamentos com tipos de verba diferentes',
    parametrizacao_permitir_adendo: 'Permite adendo de verba',
    parametrizacao_permitir_reducao: 'Permite redução de verba',
    para_gestor: 'Para (Gestor)',
    para_novo_superior_imediato: 'Para (Novo superior imediato)',
    para_usuario_distribui_verba: 'Para (Usuário que distribui verba)',
    para_vendedor: 'Para (Vendedor)',
    part_faturamento: 'Part. sobre Fat.',
    part_investimento: 'Part. sobre Invest.',
    patrocinio_referente: 'Patrocínio referente',
    patrocinio_referente_a: 'Patrocínio referente a',
    participa_aprovacao_acordo: 'Participa na aprovação do acordo',
    participacao_investimento: 'Participação Sobre Investimento',
    participacao_x_investimento: '% Part sobre Inv',
    percentual_participacao_investimento: '% Part. invest.',
    participacao_investimento_canal: 'Participação no Investimento por Canal',
    peca: 'Peça',
    pendencia: 'Pendência | Pendências',
    pendencia_aprovacao: 'Pendência de aprovação',
    pendente_apuracao: 'Pendente Apuração',
    pendente_distribuicao: 'Pendente distribuição',
    pendente_pagamento: 'Pendente pagamento',
    pendente_reserva: 'Pendente reserva',
    percentual_investimento: 'Percentual de Investimento',
    perc_investimento: '% Investimento',
    perc_provisao: 'Percentual de provisão',
    perfil: 'Perfil | Perfis',
    periodo: 'Período',
    periodo_apuracao: 'Período de apuração',
    periodo_apuracao_datas: '{dtaInicio} a {dtaFim}',
    periodo_apuracao_de: 'Período de apuração De',
    periodo_apuracao_ate: 'Período de apuração Até',
    periodo_fiscal: 'Período fiscal',
    periodo_fiscal_atual: 'Período fiscal atual',
    periodo_fiscal_anterior: 'Período fiscal anterior',
    periodo_planejamento: 'Período de planejamento',
    periodo_orcamentario: 'Período orçamentário',
    periodo_orcamentario_igual_planejamento: 'Período de orçamento igual ao período de planejamento',
    periodo_pdi: 'Período PDI',
    pedido: 'Pedido',
    percentual: 'Percentual',
    permitir_usuario_cliente_visualizar_roi: 'Permitir usuário cliente visualizar o ROI no planejamento da ação',
    pesquisa_filtro_cliente: 'Pesquisa de Filtro Cliente',
    pesquisar: 'Pesquisar',
    peso: 'Peso',
    peso_kg: 'Peso (kg)',
    peso_produto: 'Peso do produto',
    pis_cofins: 'PIS/COFINS',
    planejamento: 'Planejamento',
    planejamento_tatico: 'Planejamento tático',
    planejado: 'Planejado',
    planejado_distribuido: 'Planejado distribuído',
    planejado_nao_distribuido: 'Planejado não distribuído',
    planejado_x_faturamento: '% Plan/Fat',
    planejado_x_realizado_area_atuacao: 'Valor Planejado x Realizado por Área de Atuação',
    planejado_x_realizado_cliente: 'Valor Planejado x Realizado por Cliente',
    planejado_x_realizado_cultivo: 'Valor Planejado x Realizado por Cultivo',
    planejado_x_realizado_periodo: 'Valor Planejado x Realizado por Período',
    plano_acao_aprovacao: 'Plano de ação/Aprovação',
    plano_comparativo: 'Plano Comparativo',
    preco_negociado: 'Preço Negociado',
    principal: '',
    ponto: 'Ponto | Pontos',
    pontuacao: 'Pontuação',
    pontuacao_minima_total: 'Pontuação minima total',
    por_mes: 'Por Mês',
    por_periodo_fiscal: 'Por Período Fiscal',
    por_periodo_planejamento: 'Por Período de Planejamento',
    por_planejamento_fiscal: 'Por Planejamento Fiscal',
    por_tipo_investimento: 'Por Tipo de Investimento',
    por_tipo_investimento_marca: 'Por Tipo de Investimento vs. Marca',
    por_tipo_negociacao_marca: 'Por Tipo de Negociação vs. Marca',
    positivacao: 'Positivação',
    prazo_notificacao_dias: 'Prazo para notificar (dias)',
    prazo_vencimento_dias: 'Prazo de vencimento (dias)',
    prazo_vencimento_acao_dias: 'Prazo de vencimento de ação avulsa (dias)',
    prazo_vencimento_contrato_dias: 'Prazo de vencimento de contrato (dias)',
    preenchimento_automatico: 'Preenchimento automático',
    primeiro_acesso: 'Primeiro acesso',
    preencha_campos_abaixo: 'Preencha os campos abaixo',
    permitir_alteracao_apuracao: 'Permitir alteração da seção na apuração',
    produto: 'Produto | Produtos',
    programa: 'Programa',
    programa_marketing: 'Programa de marketing',
    prorrogacao: 'Prorrogação',
    prorrogar: 'Prorrogar',
    provisao: 'Provisão | Provisões',
    proximo: 'Próximo | Próximos',
    proximo_passo: 'Próximo passo',
    prospect: 'Prospect',
    projeto: 'Projeto',
    publico: 'Público',
    publico_estimado: 'Público estimado',
    quantidade: 'Quantidade',
    quantidade_aprovada: 'Quantidade aprovada',
    quantidade_apuracao: 'Quantidade de apurações',
    quantidade_minima: 'Quantidade mínima',
    quantidade_pedido: 'Quantidade pedido',
    quantidade_por_contrato: 'Quantidade por contrato',
    quantidade_produto: 'Quantidade de produtos',
    qtd: 'Qtd.',
    quantidade_acoes: 'Quantidade de Ações',
    quantidade_acoes_execucao: 'Quantidade de ações em execução',
    quantidade_acoes_planejadas: 'Quantidade de ações planejadas',
    quantidade_estoque: 'Quantidade estoque',
    quantidade_inconsistencia: 'Quantidade de inconsistências',
    quantidade_linhas_adicionar: 'Quantidade de linhas para adicionar',
    quantidade_maior_disponivel: 'Quantidade maior que o disponível',
    qtd_acao: 'Qtd. Ação',
    qtd_disponivel: 'Qtd. Disponível',
    range: 'Range',
    rascunho: 'Rascunho',
    rating: 'Rating',
    razao_social: 'Razão Social',
    realizar_alteracao: 'Realizar Alteração',
    rateio: 'Rateio',
    rateio_acordo: 'Rateio de acordo',
    rateio_orcamento: 'Rateio Orçamento',
    rateio_manual_por_fornecedor: 'Rateio manual por fornecedor',
    rateio_proporcional_cliente: 'Rateio proporcional por cliente',
    rateio_proporcional_fornecedor: 'Rateio proporcional por fornecedor',
    realizado: 'Realizado',
    realizado_meta: 'Realizado',
    reagendar: 'Reagendar',
    rebate: 'Rebate',
    recarregar: 'Recarregar',
    recebe_mail: 'Recebe e-mail',
    recebimento: 'Recebimento',
    recebido: 'Recebido',
    recusar: 'Recusar',
    recusar_lote: 'Recusar lote',
    redefinir_senha: 'Redefinir senha',
    redefinicao_email_usuario: 'Redefinição e-mail usuário.',
    redirecionamento: 'Redirecionamento',
    redirecionamento_fluxo: 'Redirecionamento do fluxo',
    reducao: 'Redução',
    reducao_massiva: 'Redução Massiva',
    reembolso_solicitado: 'Reembolso Solicitado',
    reenviar: 'Reenviar',
    reenviar_pagamento: 'Reenviar pagamento',
    reenviar_recebimento: 'Reenviar recebimento',
    referencia_de: 'Referência de',
    referencia_ate: 'Referência até',
    regiao: 'Região',
    regiao_loja: 'Região da loja',
    regional: 'Regional | Regionais',
    registros_acesso: 'Registro de acesso',
    registros_inseridos: 'Registros Inseridos',
    registros_inativados: 'Registros Inativados',
    registro_ja_cancelado: 'Registro {id} já cancelado',
    registros_ja_cancelados: 'Os seguintes registros já foram cancelados: {ids}',
    registro_ja_inativo: 'Registro {id} já inativa',
    registros_ja_inativos: 'Os seguintes registros já estão com status inativa: {ids}',
    registro_ja_aguardando_inativacao: 'Registro já está aguardando inativação',
    registro_atualizado_com_sucesso: 'Registro atualizado com sucesso! | Registros atualizados com sucesso!',
    registros_atualizados: 'Registros Atualizados',
    reivindicacao: 'Reivindicaçao | Reivindicações',
    reivindicar: 'Reivindicar',
    reivindicar_apuracao: 'Reivindicar Apuração',
    relatorio: 'Relatório | Relatórios',
    relatorios: {
      roi: 'ROI',
      tipo_investimento: 'Tipo de Investimento',
      faturamento_periodo_anterior: 'Faturamento Período Anterior',
      faturamento_periodo_atual: 'Faturamento Período Atual',
      custo_total_periodo_anterior: 'Custo total Período Anterior',
      custo_total_periodo_atual: 'Custo total Período Atual',
      margem_periodo_anterior: 'Margem Período Anterior',
      margem_periodo_atual: 'Margem Período Atual',
      investimento_periodo_anterior: 'Investimento Período Anterior',
      investimento_periodo_atual: 'Investimento Período Atual',
    },
    reload_grid: 'Recarregar grid',
    remanescente: 'Remanescente | Remanescentes',
    remover: 'Remover',
    remover_passo: 'Remover passo',
    remover_justificativa: 'Remover justificativa',
    renovar: 'Renovar',
    reportada: 'Reportada',
    reportes_pendentes: 'Reportes Pendentes',
    representante: 'Representante',
    representante_marcado: 'Marcado',
    representante_desmarcado: 'Desmarcado',
    repres_invest: 'Repres. Invest.',
    reprocessar: 'Reprocessar | Calculado atual diferente do Valor Apurado (Pendente Reprocessamento)',
    reprocessar_lote: 'Reprocessar lote',
    reprocessar_todos: 'Reprocessar todos',
    reprocessar_cinquenta: 'Reprocessar Cinquenta',
    reprovar: 'Reprovar',
    reprovado: 'Reprovado',
    reprovar_todas: 'Reprovar todas',
    reprovar_todos: 'Reprovar todos',
    reservado: 'Reservado',
    resgatar: 'Resgatar',
    responsavel: 'Responsável | Responsáveis',
    responsavel_conclusao_etapa: 'Responsável pela conclusão da etapa',
    responsavel_faturamento: 'Responsável pelo faturamento',
    restante: 'Restante',
    resumo: 'Resumo',
    resumo_contrato: 'Resumo do contrato',
    resultado_avaliacao: 'Resultado Avaliação',
    resultado_esperado: 'Resultado Esperado | Resultados Esperados',
    revalidacao: 'Revalidação',
    risco: 'Risco',
    rg: 'RG',
    roi: 'ROI',
    roi_volume: 'Volume Sell In',
    saldo: 'Saldo',
    saldo_ajustado: 'Saldo ajustado',
    saldo_atual: 'Saldo atual',
    saldo_conta_corrente: 'Saldo Conta Corrente',
    saldo_disponivel: 'Saldo disponível',
    saldo_pos_transferencia: 'Saldo pós transferência',
    saldo_disponivel_materiais: 'Saldo disponível materiais',
    saldo_pdi: 'Saldo PDI',
    saldo_solicitado: 'Saldo Solicitado',
    saldo_total: 'Saldo total',
    saldo_vendedor: 'Saldo por Vendedor',
    salvar: 'Salvar',
    salvar_acao: 'Salvar ação',
    salvar_continuar: 'Salvar e continuar',
    salvar_mais_um: 'Salvar +1',
    salvar_rascunho: 'Salvar rascunho',
    sap_pagador: 'SAP pagador',
    secao: 'Seção',
    selecione: 'Selecione',
    selecione_aprovadores: 'Selecione aprovadores',
    selecione_modalidade: 'Selecione a modalidade',
    selecione_solicitante: 'Selecione solicitante',
    selecione_divisoes: 'Selecione as divisões',
    sellin: 'Sell In',
    sellin_detalhado: 'Sell In Detalhado',
    sellin_por_cliente: 'Sell In por Cliente',
    sellin_valor: 'Sell In valor',
    sellin_volume: 'Sell In volume',
    sellout: 'Sell Out',
    sellout_valor: 'Sell Out valor',
    sellout_volume: 'Sell Out volume',
    sellin_sellout: 'Sell In/Sell Out',
    sem_alcada: 'Sem alçada',
    sem_comprovacao: 'Sem comprovação',
    sem_condicional: 'Sem condicional',
    sem_controle_estoque: 'Sem Controle de Estoque',
    sem_valor_final: 'Sem valor final',
    sempre: 'Sempre',
    setor: 'Setor | Setores',
    seq_item: 'Seq. Item',
    service: 'Service',
    servico: 'Serviço | Serviços',
    sim: 'Sim',
    sim_nao: 'Sim/Não',
    segmento: 'Segmento | Segmentos',
    sigla: 'Sigla',
    situacao: 'Situação',
    sla: 'SLA (horas)',
    status: 'Status',
    status_permitidos: 'Status permitidos',
    status_ano_fiscal: {
      BLOQUEIO: 'Bloqueio',
      VIGENTE: 'Vigente',
      VIGENTE_COM_BLOQUEIO: 'Vigente com bloqueio',
      FECHADO: 'Fechado',
      PROXIMO: 'Próximo',
      EM_CADASTRO: 'Em Cadastro',
      VIGENTE_NAO_INICIADO: 'Vigente não iniciado',
    },
    status_alterado: 'Status alterado',
    status_acordo: 'Status do acordo',
    status_acao: 'Status da ação',
    status_apuracao: 'Status da apuração',
    status_entrega: 'Status entrega',
    status_integracao: 'Status da integração',
    status_interno: 'Status interno',
    status_fluxo: 'Status do fluxo',
    status_layout: 'Status do Layout',
    status_negociacao: 'Status das Negociações',
    status_observacao: 'Observação sobre o status',
    status_orcamento: 'Status do orçamento',
    status_pagamento: 'Status do pagamento',
    status_planejamento: 'Status do planejamento',
    status_processo: 'Status de processo',
    status_reembolso: 'Status Reembolso',
    segmento_canal: 'Segmento do canal',
    sobrenome: 'Sobrenome',
    solicitacao_reembolso: 'Solicitação Reembolso',
    solicitado: 'Solicitado | Solicitados',
    solicitado_por: 'Solicitado por',
    solicitante: 'Solicitante',
    solicitar_analise: 'Solicitar análise',
    solicitar_aprovacao: 'Solicitar aprovação',
    solicitar_reembolso: 'Solicitar reembolso',
    solicitar_reembolso_liberados: 'Solicitar reembolso de liberados',
    soma: 'Soma',
    soma_valor_monetario_acoes: 'Soma do valor monetário das ações',
    subcategoria: 'Subcategoria',
    subgrupo: 'Subgrupo',
    sub_regioes: 'Sub-Regiões',
    sugestao_fornecedor: 'Sugestão fornecedor',
    sugestao_fornecedor_aqui: 'Faça aqui a sugestão de um fornecedor (informe dados para contato) ou descreva melhor o item',
    superior: 'Superior',
    superior_imediato: 'Superior Imediato',
    superior_imediato_atual: 'Superior imediato atual',
    supervisor: 'Supervisor',
    suporte: 'Suporte',
    tarefa_aprovacao: 'Tarefa de aprovação',
    tabela_sem_anexo: 'Nenhum arquivo anexado.',
    tabela_sem_conteudo: 'Nenhuma informação encontrada.',
    tabloide: 'Tablóide',
    tamanho: 'Tamanho | Tamanhos',
    tamanho_maximo_descritivo_texto: 'Máximo (ex: 200)',
    tamanho_maximo_descritivo_numerico: 'Máximo',
    tamanho_minimo_descritivo_numerico: 'Mínimo',
    telefone: 'Telefone',
    tempo: 'Tempo',
    template: 'Template',
    template_calculo: 'Template de cálculo',
    territorio: 'Território',
    termos_condicoes: 'Termos e condições',
    termo_aceite: 'Termo de aceite',
    textarea: 'Área de texto',
    texto: 'Texto',
    taxas_servico: 'Taxas de serviço',
    tipo: 'Tipo | Tipos',
    tipo_acao: 'Tipo de ação',
    tipo_acordo: 'Tipo de acordo',
    tipo_de_ajuste: 'Tipo de ajuste',
    tipo_apuracao: 'Tipo de apuração',
    tipo_campo: 'Tipo do campo',
    tipo_contrato: 'Tipo de contrato',
    tipo_de_verba: 'Tipo de verba',
    tipo_provisao: 'Tipo de provisão',
    tipo_rateio: 'Tipo de rateio',
    tipo_valor: 'Tipo de valor',
    tipo_verba: 'Tipo verba',
    tipo_ano_fiscal: {
      empresa: 'Empresa',
      cliente: 'Cliente',
    },
    tipo_bonificacao: 'Tipo de bonificação',
    tipo_destino_verba: {
      PATROCINIO: 'Patrocínio',
      ESTANDE: 'Estande',
      MATERIAL_PROMOCIONAL: 'Material promocional',
      PROJETO_ESPECIAL: 'Projeto especial',
      PROJETO_FACHADAS: 'Projeto fachadas',
      OUTROS: 'Outros',
    },
    tipo_de_fluxo: 'Tipo de fluxo',
    tipo_fluxo: 'Tipo fluxo',
    tipo_negociacao: 'Tipo de Negociação',
    tipo_importacao: 'Tipo de importação',
    tipo_de_investimento: 'Tipo de linha de orçamento',
    tipo_investimento: 'Tipo de linha de orçamento | Tipos de linha de orçamento',
    tipo_investimento_gerencial: 'Tipo investimento',
    tipo_linha: 'Tipo de linha',
    tipo_linha_investimento: 'Tipo de linha de investimento',
    tipo_linha_orcamento: 'Tipo de linha de orçamento',
    tipo_orcamento: 'Tipo de Orçamento',
    tipo_investimento_tipo_orcamento: 'Tipo de linha de orçamento do tipo orçamento',
    tipo_pagamento: 'Tipo de pagamento',
    tipos_pagamento: 'Tipos de pagamento',
    tipos_pagamentos_aceitos: 'Tipos de pagamentos aceitos',
    tipo_patrocinio: 'Tipo de Patrocínio',
    tipo_periodo: 'Tipo de período',
    tipo_pessoa: 'Tipo pessoa',
    tipo_usuario: 'Tipo de usuário',
    todos: 'Todos',
    totais: 'Totais',
    total: 'Total',
    total_ajustado: 'Total ajustado',
    total_disponivel: 'Total disponível',
    total_pago: 'Total pago',
    total_reservado: 'Total reservado',
    total_vencer: 'Total a vencer',
    total_geral: 'Total geral',
    total_de_recebimento: 'Total de recebimentos',
    total_de_recebimento_contrato: 'Total de recebimentos do contrato',
    total_recebimento: 'Total receb.',
    total_pagamento: 'Total pagamento | Total pagamentos',
    total_solicitacao: 'Total da solicitação',
    total_verba_materiais: 'Total pedido materiais:',
    total_verba_planejada: 'Total verba {tipoVerba} planejada:',
    tradelinks: 'Tradelinks - Solução Eletrônica de Negócios',
    tradelinks_curto: 'Tradelinks',
    transferencia: 'Transferência',
    transferencia_posse: 'Transferência de Posse',
    transferencia_temporaria: 'Temporária',
    transferido_por: 'Transferido por',
    transferir: 'Transferir',
    transferir_todos: 'Transferir todos',
    transporte_aprovado: 'Transporte aprovado',
    trimestre: 'Trimestre',
    trimestral: 'Trimestral',
    trimestral_ano_calendario: 'Trimestral - ano calendário',
    uf: 'UF',
    un_medida: 'UN. Medida',
    unica_execucao: 'Única execução',
    unica_liquidacao: 'Única liquidação',
    unica_vez: 'Única vez',
    unidade: 'Unidade',
    unidade_medida: 'Unidade de medida',
    unidadenegocio: 'Unidade de negócio',
    unidade_negocio: 'Unidade de negócio | Unidade negócio',
    unidades_negocio: 'Unidades de negócio',
    unidade_negocio_produto: 'Unidade de negócio do produto',
    unidade_dinheiro: 'R$',
    unidade_dinheiro_litro: 'R$/L',
    upload: 'Upload',
    upload_acordo: 'Upload de Acordo',
    upload_arquivo_negociacao: 'Upload Arquivo de Negociação',
    upload_anexo: 'Upload de anexo',
    upload_anexo_passo_1_obrigatorio: 'Upload de anexo no Passo 1 obrigatório para solicitar a aprovação.',
    upload_evidencia: 'Upload de Evidência',
    upload_evidencia_layout: 'Evidência de Layout',
    upload_evidencia_orcamento: 'Evidência de Orçamento',
    upload_arquivo_obrigatorio: 'Upload de arquivo é obrigatório',
    upload_arquivo_sucesso: 'Upload de arquivo realizado com sucesso',
    upload_arquivo_obrigatorio_aprovacao: 'Upload de arquivo é obrigatório para solicitar aprovação',
    upload_obrigadorio_programa_marketing: 'Upload de arquivo Plano de Ação PDI Bônus é obrigatório para o programa de marketing {param}',
    usar_proprio_endereco: 'Usar o próprio endereço',
    usuario: 'Usuário | Usuários',
    usuario_alteracao: 'Usuário de alteração',
    usuario_aprovador: 'Usuário aprovador',
    usuario_criador: 'Usuário criador',
    usuario_criacao: 'Usuário de criação',
    usuario_externo_master: '{tipo_usuario} master',
    usuario_ja_cadastrado: 'Usuário já cadastrado',
    usuario_ultima_aprovacao: 'Usuário da última aprovação',
    usuarios_sem_perfil: 'Usuários sem Perfil',
    validacao: 'Validação | Validações',
    valor: 'Valor | Valores',
    valor_reducao_para: 'Valor redução para',
    valor_acao: 'Valor da ação',
    valor_acordo: 'Valor do acordo',
    valor_ajustado: 'Valor ajustado',
    valor_ajuste: 'Valor do ajuste',
    valor_aportado: 'Valor aportado',
    valor_aportar: 'Valor a aportar',
    valor_apurado: 'Valor apurado',
    valor_apuracao: 'Valor apuração',
    valor_assinado: 'Valor assinado',
    valor_base: 'Valor Base',
    valor_base_crescimento: 'Valor Base Crescimento',
    valor_bruto: 'Valor bruto',
    valor_credito_gerado: 'Valor Crédito Gerado',
    valor_confirmado_negociacao: 'Valor Confirmado Negociação',
    valor_consumido: 'Valor consumido',
    valor_consumido_orcamento: 'Valor consumido do orçamento',
    valor_contrato: 'Valor do contrato',
    valor_desdobramento: 'Valor Desdobramento',
    valor_disponibilizado: 'Valor disponibilizado',
    valor_disponivel: 'Valor disponível',
    valor_disponivel_pos_transferencia: 'Valor disponivel pós transferência',
    valor_encargos: 'Valor encargos',
    valor_estorno: 'Valor do estorno',
    valor_final: 'Valor Final',
    valor_fixo: 'Valor fixo',
    valor_final_frete: 'Valor final frete',
    valor_historico: 'Valor histórico',
    valor_honorarios: 'Valor honorários',
    valor_inicial: 'Valor Inicial',
    valor_investimento: 'Valor Investimento',
    valor_oi_invalido: 'Valor da soma das OI\'s inválido',
    valor_minimo: 'Valor mínimo',
    valor_nao_alocado: 'Valor não alocado',
    valor_negociado: 'Valor negociado',
    valor_nf: 'Valor da NF',
    valor_nota_debito: 'Valor da Nota de Débito',
    valor_liquido: 'Valor líquido',
    valor_mercadoria: 'Valor da mercadoria',
    valor_percentual: 'Valor Percentual',
    valor_orcamento: 'Valor do orçamento',
    valor_pago: 'Valor pago',
    valor_pagar: 'Valor a pagar',
    valor_pagamento: 'Valor do Pagamento',
    valor_pagamento_calculado: 'Valor calculado do Pagamento',
    valor_pendente_ajuste: 'Valor pendente de ajuste',
    valor_percentagem_oi_invalido: 'A soma dos percentuais das OIs devem ser 100%',
    valor_planejado: 'Valor planejado',
    valor_provisionado: 'Valor provisionado',
    valor_realizado: 'Valor realizado',
    valor_realizado_original: 'Valor original',
    valor_realizado_solicitado: 'Valor alterado',
    valor_recebido: 'Valor recebido',
    valor_recebimento: 'Valor do recebimento',
    valor_reduzir: 'Valor a reduzir',
    valor_reivindicado: 'Valor reivindicado',
    valor_remanescente: 'Valor remanescente',
    valor_restante_orcamento: 'Valor restante do orçamento',
    valor_reservado: 'Valor reservado',
    valor_reserva: 'Valor da reserva',
    valor_total: 'Valor total',
    valor_total_acao: 'Valor total da ação',
    valor_total_aprovado: 'Valor Total Aprovado',
    valor_total_pedido: 'Valor Total Pedido',
    valor_transferencia: 'Valor Transferência',
    valor_unitario: 'Valor Unitário',
    valor_variavel: 'Valor variável',
    variavel: 'Variável',
    var_margem: 'VAR Margem',
    var_investimento: 'VAR Investimento',
    var_investimento_trade: 'VAR Investimento Trade',
    vencimento: 'Vencimento',
    verba_sobre_fat: 'Verba sobre Fat.',
    vendedor: 'Vendedor',
    vendedores_usuario: 'Vendedores do usuário',
    ver_mais: 'Ver mais',
    ver_todos: 'Ver todos',
    verba: 'Verba',
    verba_aprovada: 'Verba aprovada',
    verba_disponivel: 'Verba disponível',
    verba_consumida: 'Verba consumida',
    verba_estrutural: 'Verba Estrutural | Verbas Estruturais',
    verba_excedente: 'Verba excedente',
    verba_liberada: 'Verba liberada?',
    verba_tatica: 'Verba Tática | Verbas Táticas',
    verbas_tecnicas: 'Verbas Técnicas',
    verba_cliente_representante: 'Representante',
    verba_cliente_regional: 'Regional',
    view: 'View',
    views: 'Views',
    vigencia: 'Vigência',
    vigencia_contrato: 'Vigência do contrato',
    vigencia_aberta: 'Vigência aberta',
    vigencia_inicial: 'Vigência inicial',
    vigencia_final: 'Vigência final',
    vigencia_orcamento: 'Vigência do orçamento',
    vigencia_periodo_configurado: 'Vigência do período configurado',
    vinculo: 'Vínculo',
    vinculo_carteira_cliente: 'Vínculo na carteira de cliente',
    vinculo_industria: 'Vínculo com a indústria',
    visibilidade: 'Visibilidade',
    visao: 'Visão | Visão do Gráfico',
    visao_usuario_cliente: 'Visão do usuário cliente',
    visao_aprovado_liquidado: 'Total de ações pagas',
    visao_top_5_por_tipo_acao: 'TOP 5 - tipo de ação',
    visualizar: 'Visualizar',
    visualizar_acao: 'Visualizar ação',
    visualizar_cliente: 'Visualizar cliente | Visualizar clientes',
    visualizar_detalhes: 'Detalhes',
    visualizar_divisao: 'Visualizar divisão',
    visualizar_erros: 'Visualizar erros',
    visualizar_execucao: 'Visualizar execução',
    visualizar_gestor: 'Visualizar gestor | Visualizar gestores',
    visualizar_orcamento: 'Visualizar orçamento',
    visualizar_perfil: 'Visualizar perfil',
    visualizar_peca: 'Visualizar peças',
    visualizar_produto: 'Visualizar produto',
    visualizar_usuario: 'Visualizar usuário',
    visualizar_estrutura_usuario: 'Visualizar estruturas de usuário',
    voltar: 'Voltar',
    voltar_dashboard: 'Voltar ao Dashboard Inicial',
    voltar_passo: 'Voltar passo',
    volume: 'Volume',
    volume_m: 'Volume (m³)',
    volume_produto: 'Volume do produto',
    vlr_adendo: 'Valor adendo',
    vlr_aditivo: 'Valor aditivo',
    vlr_aportado: 'Valor aportado',
    vlr_base_crescimento: 'Valor Base Crescimento',
    vlr_calculado: 'Calculado',
    vlr_conf_acao: 'Valor confirmado ação',
    vlr_conf_apuracao: 'Valor confirmado apuração',
    vlr_conf_negociacao: 'Confirmado negociação',
    vlr_consumido: 'Valor consumido',
    vlr_comp_orcamento: 'Complemento de orçamento',
    vlr_comprometido: 'Valor comprometido',
    vlr_incial_maior_vlr_final: 'O valor inicial é maior que o valor final',
    vlr_investido: 'Valor Investido',
    vlr_conflitante: 'Valores conflitantes com outro fluxo',
    vlr_gasto: 'Valor gasto',
    vlr_pendente: 'Pendente',
    vlr_planejado: 'Valor planejado',
    vlr_reducao: 'Valor redução',
    vlr_reivindicado: 'Reivindicado',
    vlr_reservado: 'Valor reservado',
    vlr_reservado_atual: 'Valor reservado atual',
    vlr_saldo: 'Saldo disponível',
    vlr_unitario: 'Vlr. Unitário',
    vlr_total: 'Vlr. Total',
    workflow: 'Workflow',
    workflow_aprovacao: 'Workflow de aprovação',
    workflow_buscar_grupos: 'Buscar Perfis',
    workflow_buscar_subgrupos: 'Buscar Estruturas de Usuário',
    workflow_buscar_usuarios: 'Buscar Usuários',
    worfklow_passo: 'Passo {passo}',
    workflow_seleciona_aprovador: 'Seleciona aprovador?',
    workflow_solicitante: 'Solicitante',
    workflow_solicitante_grupo: 'Perfil',
    workflow_solicitante_usuario: 'Usuário',
    workflow_tooltip: '{placeholder} do Workflow',
  },
  message: {
    acao_aprovada: 'Ação aprovada.',
    acao_aprovada_automatica: 'Ação aprovada automaticamente. Não há fluxo de aprovação.',
    acao_atualizada: 'Ação atualizada com sucesso.',
    acao_cancelada: 'Ação cancelada com sucesso.',
    acao_cancelada_justificativa: 'Justificativa Cancelamento feito por {responsavelCancelamento}: {justificativaCancelamento}',
    acao_enviada_analise: 'Ação enviada para análise.',
    acao_enviada_apuracao: 'Ação enviada para apuração',
    acao_necessaria_contrato: 'Nenhuma ação associada ao contrato',
    acao_nao_reportada: 'Ação não reportada. É obrigatório realizar o Reporte da ação antes de enviar o Pagamento para aprovação.',
    acao_nao_pode_cancelar: 'Ação não pode ser cancelada pois possui pagamento criado.',
    acao_reprovada: 'Ação reprovada.',
    acao_pdv_remover_material: 'A ação PDV precisa ter pelo menos um material.',
    acesso_negado: 'Acesso negado.',
    acesso_negado_orcamento: 'Acesso negado ao orçamento.',
    acao_removida: 'Ação removida com sucesso.',
    acoes_saldo_de_para: 'Todas as ações e saldo do usuário {de} serão transferidos permanentemente para {para};',
    acrescente_um_peca_carrinho: 'Acrescente pelo menos 01 para incluir a peça no carrinho.',
    advertencia_periodos_iguais: 'Ao selecionar períodos de orçamento igual aos períodos de planejamento, todos os períodos de orçamento previamente incluídos, serão excluídos e substituídos por períodos idênticos aos períodos planejados. Deseja continuar?',
    aguarde: 'Esta operação pode levar alguns minutos. Aguarde.',
    ajuda: {
      parametrizacao_orcamento: {
        campo_padrao: 'Apresenta os campos padrões que sempre farão parte do cadastro do orçamento e sua obrigatoriedade',
        campos_customizados: 'Apresenta os campos customizados, ativos e associados a entidade Orçamento. Caso ele faça parte do cadastro do orçamento você deve <br />selecionar as opções "Resumo" (informações gerais do orçamento) ou "Linha de investimento" (detalhe da alocação de verba no orçamento)',
        campos_gerais: '<ol><li>Centro de custo: para incluir esses campos no cadastro do orçamento você deve selecionar as opções "Resumo" <br />(informações gerais do orçamento) ou "Linha de investimento" (detalhe da alocação de verba no orçamento)</li></ol>',
        cliente: '<ol><li>Para incluir alguma informação de cliente no cadastro do orçamento você deve selecionar as opções "Resumo" <br />(informações gerais do orçamento) ou "Linha de investimento" (detalhe da alocação de verba no orçamento)</li><li>Se você selecionar Cliente a seleção será um cliente específico, por esse motivo recomenda-se não incluir essa opção <br />no Resumo do orçamento mas sim no detalhe na linha de investimento</li><li>Se você selecionar Canal a seleção será um Canal específico, por esse motivo recomenda-se não incluir essa opção <br /> no Resumo do orçamento mas sim no detalhe na linha de investimento</li></ol>',
        comportamento: 'Define como o orçamento será planejado: <ol><li>Com preenchimento manual o cadastro do orçamento será manual</li><li>Com preenchimento automático sem projeção de verba será aplicada normalmente para verbas de cliente. O Tradelinks irá disponibilizar <br />automaticamente a verba a partir do cumprimento de alguma ação acordada com o cliente</li><li>Com preenchimento automático com projeção de verba será aplicada normalmente para verbas de cliente. O Tradelinks irá disponibilizar automaticamente <br />a verba a partir do cumprimento de alguma ação acordada com o cliente porém poderá ser informada manualmente a projeção de <br />verba esperada antes que as ações sejam apuradas</li></ol>',
        divisao: '<ol><li>A divisão sempre estará associada a linha de investimento</li><li>Selecione apenas as divisões que são responsáveis por iniciar a distribuição de verba após conclusão/aprovação do orçamento</li><li>Se você selecionou uma Divisão pai de alguma outra Divisão, as divisões filhas não poderão ser selecionadas</li></ol>',
        grid_filtros: 'Definição dos campos que serão apresentados no grid e/ou filtro: <br />Restrições de grid <ol><li>Os campos de linha de orçamento não serão apresentados no grid</li><li>Os campos com apresentação pré-definida no grid não poderão ser retirados</li></ol> Restrições de filtro<ol><li>Campos padrões: os campos textos e numéricos não poderão compor o filtro</li><li>Campos customizados: apenas os campos customizados dos tipos Lista, Múltipla escolha e Sim/Não poderão compor os filtros</li></ol>',
        ordenacao: 'Ordenação dos campos apresentados no formulário de cadastro, edição e rateio do orçamento. Essa ordenação será considerada <br />para apresentação dos campos no grid de listagem do orçamento',
        produto: '<ol><li>Para incluir alguma informação de produto no cadastro do orçamento você deve selecionar as opções "Resumo" <br />(informações gerais do orçamento) ou "Linha de investimento" (detalhe da alocação de verba no orçamento)</li><li>Recomenda-se incluir no resumo do orçamento campos mais abrangentes como Marca e Categoria e no detalhe na linha <br />de investimento campos menos gerais como produto (SKU)</li></ol>',
        rateio: 'Forma de rateio por divisão <ol><li>O rateio por Divisão deverá ser utilizado quando a distribuição de verba é realizada considerando a estrutura <br />organizacional da indústria que poderá ser geográfica ou não</li><li>Para a identificação das divisões que farão parte do rateio serão consideradas todas as divisões <br />associadas a(s) divisão(ões) selecionadas no Passo 2</li><li>A composição por Canal e/ou Cliente só serão apresentadas se foram selecionadas no Passo 2 e caso sejam <br />selecionadas atuarão para restrição na pesquisa das divisões</li></ol>',
        resumo: '<ol><li>Nome e descrição do tipo de orçamento. O nome será apresentado no menu Novo da tela de Orçamento</li><li>Ícone que será apresentado no menu Novo da tela de Orçamento e deve receber o nome da imagem do material design</li><li>Qualquer alteração nas configurações desse tipo de orçamento será aplicada para os novos orçamentos. Os demais status <br />(Em cadastro, Aprovado, Aguardando aprovação, Em análise, Reprovado e Cancelado) não serão impactados</li></ol>',
        tipo_investimento: 'Define quais investimentos poderão ser planejados para o tipo de orçamento. Se um Tipo de Investimento for inativado ele não será apresentado',
        validacao: '<ol><li>Habilitar alocação da verba parcial: caso essa regra seja habilitada, o usuário poderá concluir ou enviar um <br />fluxo de aprovação de orçamento sem distribuir totalmente o valor do orçamento</li><li>Habilitar o bloqueio de uso da verba em ações quando houver evento de calendário “bloqueio de uso de verba” vigente.</br> Nesse caso as ações criadas com esse tipo de verba durante o período de bloqueio não poderão ser enviadas para aprovação.</li></ol>',
      },
      parametrizacao_acao: {
        acesso_cliente: 'Habilite essa opção caso o cliente da indústria possa criar ações desse tipo de ação',
        acesso_divisao: 'Selecione as divisões que poderão criar esse tipo de ação na tela de Planejamento. Caso não seja selecionada uma Divisão, todos os usuários com privilégios poderão criar esse tipo de ação',
        acesso_estrutura_usuario: 'Selecione as estruturas de usuário que poderão criar esse tipo de ação na tela de Planejamento. <br />Caso não seja selecionada uma Estrutura de usuário, todos os usuários com privilégios poderão criar esse tipo de ação',
        apuracao: '<ol><li>Configure como será a apuração da ação: <br />Manual (upload de evidência manual) ou <br />Automática (calculado a partir da importação de SellIn e/ou SellOut a partir de um template de cálculo)</li><li>Flag Habilitar alteração do valor realizado calculado automaticamente: <br />Ativa ou inativa a edição do campo Realizado na etapa de apuração da ação.</li></ol>',
        apuracao_base_calculo: 'Para as metas de valor com forma de bonificação em percentual,<br /> representa se o foco será o valor líquido ou bruto do SellIn para cálculo do atingimento ou <br />pagamento da meta ou SellOut para cálculo do atingimento da meta',
        apuracao_bonificacao: 'Representa como será calculado o pagamento caso a meta definida na ação seja atingida: <ol><li>Fixo: pagamento de um valor fixo</li><li>Variável: pagamento calculado para cada unidade vendida paga-se um valor</li><li>Percentual: pagamento a partir de um percentual do valor líquido ou bruto do SellIn</li></ol>',
        apuracao_evidencia: 'Defina se o upload de evidências será habilitado na Apuração.',
        apuracao_inicio: 'Defina quando a ação poderá ser apurada: <br />após a conclusão do período da vigência ou a partir do início da vigência',
        apuracao_meta_beneficio: 'Representa o foco da definição da meta de atingimento desse tipo de ação, <br />ou seja, se o foco será Valor ou Volume baseado no SellIn ou SellOut. <br />Essa informação será utilizada para calcular se o cliente atingiu a meta acordada',
        apuracao_pagamento: 'Defina quais as opções de pagamento desse tipo de ação',
        apuracao_periodo: 'Defina como serão os períodos de apuração do tipo de ação. <br />Isso permite que uma mesma ação seja apurada em vários períodos. <br />A opção “Específico” estará habilitada se a vigência for configurada como aberta no Passo 4',
        apuracao_vendas: 'Fornece a razão entre o valor da ação (investimento) <br /> sobre o faturamento (líquido ou bruto) do SellIn para apoio na avaliação da ação.',
        calculo_roi: 'Habilite essa opção caso seja necessário cálculo de ROI - retorno do investimento, no passo 1 de cadastro da ação.',
        campo_padrao: 'Apresenta os campos padrões que sempre farão parte do cadastro do tipo de ação e sua obrigatoriedade. <br />Além disso, você pode definir qual o campo poderá ser editado quando a ação estiver no status aprovado',
        campo_divisao: 'Campo padrão do cadastro da ação e representa a Divisão responsável pela ação. É a partir desse campo que o Tradelinks identifica as contas correntes de Divisão.',
        campo_regional: 'Representa o Foco da ação que pode ou não ser a Divisão responsável pela ação.',
        campos_customizados: 'Apresenta os campos customizados, ativos e associados a entidade Ação. Caso ele faça parte do cadastro da ação você deve <br />selecionar pelo menos uma opção do Fluxo de Trademarketing (Planejamento, Execução, Apuração e/ou Pagamento).<br />Essas opções serão apresentadas conforme opção selecionada no Passo 1 – Fluxo de Trademarketing.<br />Além disso, você pode definir qual o campo poderá ser editado quando a ação estiver no status aprovado',
        cliente: 'Informe pelo menos uma opção para habilitar a informação de foco na criação desse tipo de ação.<br />Isso é necessário pois toda ação deve ter como foco um cliente específico, uma holding ou uma regional.<br />Caso necessário, informe se terá a informação de cliente pagador',
        fluxo_aprovacao: 'Para cada tipo de fluxo defina quais as obrigatoriedades parametrizadas na ação devem ser consideradas e validadas no fluxo aprovação',
        grid_filtros: 'Definição dos campos que serão apresentados no grid e/ou filtro: <br />Restrições de grid <ol><li>Os campos com apresentação pré-definida no grid não poderão ser retirados</li></ol> Restrições de filtro<ol><li>Campos padrões: os campos textos e numéricos não poderão compor o filtro</li><li>Campos customizados: apenas os campos customizados dos tipos Lista, Múltipla escolha e Sim/Não poderão compor os filtros</li></ol>',
        heranca_configuracao: 'Ações que possuem somente a etapa de planejamento podem ser usadas em contrato e ter uma ação agrupadora. Quando isso ocorrer, faz-se necessário que as configurações da apuração da ação agrupadora sejam idênticas às da que compõem o agrupamento. Marque a opção “Herdar configuração de apuração da ação” e selecione um único tipo de ação.',
        liquidacao: 'Defina se é permitida a liquidação parcial. Além disso, configurar se existe ou não integração de liquidação com software terceiro',
        ordenacao: 'Ordenação dos campos apresentados no formulário de cadastro, consulta e edição do tipo de ação.<br />Essa ordenação será considerada para apresentação dos campos no grid de listagem das ações',
        pagamento: 'Defina se é permitido o pagamento parcial. Além disso, configurar se existe ou não integração de pagamento com software terceiro',
        pagamento_acao: 'Flag "Exibir todos os clientes ativos": quando ativado exibirá no campo de cliente pagador do cadastro do planejamento da ação todos os clientes no status ativo independentemente da opção selecionada no foco da ação.',
        periodo: 'Escolha como será a definição da vigência da ação. Se poderá ser escolhida pelo usuário ou se deverá respeitar o período de planejamento configurado na tela de Período',
        produto: '<ol><li>Para incluir alguma informação de produto no cadastro da ação você deve selecionar pelo menos uma opção do Fluxo de Trademarketing (Planejamento, Execução, Apuração e/ou Pagamento).<br />Essas opções serão apresentadas conforme opção selecionada no Passo 1 – Fluxo de Trademarketing</li><li>Defina também se o campo é obrigatório no cadastro da ação, ou seja, no módulo Planejamento</li></ol>',
        resumo: '<ol><li>Nome e descrição do tipo de ação. O nome será apresentado no menu Novo da tela de Planejamento</li><li>Qualquer alteração nas configurações desse tipo de ação será aplicada para as novas ações. Os demais status (Em cadastro, Aprovado, Aguardando aprovação, Em análise, Reprovado e Cancelado) não serão impactados</li>',
        status_criacao_pagamento: 'Permite que o pagamento seja criado em status específico.<br /> O flag "Criar pagamento no status liquidado", quando habilitado, fará com que os pagamentos sejam gerados diretamente no status liquidado',
        tipo_investimento: 'Selecione as contas correntes que esse tipo de ação poderá creditar ou debitar verba. Caso nenhuma conta corrente seja habilitada não haverá controle de verba na criação da ação',
        upload: 'Configure as regras de upload de arquivo na etapa de planejamento.',
        valor: 'Configure se o valor da ação será informado manualmente ou será calculado a partir de um template de cálculo',
        valor_pagamento: 'Defina sobre o comportamento do campo de valor de pagamento na etapa de apuração.',
        vencimento_apuracao_acao: 'Habilitar os vencimento das apurações de tipo de ação conforme a parametrização:<ol><li>Os status da apuração aplicados para os vencimentos são "Aguardando Apuração" e "Em análise"</li><li>Data para contar o prazo de vencimento: É a data a partir da qual se contará o prazo de vencimento da ação/apuração.</li><li>Prazo em dias para o vencimento: a quantidade de dias corridos para o vencimento da apuração da ação a partir da "Data para contar o prazo de vencimento" e nos status válidos.</li><li>Tipo de verba: quando houver, poderá restringir os tipos de verba em que será aplicado o processo. Se e somente se houver tipo de verba marcado no passo 1. Se deixado em branco, será aplicado para todos os tipos de verba selecionados no tipo da ação.</li><li>Habilitar notificação por e-mail antes do vencimento: se habilitado, ocorrerá o envio de e-mail aos usuários (funcionários e/ou clientes, de acordo com a seleção) quando estiver a X dias (campo prazo em dias para notificar) antes da data de vencimento da ação.</li></ol>',
        vigencia: 'Se nenhum campo desta seção for marcado, então a vigência da ação será aberta.<ol><li>Preenchimento automático: faz com que as datas de início e fim da ação sejam preenchidas automaticamente como a data atual.</li><li>Vigência Trimestral - ano calendário:  faz com que a ação tenha como opções de data início os meses em que se iniciam os trimestres do ano calendário (JAN-DEZ) e para data de fim os meses em que terminam os trimestres.</li></ol>',
        vinculo: 'Para cada tipo de orçamento defina quais as informações em comuns parametrizadas na ação e no tipo de orçamento devem ser consideradas e validadas na criação da ação',
      },
      transferencia_definitiva: {
        carteira_cliente: '<ol><li>Apresenta a carteira de clientes do usuário de origem selecionado no Passo 1.<br/>A carteira apresentada é composta somente de clientes que ainda não fazem parte da carteira de clientes do usuário de destino</li><li>A carteira de clientes é utilizada para recuperar os clientes e holdings do usuário ao criar uma nova negociação</li></ol>',
        cenario: 'Selecione o cenário de transferência de hierarquia.<br/>Atente para o tipo de cenário selecionado com o motivo que originou a realização dessa transferência.<br/>Se o motivo não se enquadrar em nenhum dos cenários apresentados, entre em contato com o suporte para alinhar sobre a necessidade.',
        cliente: '<ol><li>Apresenta todos os clientes cujo usuário de origem selecionado no Passo 1 está sinalizado como responsável principal pelo faturamento. A responsabilidade apresentada é composta somente de clientes que o usuário de destino não é o responsável principal</li><li>A responsabilidade pelo faturamento é utilizada nos relatórios gerenciais na consolidação dos valores de faturamento por vendedor e gestor</li></ol>',
        motivo: 'Informe o motivo que originou a realização dessa transferência.',
        orientacao_geral: 'A transferência definitiva se aplica quando um funcionário da AB Brasil for assumir definitivamente as ações, carteira de clientes e verba nos orçamentos vigentes de um outro funcionário, que por algum motivo deixará de exercer as suas funções no time comercial.',
        orientacao_geral_premissa: 'Segue algumas premissas dessa operação:',
        orientacao_geral_premissa_1: 'A operação é irreversível, ou seja, todos as operações de cada cenário não poderão ser desfeitas após a confirmação. Somente uma nova transferência definitiva poderá desfazer essas operações;',
        orientacao_geral_premissa_2: 'A operação só poderá ser realizada entre funcionários de mesmo Canal e Unidade de Negócio;',
        orientacao_geral_premissa_3: 'Os totais de faturamento e investimento (apresentados nos relatórios gerenciais) e que foram impactados pela transferência, passarão a compor os valores do funcionário que assumiu as funções do outro funcionário e sua respectiva hierarquia;',
        orientacao_geral_premissa_4: 'Os totais de orçamento apresentados no relatório Plano Comparativo, apresentarão os seus totais nos meses selecionados na transferência do funcionário que assumiu as funções do outro funcionário e sua respectiva hierarquia. Nos demais meses os valores estarão no funcionário que foi substituído e na sua hierarquia.',
        selecao_carteira: 'Se você deseja transferir parcialmente a carteira do usuário de origem, utilize os filtros para selecionar os clientes desejados.<br/>Caso você transfira a carteira completa, não é necessário a utilização dos filtros.',
        selecao_cliente: 'Se você deseja transferir parcialmente a responsabilidade pelo faturamento do usuário de origem, utilize os filtros para selecionar os clientes desejados.<br/>Caso você transfira a responsabilidade completa, não é necessário a utilização dos filtros.',
        selecao_orcamentos: 'Selecione os orçamentos que deverão ser transferidos. A partir desses orçamentos é que as ações serão identificadas e transferidas.',
        transferencia_carteira: '<ol><li>Transferir toda a carteira de clientes: transfere totalmente a carteira de clientes do usuário de origem para o usuário de destino</li><li>Transferir a carteira selecionado no filtro: transfere a carteira de clientes  resultado dos filtros selecionados do usuário de origem para o usuário de destino</li><li>Transferir os clientes selecionados: transfere somente os clientes selecionados na seção Carteira de clientes do usuário de origem para o usuário de destino</li></ol>',
        transferencia_responsabilidade_faturamento: '<ol><li>Transferir toda a responsabilidade: transfere totalmente a responsabilidade de faturamento de clientes do usuário de origem para o usuário de destino</li><li>Transferir todos os clientes selecionados no filtro cujo usuário de origem é o responsável principal : transfere a responsabilidade de faturamento dos clientes resultado dos filtros selecionados do usuário de origem para o usuário de destino</li><li>Transferir apenas os clientes selecionados cujo usuário de origem é o responsável principal : transfere a responsabilidade de faturamento dos clientes selecionados na seção Clientes do usuário de origem para o usuário de destino</li></ol>',
        usuario_de: '<ol><li>Se cenário 1 selecionado: Selecione o vendedor que você deseja alterar o gestor (superior imediato).</li><li>Se cenário 2 selecionado: Selecione o vendedor de origem do qual você está transferindo as ações, verba e carteira de clientes.</li><li>Se cenário 3 selecionado: Selecione o gestor de origem do qual você está transferindo as ações, verba e carteira de clientes. Obrigatoriamente o superior imediato do time de vendedores do gestor de origem será alterado para o gestor de destino.</li><li>Se cenário 4 selecionado: Selecione o usuário que faz parte da hierarquia de distribuição de verba no rateio e que não atua como vendedor para incluí-lo no rateio dos orçamentos aprovados.</li></ol>',
        usuario_para: '<ol><li>Se cenário 1 selecionado: Selecione o novo gestor (superior imediato) do vendedor selecionado na seção De.</li><li>Se cenário 2 selecionado: Selecione o vendedor de destino que assumirá definitivamente as ações, verba e carteira de clientes do vendedor selecionado na seção De.</li><li>Se cenário 3 selecionado: Selecione o gestor de destino que assumirá definitivamente as ações, verba e carteira de clientes do gestor selecionado na seção De.</li><li>Se cenário 4 selecionado: Selecione o usuário de destino que assumirá a distribuição da verba na hierarquia dos rateios de orçamento já criados, aprovados e vigentes.</li></ol>',
      },
      parametrizacao_periodo: {
        remover_condicao: 'Esta operação será irreversível, deseja prosseguir e remover esta condição percentual?',
      },
      parametrizacao_contrato: {
        acesso_cliente: 'Habilite essa opção caso o cliente da indústria possa criar contratos desse tipo de contrato',
        acesso_divisao: 'Selecione as divisões que poderão criar esse tipo de contrato na tela de Planejamento. Caso não seja selecionada uma Divisão, todos os usuários com privilégios poderão criar esse tipo de contrato',
        acesso_estrutura_usuario: 'Selecione as estruturas de usuário que poderão criar esse tipo de contrato na tela de Planejamento. <br />Caso não seja selecionada uma Estrutura de usuário, todos os usuários com privilégios poderão criar esse tipo de contrato',
        apuracao: '<ol><li>Configure como será a apuração da ação: <br />Manual (upload de evidência manual) ou <br />Automática (calculado a partir da importação de SellIn e/ou SellOut a partir de um template de cálculo)</li><li>Flag Habilitar alteração do valor realizado calculado automaticamente: <br />Ativa ou inativa a edição do campo Realizado na etapa de apuração do contrato.</li></ol>',
        apuracao_base_calculo: 'Para as metas de valor com forma de bonificação em percentual,<br /> representa se o foco será o valor líquido ou bruto do SellIn para cálculo do atingimento ou <br />pagamento da meta ou SellOut para cálculo do atingimento da meta',
        apuracao_bonificacao: 'Representa como será calculado o pagamento caso a meta definida na ação seja atingida: <ol><li>Fixo: pagamento de um valor fixo</li><li>Variável: pagamento calculado para cada unidade vendida paga-se um valor</li><li>Percentual: pagamento a partir de um percentual do valor líquido ou bruto do SellIn</li></ol>',
        apuracao_evidencia: 'Defina se o upload de evidências será habilitado na Apuração.',
        apuracao_inicio: 'Defina quando o contrato poderá ser apurada: <br />após a conclusão do período da vigência ou a partir do início da vigência',
        apuracao_meta_beneficio: 'Representa o foco da definição da meta de atingimento desse tipo de contrato, <br />ou seja, se o foco será Valor ou Volume baseado no SellIn ou SellOut. <br />Essa informação será utilizada para calcular se o cliente atingiu a meta acordada',
        apuracao_pagamento: 'Defina quais as opções de pagamento desse tipo de contrato',
        apuracao_periodo: 'Defina como serão os períodos de apuração do tipo de contrato. <br />Isso permite que uma mesma ação seja apurada em vários períodos. <br />A opção “Específico” estará habilitada se a vigência for configurada como aberta no Passo 4',
        apuracao_vendas: 'Fornece a razão entre o valor do contrato (investimento) <br /> sobre o faturamento (líquido ou bruto) do SellIn para apoio na avaliação do contrato.',
        calculo_roi: 'Habilite essa opção caso seja necessário cálculo de ROI - retorno do investimento, no passo 1 de cadastro do contrato.',
        campo_padrao: 'Apresenta os campos padrões que sempre farão parte do cadastro do tipo de contrato e sua obrigatoriedade. <br />Além disso, você pode definir qual o campo poderá ser editado quando o contrato estiver no status aprovado',
        campo_divisao: 'Campo padrão do cadastro do contrato e representa a Divisão responsável pelo contrato. É a partir desse campo que o Tradelinks identifica as contas correntes de Divisão.',
        campo_regional: 'Representa o Foco do contrato que pode ou não ser a Divisão responsável pelo contrato.',
        campos_customizados: 'Apresenta os campos customizados, ativos e associados a entidade Ação. Caso ele faça parte do cadastro do contrato você deve <br />selecionar pelo menos uma opção do Fluxo de Trademarketing (Planejamento, Execução, Apuração e/ou Pagamento).<br />Essas opções serão apresentadas conforme opção selecionada no Passo 1 – Fluxo de Trademarketing.<br />Além disso, você pode definir qual o campo poderá ser editado quando o contrato estiver no status aprovado',
        cliente: 'Informe pelo menos uma opção para habilitar a informação de foco na criação desse tipo de contrato.<br />Isso é necessário pois todo contrato deve ter como foco um cliente específico, uma holding ou uma regional.<br />Caso necessário, informe se terá a informação de cliente pagador',
        fluxo_aprovacao: 'Para cada tipo de fluxo defina quais as obrigatoriedades parametrizadas no contrato devem ser consideradas e validadas no fluxo aprovação',
        grid_filtros: 'Definição dos campos que serão apresentados no grid e/ou filtro: <br />Restrições de grid <ol><li>Os campos com apresentação pré-definida no grid não poderão ser retirados</li></ol> Restrições de filtro<ol><li>Campos padrões: os campos textos e numéricos não poderão compor o filtro</li><li>Campos customizados: apenas os campos customizados dos tipos Lista, Múltipla escolha e Sim/Não poderão compor os filtros</li></ol>',
        liquidacao: 'Defina se é permitida a liquidação parcial. Além disso, configurar se existe ou não integração de liquidação com software terceiro',
        ordenacao: 'Ordenação dos campos apresentados no formulário de cadastro, consulta e edição do tipo de contrato.<br />Essa ordenação será considerada para apresentação dos campos no grid de listagem das ações',
        pagamento: 'Defina se é permitido o pagamento parcial. Além disso, configurar se existe ou não integração de pagamento com software terceiro',
        periodo: 'Escolha como será a definição da vigência do contrato. Se poderá ser escolhida pelo usuário ou se deverá respeitar o período de planejamento configurado na tela de Período',
        produto: '<ol><li>Para incluir alguma informação de produto no cadastro do contrato você deve selecionar pelo menos uma opção do Fluxo de Trademarketing (Planejamento, Execução, Apuração e/ou Pagamento).<br />Essas opções serão apresentadas conforme opção selecionada no Passo 1 – Fluxo de Trademarketing</li><li>Defina também se o campo é obrigatório no cadastro do contrato, ou seja, no módulo Planejamento</li></ol>',
        resumo: '<ol><li>Nome e descrição do tipo de contrato. O nome será apresentado no menu Novo da tela de Planejamento</li><li>Qualquer alteração nas configurações desse tipo de contrato será aplicada para as novas ações. Os demais status (Em cadastro, Aprovado, Aguardando aprovação, Em análise, Reprovado e Cancelado) não serão impactados</li>',
        tipo_investimento: 'Selecione as contas correntes que esse tipo de contrato poderá creditar ou debitar verba. Caso nenhuma conta corrente seja habilitada não haverá controle de verba na criação do contrato',
        tipos_acao: 'Incluir os tipos de ação que podem ser incluídos neste contrato, e a quantidade máxima de cada. <br />Flag Permitir duplicidade de ações: quando ativo, permite criar ações de contrato com a mesma vigência e configuração (ex.: atributos de produto/cliente).',
        upload: 'Configure as regras de upload de arquivo na etapa de planejamento.',
        valor: 'O template de valor calculado automaticamente "Soma do valor monetário das ações" faz a soma dos valores <br />das ações do contrato se contrato de meta de valor fixo será utilizado o maior valor cadastrado. <br />Atualiza o valor do contrato depois de aprovado quando novas ações forem incluídas e aprovadas',
        valor_pagamento: 'Defina sobre o comportamento do campo de valor de pagamento na etapa de apuração.',
        vencimento_apuracao_acao: 'Habilitar os vencimento das apurações de tipo de contrato conforme a parametrização:<ol><li>Os status da apuração aplicados para os vencimentos são "Aguardando Apuração" e "Em análise"</li><li>Data para contar o prazo de vencimento: É a data a partir da qual se contará o prazo de vencimento da ação/apuração.</li><li>Prazo em dias para o vencimento: a quantidade de dias corridos para o vencimento da apuração da ação a partir da "Data para contar o prazo de vencimento" e nos status válidos.</li><li>Tipo de verba: quando houver, poderá restringir os tipos de verba em que será aplicado o processo. Se e somente se houver tipo de verba marcado no passo 1. Se deixado em branco, será aplicado para todos os tipos de verba selecionados no tipo de contrato.</li><li>Habilitar notificação por e-mail antes do vencimento: se habilitado, ocorrerá o envio de e-mail aos usuários (funcionários e/ou clientes, de acordo com a seleção) quando estiver a X dias (campo prazo em dias para notificar) antes da data de vencimento do contrato.</li></ol>',
        vinculo: 'Para cada tipo de orçamento defina quais as informações em comuns parametrizadas no contrato e no tipo de orçamento devem ser consideradas e validadas na criação do contrato',
      },
    },
    ajuste_verba: {
      linhas_com_fluxo_andamento: 'Algumas linhas deste orçamento já possuem um fluxo de redução de verba em andamento. O saldo exibido nestas linhas está atualizado considerando essas reduções.',
    },
    aporte_automatico_com_projecao: 'Aporte automático com projeção de verba',
    aporte_automatico_sem_projecao: 'Aporte automático sem projeção de verba',
    aporte_manual_verba: 'Aporte manual de verba',
    aporte_saldo_negativo: 'Saldo insuficiente.',
    aprovacao_transferida: 'Aprovação transferida com sucesso',
    apuracao_nao_finalizada_vlr_recebimento: 'Esta apuração não será finalizada por não ter valor de recebimento.',
    aprovado_sucesso: 'Item aprovado com sucesso!',
    apuracao_cancelar_vigencia_iniciada: 'Não é possível cancelar apuração com vigência iniciada',
    apuracao_enviada_analise: 'Apuração enviada para análise.',
    apuracao_finalizada_sem_fluxo: 'Apuração aprovada automaticamente. Não há fluxo de aprovação.',
    apuracao_fluxo_cancelado: 'Fluxo da apuração cancelado.',
    apuracao_periodo_nao_alteravel_data: 'Não é permitido alterar a data fim de apurações com vigência concluída.',
    apuracao_periodo_nao_alteravel_status: 'Não é permitido alterar data fim da apuração neste status.',
    apuracao_reprovada: 'Apuração reprovada',
    apuracoes_canceladas: 'Apurações canceladas com sucesso',
    arquivo_removido: 'Arquivo removido com sucesso.',
    associar_carteira_cliente: 'Deve existir pelo menos uma carteira associada ao Cliente',
    associar_produto_fornecedor: 'Deve existir pelo menos um produto associado ao Fornecedor',
    associar_tipo_acao_contrato: 'Deve existir pelo menos um tipo de ação associado ao Contrato',
    adicionado_confira_tabela: 'Adicionado com sucesso!',
    atualizar_tabela: 'Atualizado com sucesso!',
    adicionar_meta: 'Adicione uma meta para criar um acordo',
    adicionar_negociacao: 'Adicione uma negociação para criar um meta',
    acordos_datas_mesmo_mes: 'Data inicial e final devem pertencer ao mesmo mês',
    acordos_por_salvar: 'Tem acordos sem salvar, confira na tabela',
    autocomplete_required: 'Execute a busca e selecione um(a) {entidade}',
    atualizado_com_sucesso: 'Atualizado com sucesso.',
    atualizado_confira_tabela: 'Atualizado com sucesso.',
    alteracao_massa_sucesso: 'Alteração em massa executada com sucesso.',
    alteracao_nao_salvas: 'Você irá perder as alterações não persistidas, deseja realmente sair?',
    acompanhamento_finalizado: ' não possui histórico de acompanhamento de Workflow, pois foi finalizado.',
    alterar_forma_bonificacao_redefinir_meta: 'Ao alterar o Tipo de verba você terá que redefinir a verba de todos os intervalos cadastrados. | Ao alterar o Tipo de verba você terá que redefinir a verba de todos os intervalos cadastrados. Deseja continuar?',
    acordo_alterado: 'Acordo atualizado com sucesso!',
    acordo_aprovado: 'Acordo(s) aprovado(s).',
    acordo_enviado_analise: 'Acordo(s) enviado(s) para análise.',
    acordo_possui_fluxo_apuracao_em_andamento: 'Acordo possui fluxo de aprovação de apuração em andamento. Não será possível encerrá-lo.',
    acordo_reprovado: 'Acordo(s) reprovado(s).',
    acordo_finalizado: 'Acordo finalizado com sucesso!',
    acordo_incluido: 'Acordo incluído com sucesso!',
    acordo_cancelar: 'Acordo cancelado!',
    acordo_encerrado: 'Acordo(s) encerrado(s)!',
    acordo_em_cadastro: 'Acordo em cadastro',
    acordo_nao_esta_aprovado: (desAcordo, descNegociacao) => `Acordo não está aprovado! Acordo: ${desAcordo}, Negociação: ${descNegociacao}`,
    ajuste_verba_aprovado: 'Ajuste de verba aprovado. | Ajustes de verba aprovados.',
    ajuste_verba_cancelado: 'Ajuste de verba cancelado com sucesso. | Ajustes de verba cancelados com sucesso.',
    ajuste_verba_concluido: 'Ajuste de verba concluído. | Ajustes de verba concluídos.',
    ajuste_verba_enviado_analise: 'Ajuste de verba enviado para análise. | Ajustes de verba enviados para análise.',
    ajuste_verba_finalizado: 'Ajuste de verba finalizado. | Ajustes de verba finalizados.',
    ajuste_verba_finalizado_sem_fluxo: 'Ajuste de verba finalizado sem fluxo de aprovação. | Ajustes de verba finalizados sem fluxo de aprovação.',
    ajuste_verba_orcamento: 'Esta alteração ocasionará ajuste no saldo do Orçamento.',
    ajuste_verba_reprovado: 'Ajuste de verba reprovado. | Ajustes de verba reprovados.',
    ajuste_verba_enviado_aprovacao: 'Ajuste de verba enviado para aprovação. | Ajustes de verba enviados para aprovação.',
    ano_anterior_ano_atual_igual: 'Ano fiscal anterior igual ao atual',
    ao_concluir_transferencia_operacoes_efetivadas: 'Ao concluir essa transferência, as seguintes operações serão efetivadas:',
    apos_efetivacao_transferencia_nao_desfeita: 'Após efetivação da transferência, a mesma não poderá ser desfeita.',
    apuracao_ajustada: 'Apuração ajustada.',
    apuracao_aprovada: 'Apuração aprovada.',
    apuracao_concluida: 'Apuração concluída.',
    apuracao_finalizada: 'Apuração finalizada!',
    apuracao_iniciou_fluxo: 'Apuração iniciou fluxo!',
    apuracao_passos_aprovar: 'Passo(s) apuracao aprovado(s) com sucesso!',
    apuracao_passos_enviar_analise: 'Passo(s) apuracao enviado(s) para análise com sucesso!',
    apuracao_passos_reprovar: 'Passo(s) apuracao reprovado(s) com sucesso!',
    apuracao_valor_pagamento_diferente_itens: 'O valor do pagamento {valorPagamento} é diferente do valor total dos itens {valorItens}, tem certeza que deseja continuar?',
    apuracao_pagamento_liquidado_maior_pagamento: 'Não é possível concluir a apuração pois a soma dos pagamentos liquidados {vlrPagamentoLiquidado} é maior do que o valor do pagamento {vlrPagamento}.',
    apuracao_pagamento_liquidado_diferenca_pagamento: 'A soma dos pagamentos liquidados {vlrPagamentoLiquidado} é menor que o valor do pagamento {vlrPagamento}, deseja seguir com a conclusão da apuração assim mesmo? Se sim, o valor {vlrDiferencaPagamentos} será estornado para a conta corrente.',
    apuracao_pagamento_liquidado_saldo_conta: 'O valor do pagamento {vlrPagamento} é maior que o planejado {vlrPlanejado}. Este é o saldo disponível na conta corrente {vlrSaldoConta}. O valor do pagamento {vlrPagamento} é maior que a soma dos pagamentos liquidados {vlrPagamentoLiquidado}. Deseja seguir com a conclusão da apuração mesmo assim?',
    apuracao_pagamento_liquidado_saldo_insuficiente: 'Não é possível liquidar este pagamento pois não há saldo suficiente na conta corrente {vlrSaldoDisponivel}.',
    apuracao_acao_itens_nao_confirmados: 'Não foi possível concluir/enviar para fluxo de aprovação. Itens detalhes de ação não confirmados!',
    apuracao_rateio_pagamento: 'O valor do pagamento da ação é diferente do valor da ação. Faça o ajuste do valor pendente entre as contas correntes abaixo.',
    apuracao_reivindicacao_aprovar: 'Passo(s) Reivindicação aprovada com sucesso!',
    apuracao_reivindicacao_enviar_analise: 'Reivindicação(ções) apuracao enviado(s) para análise com sucesso!',
    apuracao_reivindicacao_reprovar: 'Reivindicação(ções) reprovada(s) com sucesso!',
    apuracao_reprocessada: 'Apuração reprocessada com sucesso!',
    apuracao_resgatada: 'Apuração resgatada com sucesso!',
    apuracao_resgatada_informacao: 'Apuração resgatada por {usuario}: {justificativa}',
    arquivo_evidencia_obrigatorio: 'Não foi possível finalizar/enviar para fluxo de aprovação. Arquivo de evidência, é obrigatório!',
    arquivo_evidencia_obrigatorio_sellin: 'Upload de arquivo obrigatório. Meta sem comprovação de SellIn.',
    arqivos_execucao_inexistente: 'Não existem arquivos de execução.',
    ativado_com_sucesso: 'Ativado com Sucesso',
    atualiza_pagamento: 'Atualizar Status e/ou dados complementares do pagamento',
    alteracoes_aplicadas_orcamentos_vendedor_de: 'As alterações serão aplicadas para todos os orçamentos que o usuário {de} está associado;',
    alterar_ano_fiscal: 'Este procedimento irá alterar um Período existente conforme dados informados. Deseja continuar a operação?',
    bloqueio_efetuado_com_sucesso: 'Bloqueio efetuado com sucesso',
    bloqueio_periodo_fiscal_enviado: 'Bloqueio encaminhado com sucesso. Assim que o procedimento for finalizado, você será notificado.',
    bloqueio_sistema: 'Há um período de fechamento ativo no momento. Algumas funcionalidades estarão indisponíveis.',
    campo_obrigatorio: 'Este campo é obrigatório',
    campo_obrigatorio_ou_invalido: 'Campo obrigatório ou inválido',
    campo_formato_invalido: 'Formato do campo inválido. Exemplo válido: {exemplo}',
    campo_obrigatorio_item_especifico: 'Favor informar {nome_campo} do item {nome_item}',
    campo_obrigatorio_pessoa_juridica: 'Este campo é obrigatório para Pessoa Jurídica',
    cancelado_confira_tabela: 'Cancelado com sucesso!',
    cancelar_acao: 'Cancelar ação',
    cancelar_entrega_materiais: 'Você está ciente que ao selecionar essa opção, os materiais retornarão à "Qtd Disponível" e esta ação não receberá nenhum material.',
    cancelamento_execucao_negado_orcamento_aprovado: 'Não foi possível cancelar a execução, pois existe um orçamento aprovado para esta ação.',
    cancelamento_execucao_negado_pagamento_iniciado: 'Não foi possível cancelar a execução, pois o pagamento já foi realizado.',
    cancelamento_execucao_negado_um_item_iniciado: 'Não foi possível cancelar a execução, pois existe {item} item com pagamento concluído para esta ação. | Não foi possível cancelar a execução, pois existem {item} itens com pagamento concluído para esta ação.',
    cancelamento_execucao_negado_reporte_iniciado: 'Não foi possível cancelar a execução, pois existe um reporte cadastrado para esta ação.',
    cancelamento_modal_titulo_selecionadas: 'Cancelar as apurações selecionadas',
    cancelamento_modal_mensagem_selecionadas: 'Deseja cancelar as apurações desses contratos?',
    cancelamento_modal_titulo_tudo: 'Cancelar todas as apurações selecionadas',
    cancelamento_modal_mensagem_tudo: 'Deseja cancelar todas as apurações desses contratos?',
    cancelamento_lote: 'Ao confirmar, essa apuração NÃO poderá ser mais calculada. A justificativa é obrigatória se confirmar o cancelamento.',
    cancelar_sem_salvar: 'Tem certeza que deseja cancelar? As alterações feitas não serão salvas.',
    carteira_cliente_erro_inativar: 'Areas de atuação com vínculos. Impossível inativar.',
    carteira_cliente_responsavel_serao_ajustadas: 'A carteira de clientes e responsabilidade no faturamento serão ajustadas conforme seleção;',
    catalogo_material_download: 'O download do catálogo de materiais começará em breve!',
    catalogo_peca_download: 'O download do catálogo de peças começará em breve!',
    classificacao_invalida: 'A classificação não é válida para solicitar aprovação.',
    classificacao_nao_selecionada: 'Classificação não selecionada.',
    classificacao_nao_configurada: 'Classificação não configurada.',
    carregar_linhas: 'Carregue as linhas para poder exportar',
    cnpj_tamanho: 'O tamanho do CNPJ deve ser de 14 dígitos',
    condicao_percentual_aprovada: 'Condição percentual aprovada.',
    condicao_percentual_enviada_analise: 'Condição percentual enviada para análise.',
    confirmacao_alterar_email: 'Confirma alteração destes emails ?',
    confirmar: 'Deseja confirmar?',
    concluir_frete_materiais: 'Confirma a opção de frete escolhida?',
    concluir_aporte: 'Deseja concluir este aporte?',
    concluir_acao_sem_fluxo: 'Deseja concluir o planejamento da ação? Após concluir, a ação será aprovada automaticamente e não poderá ser editada.',
    concluida_acao_sem_fluxo: 'Ação concluida com sucesso!',
    concluir_reducao: 'Deseja concluir esta redução?',
    configuracao_produto_ja_utilizada: 'Já existe uma ação criada para esse contrato com as mesmas configurações de produto. (Ação "{descricao}")',
    configuracao_conflito: 'Configuração em conflito!',
    configuracao_cancelar: 'As configurações definidas até o momento serão perdidas, deseja cancelar mesmo assim?',
    configuracao_voltar_passo: 'As configurações não salvas deste passo serão perdidas, deseja voltar mesmo assim?',
    configuracao_salva: 'Configuração salva com sucesso!',
    configuracao_finalizada: 'Configuração finalizada com sucesso!',
    confirmacao_peca_sem_foto: 'Nenhuma foto foi carregada para esta Peça, deseja continuar?',
    confirmacao_drop_cache: 'Confirma drop cache?',
    conta_corrente_filtros_alterados: 'As alterações realizadas refletirão na seleção das contas correntes no Passo 3 e você deverá distribuir verba novamente. Deseja continuar?',
    contrato_anexacao_desabilitada: 'Só é possível inserir anexos com o DPM salvo.',
    contrato_aprovado: 'Contrato aprovado.',
    contrato_cancelado: 'Contrato cancelado com sucesso.',
    contrato_cancelar: 'Contrato cancelado!',
    contrato_encerrar: 'Contrato encerrado!',
    contrato_enviado_analise: 'Contrato enviado para análise.',
    contrato_finalizado: 'Contrato finalizado com sucesso!',
    contrato_finalizado_sem_fluxo: 'Contrato finalizado sem fluxo de aprovação',
    contrato_reprovado: 'Contrato reprovado.',
    cpf_tamanho: 'O tamanho do CPF deve ser de 11 dígitos',
    criar_ano_fiscal: 'Este procedimento irá criar um novo Período conforme dados informados. Deseja continuar a operação?',
    data_entrega_desejada: 'Data de entrega desejada inválida.',
    data_inicial_invalida: 'Data inicial inválida. Verifique o formato.',
    data_inicio_invalida: 'Data ou hora inicial inválida. Verifique o formato.',
    data_invalida: 'Data inválida. Verifique o formato.',
    data_intervalo_invalido_ano: 'Intervalo de datas não pode ser superior a 12 meses',
    data_intervalo_invalido_cinco_anos: 'Intervalo de datas não pode ser superior a 5 anos',
    data_intervalo_invalido_dez_anos: 'Intervalo de datas não pode ser superior a 10 anos',
    data_intervalo_invalido_meses: 'Intervalo de datas não pode ser superior a {qtdMeses} meses',
    data_fim_invalida: 'Data ou hora final inválida. Verifique o formato.',
    data_final_invalida: 'Data final inválida. Verifique o formato.',
    data_final_antes_inicial: 'Data inicial maior ou igual que data final.',
    data_inicial_maior_final: 'Data Inicial maior que a Data Final.',
    data_inicial_menor_dia_seguinte: 'Data Inicial menor que o Dia Seguinte.',
    data_final_e_inicial: 'Data inicial e final devem ser informadas.',
    data_prorrogacao_invalida: 'A data fim da prorrogação deve ser posterior à data fim atual.',
    definir_todas_data_contabilizacao: 'Define a data de contabilização para todas as notas de débito selecionadas.',
    desbloqueio_efetuado_com_sucesso: 'Desbloqueio efetuado com sucesso',
    desbloqueio_periodo_fiscal_enviado: 'Desbloqueio encaminhado com sucesso. Assim que o procedimento for finalizado, você será notificado.',
    deseja_adicionar_acao: 'Deseja adicionar esta ação?',
    deseja_adicionar_carrinho: 'Deseja adicionar esta peça ao carrinho?',
    deseja_aplicar_data_contabilizacao: 'Deseja aplicar esta data para os itens selecionados?',
    deseja_ajustar_acordo: 'Deseja ajustar o Acordo?',
    deseja_ativar_inativar: 'Deseja ativar/inativar o registro?',
    deseja_aprovar: 'Deseja aprovar o(s) item(ns)?',
    deseja_aprovar_ajuste_verba: 'Deseja aprovar o(s) ajustes(s) de verba?',
    deseja_aprovar_contrato: 'Deseja aprovar contrato?',
    deseja_aprovar_item: 'Deseja aprovar o item?',
    deseja_aprovar_layout: 'Deseja aprovar o layout?',
    deseja_aprovar_planejamento: 'Deseja aprovar planejamento?',
    deseja_aprovar_orcamento: 'Deseja aprovar o(s) orçamento(s)?',
    deseja_aprovar_transferencia: 'Deseja aprovar a(s) transferência(s)?',
    deseja_aprovar_servico: 'Deseja aprovar o serviço?',
    deseja_cancelar_acao: 'Deseja cancelar essa ação?',
    deseja_cancelar_acao_aprovada: 'Deseja cancelar a essa ação? A apuração dela está no status {status}. Ao confirmar, essa ação não poderá ser mais apurada. A justificativa é obrigatória se confirmar o cancelamento. | Existem apurações que ainda podem ser calculadas. Ao confirmar, essa ação não poderá ser mais apurada. A justificativa é obrigatória se confirmar o cancelamento.',
    deseja_cancelar_acao_contrato: 'Deseja cancelar a ação de {nomeAcaoTipo} desse contrato? <br/> Ao confirmar o cancelamento, haverá impacto nas apurações de {nomeAcaoAgrupadora} listados abaixo. Tem certeza que deseja continuar? </br> A justificativa é obrigatória se confirmar o cancelamento.',
    deseja_cancelar_acordo: 'Deseja cancelar o(s) acordo(s)?',
    deseja_cancelar_ajuste_verba: 'Deseja cancelar o ajuste de verba? | Deseja cancelar os ajustes de verba?',
    deseja_cancelar_apuracao: 'Deseja cancelar a apuração?',
    deseja_cancelar_apuracao_justificativa: 'Deseja cancelar a apuração dessa ação?<br/> Ao confirmar, essa apuração NÃO poderá ser mais calculada. A justificativa é obrigatória se confirmar o cancelamento.',
    deseja_cancelar_contrato: 'Deseja cancelar esse contrato?',
    deseja_cancelar_edicao: 'Deseja cancelar a edição?',
    deseja_cancelar_evento: 'Deseja cancelar este evento?',
    deseja_cancelar_execucao: 'Deseja cancelar a execução?',
    deseja_cancelar_pagamento: 'Deseja cancelar o pagamentos?',
    deseja_cancelar_pagamento_acao: 'Deseja cancelar este item da ação?',
    deseja_cancelar_planejamento: 'Deseja cancelar o planejamento?',
    deseja_cancelar_orcamento: 'Deseja cancelar o(s) orçamento(s)?',
    deseja_cancelar_recebimento: 'Deseja cancelar o recebimento?',
    deseja_cancelar_transferencia: 'Deseja cancelar a transferência?',
    deseja_concluir: 'Deseja concluir este(a) {text}?',
    deseja_concluir_apuracao: 'Deseja concluir essa apuração? </br> Após conclusão, será criado o recebimento e a apuração não poderá ser mais alterada',
    deseja_concluir_apuracao_simples: 'Deseja concluir a apuração?',
    deseja_concluir_materiais: 'Deseja concluir estes materiais?',
    deseja_concluir_edicao: 'Deseja concluir a edição deste(a) {text}?',
    deseja_concluir_edicoes: 'Deseja concluir a edição destes(as) {text}?',
    deseja_concluir_planejamento: 'Deseja concluir este planejamento?',
    deseja_confirmar_conclusao: 'Deseja confirmar conclusão?',
    deseja_continuar: 'Deseja continuar?',
    deseja_editar_item: 'Deseja editar o item?',
    deseja_desfazer_solicitacao_reembolso: 'Deseja desfazer a solicitação de reembolso?',
    deseja_encerrar_acordo: 'Deseja encerrar o(s) acordo(s)?',
    deseja_enviar_analise: 'Deseja enviar para análise?',
    deseja_enviar_analise_planejamento: 'Deseja enviar para análise o planejamento?',
    deseja_enviar_aprovacao: 'Deseja enviar para aprovação',
    deseja_enviar_aprovacao_orcamento: 'Deseja enviar o orçamento para aprovação?',
    deseja_enviar_aprovacao_layout: 'Deseja enviar o layout para aprovação?',
    deseja_excluir: 'Deseja excluir {text}?',
    deseja_excluir_indicador: 'Em caso de exclusão, o indicador não será utilizado na composição de pontos da Classificação. Deseja continuar?',
    deseja_excluir_configuracao_classificacao: 'Deseja excluir a configuração da classificação?',
    deseja_executar_analise: 'Deseja executar a análise?',
    deseja_executar_analise_acao: 'Deseja executar a análise desta ação?',
    deseja_executar_analise_condicao_percentual: 'Deseja executar a análise desta condição percentual?',
    deseja_executar_analise_contrato: 'Deseja executar a análise deste contrato?',
    deseja_executar_analise_planejamento: 'Deseja executar a análise do planejamento?',
    deseja_exportar: 'Deseja realizar exportação?',
    deseja_finalizar_orcamento: 'Deseja finalizar o orçamento?',
    deseja_finalizar_contrato: 'Deseja finalizar o contrato?',
    deseja_gerar_grade: 'Deseja gerar grade?',
    deseja_importar: 'Deseja importar?',
    deseja_importar_novo: 'Deseja importar novo',
    deseja_imprimir: 'Deseja realizar a impressão?',
    deseja_liquidar_pagamento: 'Deseja liquidar o pagamento? Caso confirme, o pagamento será criado e não poderá mais ser editado',
    deseja_ativar_inativar_registros: 'Deseja ativar/inativar os {quantidade} registro(s) selecionado(s)?',
    deseja_realizar_alteracao: 'Deseja realizar a alteração?',
    deseja_redefinir_senha: 'Deseja redefinir senha?',
    deseja_reenviar_pagamento: 'Deseja reenviar o pagamento?',
    deseja_reenviar_recebimento: 'Deseja reenviar o recebimento?',
    deseja_remover_estrutura_pai: 'Deseja remover a estrutura pai?',
    deseja_remover_material: 'Deseja remover este material?',
    deseja_remover_meta: 'Deseja remover a meta?',
    deseja_remover_peca: 'Deseja remover a peça?',
    deseja_remover_todas_peca: 'Deseja remover todas as peças?',
    deseja_remover_servico: 'Deseja remover o serviço da ação?',
    deseja_reprovar: 'Deseja reprovar o(s) item(ns)?',
    deseja_reprovar_ajuste_verba: 'Deseja reprovar o(s) ajustes(s) de verba?',
    deseja_reprovar_item: 'Deseja reprovar o item?',
    deseja_reprovar_planejamento: 'Deseja reprovar planejamento?',
    deseja_reprovar_layout: 'Deseja reprovar o layout?',
    deseja_reprovar_orcamento: 'Deseja reprovar o(s) orçamento(s)?',
    deseja_reprovar_execucao_orcamento: 'Deseja reprovar o orçamento?</br>Esta ação é irreversível e irá cancelar a execução da ação e a ação no planejamento.',
    deseja_reprovar_transferencia: 'Deseja reprovar a(s) transferência(s)?',
    deseja_resgatar_apuracao: 'Deseja resgatar a apuração?',
    deseja_solicitar_aprovacao_acordo: 'Deseja solicitar aprovação do acordo?',
    deseja_solicitar_aprovacao_acordo_sem_salvar: 'Você irá perder as alterações não persistidas, deseja realmente solicitar aprovação do acordo?',
    deseja_solicitar_aprovacao_ajuste_verba: 'Deseja solicitar aprovação do ajuste de verba?',
    deseja_solicitar_aprovacao_apuracao: 'Deseja solicitar aprovação da apuração?',
    deseja_solicitar_aprovacao_condicao_percentual: 'Deseja solicitar aprovação da condição percentual?',
    deseja_solicitar_aprovacao_planejamento: 'Deseja solicitar aprovação do planejamento?',
    deseja_solicitar_aprovacao_orcamento: 'Deseja solicitar aprovação do orçamento?',
    deseja_solicitar_reembolso: 'Deseja solicitar reembolso?',
    deseja_salvar: 'Deseja salvar este(a) {text}?',
    deseja_salvar_acao: 'Deseja salvar esta ação?',
    deseja_salvar_edicao: 'Deseja salvar a edição deste(a) {text}?',
    deseja_salvar_edicoes: 'Deseja salvar a edição destes(as) {text}?',
    deseja_salvar_informacoes: 'Deseja salvar estes(as) {text}?',
    deseja_salvar_material: 'Deseja salvar este material?',
    deseja_salvar_materiais: 'Deseja salvar estes materiais?',
    deseja_salvar_pagamento: 'Deseja salvar este pagamento?',
    deseja_salvar_pagamento_enviar_aprovacao: 'Deseja salvar este pagamento e enviar para aprovação?',
    deseja_salvar_peca: 'Deseja salvar esta peça?',
    deseja_salvar_pedido: 'Deseja salvar?',
    deseja_salvar_planejamento: 'Deseja salvar este planejamento?',
    deseja_salvar_reporte: 'Deseja salvar este reporte?',
    deseja_salvar_taxas_alteradas: 'Houve incidência de taxas sobre o valor das Ações abaixo. Deseja prosseguir?',
    deseja_seguir_proxima_apuracao: 'Deseja seguir para a próxima apuração?',
    deseja_voltar: 'Deseja voltar?',
    deseja_voltar_pagamento: 'Deseja voltar para lista de pagamentos?',
    diferente_valor_liquido: 'Soma dos valores informados diferente do valor líquido',
    digite_para_pesquisar: 'Digite parte do texto para pesquisar',
    visualizar_valor_dividido_por_extensao: 'Clique aqui para visualizar o valor dividido por OI',
    visualizar_notas_clique_aqui: 'Clique aqui para visualizar as notas',
    divisao_preenchida: 'Confirma a inclusão de um Funcionário sem Divisão informada?',
    download_arquivo_expirado: 'Link invalido ou arquivo expirado',
    download_efetuado: 'Download efetuado!',
    download_pedido_pecas: 'É de total responsabilidade do usuário a utilização deste arquivo respeitando <br/>as especificações técnicas para não haver distorção de cor e tamanho.<br/>É proibida a edição deste material, como por exemplo<br/> alterações no logotipo (tamanho/localização), fontes, texto legal, etc.<br/> Ao produzir o material, você é responsável por garantir a qualidade<br/> e utilização do mesmo de acordo com as diretrizes {placeholder}.',
    edicao_agendamento: '**Editar essas informações não altera a data da próxima execução',
    encerramento_acordo: 'Com este encerramento o saldo do Representante será restituido em ',
    email_relatorio_saldo_solicitado: 'Relatório solicitado com sucesso, o relatório será enviado para o seu e-mail.',
    enviado_aprovacao_sucesso: 'Item enviado para aprovação!',
    enviado_analise_sucesso: 'Item enviado para análise!',
    enviar_ajuste: 'Deseja enviar o acordo para ajuste',
    enviado_analise: 'Item enviado para análise.',
    estrutura_usuario_definicao_obrigatoria: 'Selecione pelo menos uma das duas opções: "Filtro da visão", "Carteira" ou "Estrutura de usuário"',
    erro_buscar_importacoes: 'Erro ao buscar importações',
    erro_front_planejamentoacao: 'Erro inesperado ao salvar a ação. Você será encaminhado(a) para a tela de listagem. Favor verificar se a ação foi salva.',
    erro_buscar_tipos_importacao: 'Erro ao buscar tipos de importação',
    erro_salvar_centro_sem_produto_cliente: 'O centro de custo deve ter selecionado pelo uma das opções Cliente/Produto.',
    erro_salvar_peca_falta_arquivos: 'É necessário o upload de pelo menos um arquivo.',
    erro_estrutura_pai_inexistente: 'Erro! Estrutura pai inexistente',
    erro_tente_importar_novamente: 'Erro! Tente importar novamente',
    evidencia_removida: 'Evidência removida com sucesso.',
    data_nascimento_invalida: 'Data de nascimento inválida.',
    excluido_sucesso: '{param} excluido com sucesso.',
    execucao_acao_incluida: 'Execução incluída com sucesso.',
    execucao_acao_concluida: 'Execução concluída com sucesso.',
    execucao_acao_alterado: 'Execução atualizada com sucesso!',
    execucao_cancelada: 'Execução cancelada com sucesso!',
    execucao_metas: 'Execução metas efetuado com sucesso.',
    execucao_layout: 'Evidência de layout enviada com sucesso.',
    execucao_orcamento: 'Evidência de orçamento enviada com sucesso.',
    execucao_removida: 'Execução removida com sucesso.',
    existem_arquivos_anexados_para_execucao: 'Existe um pagamento iniciado para esta execução, deseja continuar?',
    exportacao_assincrona: {
      em_processamento: 'Sua requisição já está sendo processada, em breve o link para o relatório será enviado por email.',
      ja_existe: 'Já existe uma exportação em andamento.',
    },
    exportacao_download: 'Exportação em andamento. Aguarde enquanto o arquivo é gerado.',
    fechamento_ano_fiscal: 'Esta operação fechará o atual período. Este procedimento é irreversível, deseja continuar com a alteração?',
    fechamento_ano_fiscal_enviado: 'O período foi encaminhado para fechamento. Assim que o procedimento for finalizado, você será notificado.',
    fechamento_periodo_fiscal_enviado: 'Fechamento encaminhado com sucesso. Assim que o procedimento for finalizado, você será notificado.',
    fechamento_periodo_fiscal: 'Esta operação fechará o atual período. Este procedimento é irreversível, deseja continuar com a alteração?',
    finalizar_acordo: 'Deseja finalizar este Acordo?',
    finalizar_alteracao_nao_salvas: 'Não é possível finalizar, você tem alterações não persistidas!',
    finalizar_apuracao: 'Deseja concluir essa apuração?<br />Após conclusão, será criado o recebimento e a apuração não poderá ser mais alterada.',
    finalizar_apuracao_industria: 'Deseja concluir essa apuração?<br />Após conclusão, será criado o aporte/pagamento e a apuração não poderá ser mais alterada.',
    finalizar_apuracao_lote: 'Ao confirmar a finalização das apurações, a apuração será aprovada e o recebimento gerado. Não será possível realizar alterações. Deseja continuar?',
    finalizar_apuracoes_lote_aportar: 'Ao confirmar a finalização das apurações, o aporte será gerado, esta ação é irreversível. Deseja continuar?',
    finalizar_apuracoes_lote_aportar_pagamento: 'Ao confirmar a finalização das apurações, o aporte/pagamento será gerado, esta ação é irreversível. Deseja continuar?',
    finalizar_apuracoes_lote_pagamento: 'Ao confirmar a finalização das apurações, o pagamento será gerado, esta ação é irreversível. Deseja continuar?',
    finalizar_apuracao_lote_sem_valor: 'Apuração não possui valor de aporte/pagamento. Deseja  prosseguir?<br />Ao confirmar, esta ação é irreversível',
    finalizar_apuracao_lote_aportar: 'Ao confirmar a finalização da apuração, o aporte será gerado, esta ação é irreversível. Deseja continuar?',
    finalizar_apuracao_lote_pagamento: 'Ao confirmar a finalização da apuração, o pagamento será gerado, esta ação é irreversível. Deseja continuar?',
    finalizar_apuracao_sem_recebimento: 'Algumas apurações não serão finalizadas, pois, não possuem valor de recebimento.',
    finalizar_apuracao_sem_pagamento: 'Algumas apurações não possuem valor de aporte/pagamento. Deseja  prosseguir?',
    finalizar_apuracao_todas_sem_pagamento: 'Apurações não possuem valor de aporte/pagamento.',
    finalizar_apuracao_alterar_status_aprovado: 'Deseja alterar o status da apuração para APROVADO?<br />Este processo irá finalizar a apuração e ela não será mais calculada pelo sistema. | Deseja alterar o status das apurações que possuem valor a aportar igual ao valor apurado para APROVADO?<br />Este processo irá finalizar a apuração e ela não será mais calculada pelo sistema.',
    fluxo_redirecionado_sucesso: 'O redirecionamento de fluxo foi realizado com sucesso',
    fluxo_cancelado: 'Fluxo de aprovação cancelado com sucesso!',
    fluxo_cancelado_sucesso: 'O fluxo de aprovação foi cancelado com sucesso.',
    habilitar_alteracao_valor_pagamento: 'Habilitar alteração do valor do pagamento',
    habilitar_calculo_automatico_investimento_vendas: 'Habilitar cálculo automático de investimento sobre vendas',
    habilitar_coeficiente_calculo: 'Ative a flag Habilitar coeficiente de cálculo, se for necessário preenchimento de um valor (numérico) no cadastro do contrato (passo 1 - Campos Gerais) que irá compor a regra de cálculo da apuração',
    habilitar_edicao_pagamento_realizado: 'Habilitar alteração do valor realizado calculado automaticamente',
    habilitar_integracao_envio_pagamento: 'Habilitar integração para envio de pagamento',
    habilitar_integracao_retorno_pagamento: 'Habilitar integração para retorno de pagamento',
    habilitar_integracao_envio_recebimento: 'Habilitar integração para envio de recebimento',
    habilitar_integracao_retorno_recebimento: 'Habilitar integração para retorno de recebimento',
    habilitar_justificativa_alterar_valor: 'Habilitar justificativa ao alterar valor do pagamento',
    habilitar_liquidacao_parcial: 'Habilitar liquidação parcial',
    habilitar_pagamento_parcial: 'Habilitar pagamento parcial',
    historico_orcamento_investimento_faturamento_serao_alterados: 'O histórico de valores de orçamento, investimento e faturamento apresentados nos relatórios gerenciais serão alterados após transferência.',
    importado_em_breve_sera_processado: 'Importado! Em breve o arquivo será processado e estará disponível na tabela',
    inativado_com_sucesso: 'Inativado com Sucesso',
    InativarDivisao: 'Não é possivel inativar. Divisão com vinculos.',
    InativarCarteiraCliente: 'Não é possivel inativar. Carteira de Cliente com vinculos.',
    InativarEstruturaUsuario: 'Não é possivel inativar. Estrutura do usuário com vinculos.',
    inconsistencia_continua_pendente: 'A inconsistência continua pendente',
    inconsistencia_integrada_sucesso: 'A inconsistência foi integrada com sucesso',
    inconsistencia_reprocessar: 'Deseja reprocessar esta inconsistência?',
    inconsistencia_recusar: 'Deseja recusar esta inconsistência?',
    indicador_nao_configurado: 'Indicador não configurado.',
    informe_periodo_historico_meses_anteriores: 'Informe o período histórico de meses anteriores ao período de apuração da ação:',
    iniciado_fluxo: 'Iniciado fluxo de aprovação',
    iniciar_periodo: 'Deseja iniciar o Período?',
    insira_conteudo: 'Insira um conteúdo.',
    intervalo_meta_obrigatorio: 'Intervalo da meta é obrigatório',
    intervalo_numerico: 'Número deve estar entre o intervalo: {min} e {max}',
    intervalo_numerico_percentual: 'Percentual deve estar entre o intervalo: {min} e {max}',
    item_cancelado_com_sucesso: 'Item cancelado com sucesso',
    item_enviado_alteracao: 'Item enviado para alteração.',
    justificado: 'Justificado e enviado com sucesso.',
    justificativa_apuracao: 'Apuração sem justificativa.',
    justificativa_cancelamento_acao: 'Justificativa do cancelamento feito por {usuario}: {justificativa}',
    justificativa_cancelamento_acao_data: 'Justificativa do cancelamento feito em {data} {hora} por {usuario}: {justificativa}',
    justificativa_reivindicacao: 'Reivindicação sem justificativa.',
    lgpd: 'Caro usuário, devido à Lei Geral de Proteção dos Dados (13.709/2018), os dados informados nesta página serão tratados automaticamente. Aguarde a atualização desta página para obter os novos dados.',
    lgpd_importacao: 'Caro usuário, devido à Lei Geral de Proteção dos Dados (13.709/2018), os dados importados nesta página e que identifiquem uma pessoa física serão tratados automaticamente. Após a conclusão da importação, confira os novos dados em suas respectivas telas de cadastro.',
    lider_conta_obrigatorio: 'Selecione pelo menos um opção como líder de conta',
    limite_caracteres_excedido: 'Limite de caracteres excedido!',
    lojas_contrato_incluidas: 'Lojas do contrato incluídas',
    lojas_contrato_ja_incluidas: 'Loja já está incluída no contrato',
    lojas_contrato_removida: 'Loja removida do contrato',
    lote_continua_pendente: 'Ainda existem inconsistências para o lote',
    lote_integrado_sucesso: 'O lote foi integrada com sucesso',
    lote_reprocessado_possiveis: 'Reprocessado todos lotes possíveis',
    lote_reprocessar: 'Deseja reprocessar este lote?',
    lote_reprocessar_possiveis: 'Deseja tentar reprocessar todos lotes possíveis?',
    lote_recusar: 'Deseja recusar este lote?',
    materiais_alterados: 'Materiais atualizados com sucesso!',
    materiais_frete_pendente: 'Você incluiu materiais mas não escolheu o tipo de frete. Cancele o pedido ou escolha o tipo de frete para concluir a Execução.',
    materiais_pendentes: 'Você não incluiu nenhum Material à Ação, deseja prosseguir?',
    mes_importacao_obrigatorio: 'Mês de importação obrigatório',
    meses_verba_alocada: 'Somente meses que possuem verba alocada.',
    meta_quantidade_evidencia_excedida: 'Excedeu a quantidade máxima de evidências permitidas.',
    minimo_maior_maximo: 'Valor mínimo maior que valor máximo.',
    nao: 'Não',
    nao_ano_fiscal_vigente: 'Não foi encontrado ano fiscal vigente.',
    nao_ha_conteudo: 'Não há conteúdo para ser mostrado nesta tabela.',
    nao_existe_erro_importacao: 'Não ocorreram erros nessa importação',
    nao_permite_finalizar_apuracao: 'Apuração não pode ser finalizada',
    nao_possivel_concluir_pagamento_ano_fiscal_fechado: 'Não é possível concluir este Pagamento. Esta ação pertence a um Período já encerrado.',
    nao_tem_cliente_aprovador: 'O cliente não participará do fluxo de aprovação deste acordo. É necessário que seja realizado o upload do contrato assinado após a aprovação final da negociação.',
    nao_tem_fornecedor_aprovador: 'O fornecedor não participará do fluxo de aprovação deste acordo. É necessário que seja realizado o upload do contrato assinado após a aprovação final da negociação.',
    necessario_parametrizacao_tipo_orcamento: 'Não foi encontrado nenhum tipo de orçamento parametrizado. É necessário a parametrização prévia de pelo menos um tipo de orçamento para que se possa cadastrar períodos.',
    necessario_uma_condicao: 'É necessário cadastrar ao menos uma condição percentual.',
    nenhum_arquivo_anexado: 'Nenhum arquivo anexado',
    nenhum_cliente_adicionado: 'Nenhum cliente adicionado',
    nenhum_material_adicionado: 'Nenhum material foi adicionado. Adicione uma quantidade ao material desejado para continuar.',
    nehum_pagamento_selecionado: 'Nenhum pagamento selecionado.',
    nenhum_registro_encontrado: 'Nenhum registro encontrado pelos filtros informados',
    nenhum_registro: 'Nenhum registro encontrado',
    nenhum_registro_pendente_aprovacao: 'Nenhum registro pendente de aprovação',
    nenhum_valor_informado: 'Nenhum valor informado.',
    numero_invalido: 'Número inválido',
    nenhum_cliente_para_transferir: 'Não há clientes para serem transferidos.',
    nenhum_cliente_selecionado_para_transferir: 'Nenhum cliente foi selecionado para ser transferido.',
    nenhum_intervalo: 'Nenhum intervalo adicionado.',
    nenhuma_carteira_para_transferir: 'Não há carteiras para serem transferidas.',
    nenhuma_carteira_selecionada_para_transferir: 'Nenhuma carteira foi selecionada para ser transferida.',
    nenhum_orcamento_selecionado_para_transferir: 'Nenhum orçamento foi selecionado para ser transferido.',
    novo_superior_imediato_de_sera: 'O novo superior imediato do usuário {de} será {para};',
    operacao_irreversivel_deseja_continuar: 'Essa operação é irreversível. Deseja continuar?',
    orcamento_aprovado: 'Orçamento(s) aprovado(s).',
    orcamento_cancelado: 'Orçamento(s) cancelado(s).',
    orcamento_enviado_analise: 'Orçamento retornado para análise.',
    orcamento_enviado_aprovacao: 'Orçamento enviado para aprovação.',
    orcamento_concluido: 'Orçamento concluido.',
    orcamento_finalizado: 'Orçamento finalizado.',
    orcamento_finalizado_sem_fluxo: 'Orçamento finalizado sem fluxo de aprovação',
    orcamento_habilitado_alteracao: 'Orçamento habilitado para alteração.',
    orcamento_massivo_confirmar_solicitacao: 'Deseja solicitar aprovação do orçamento?',
    orcamento_reprovado: 'Orçamento(s) reprovado(s).',
    orcamento_valor_finalizar: 'Valor remanescente não pode ser igual ao valor planejado para finalizar.',
    pagamento_criado: 'Pagamento criado.',
    pagamento_salvo_com_sucesso: 'Pagamento salvo com sucesso',
    pagamento_atualizado_com_sucesso: 'Pagamento atualizado com sucesso',
    pagamento_cancelado: 'Pagamento cancelado com sucesso.',
    pagamento_concluido: 'Pagamento concluído com sucesso',
    pagamento_reenviado: 'Pagamento(s) reenviado(s).',
    pagamento_resgatado: 'Pagamento resgatado com sucesso.',
    pagina_inexistente: 'Ops... Essa página não existe.',
    pedido_peca_finalizado: 'Pedido de peças finalizado com sucesso.',
    permitir_cliente_criar_acao: 'Permitir que o cliente crie esse tipo de ação',
    periodo_adicionado_planilha: 'Periodo atualizado na planilha',
    periodo_anterior_periodo_atual_igual: 'Período fiscal anterior igual ao atual',
    periodo_acao_duplicado: 'Já existe uma ação criada para esse contrato com o mesmo tipo e período. (Ação "{descricao}")',
    periodo_iniciado_sucesso: 'Período iniciado com sucesso',
    periodo_fechado_sucesso: 'Período fechado com sucesso',
    periodo_salvo_com_sucesso: 'Período salvo com sucesso',
    periodo_prorrogado: 'Período prorrogado com sucesso.',
    pis_tamanho: 'O tamanho do PIS deve ser de 11 dígitos',
    planejamento_aprovado: 'Planejamento aprovado.',
    planejamento_atualizado: 'Planejamento atualizado.',
    planejamento_cancelado: 'Planejamento cancelado.',
    planejamento_concluido: 'Planejamento concluído.',
    planejamento_enviado_analise: 'Planejamento enviado para análise.',
    planejamento_enviado_aprovacao: 'Planejamento enviado para aprovação.',
    planejamento_reprovado: 'Planejamento reprovado.',
    planilha_enviada_email: 'Planilha enviada por email',
    ponto_virgula_invalido: 'Caracter ; inválido.',
    preco_negociado_maior_zero: 'Preço Negociado deve ser maior que R$0,00.',
    processando_valores_em_conta: 'Ajuste de verba em processamento',
    processado_valores_em_conta: 'Ajuste de verba processado',
    processamento_pendente: 'Ajuste de verba aguardando processamento',
    projeto_fachadas_download: 'O download do arquivo de Projeto Fachadas começará em breve!',
    produto_alterado_com_sucesso: 'Produto alterado com sucesso',
    produto_salvo_com_sucesso: 'Produto salvo com sucesso',
    prg_marketing: 'Existem Distribuidores/Clientes não liberados para utilização na <b>Modalidade 4</b>, por este motivo não estão listados. Para realizar uma Ação para este Distribuidor/Cliente desejado será necessário escolher outra Modalidade. Clique em Cancelar e reinicie o cadastro da Ação',
    quantidade_material: 'Este valor pode ser alterado apenas para um valor menor que o já solicitado.',
    rascunho_salvo: 'Rascunho salvo com sucesso!',
    rateio_atualizado: 'Rateio de orçamento atualizado',
    rateio_novas_divisoes: 'Existem novas divisões que não estão na estrutura do rateio. Clique para visualizar as novas divisões e incluí-las no rateio.',
    rateio_novos_vendedores: 'Existem novos vendedores que não estão na estrutura do rateio. <br/>Clique para visualizar e incluí-los.',
    rebate_nao_configurado: 'Rebate não configurado',
    recalculo_valor_final: 'Recalculo de valor de pagamento mediante alteração do realizado da meta',
    redirecionando_para_home: 'Você será redirecionado para a página inicial.',
    reivindicacao_efetuada: 'Reivindicação efetuada com sucesso.',
    reivindicacao_em_andamento: 'Não foi possível finalizar/enviar para fluxo de aprovação. Meta possui reivindicação em andamento!',
    reivindicacao_fluxo: 'Fluxo da reivindicação iniciado.',
    remover_arquivo: 'Deseja remover o arquivo?',
    remover_arquivo_nome: 'Deseja remover o arquivo {fileName}? Este procedimento é irreversível.',
    remover_acao: 'Deseja remover a ação?',
    remover_execucao: 'Deseja remover a execução selecionada?',
    remover_foto: 'Deseja remover a foto?',
    remover_meta: 'Deseja remover a(s) meta(s) selecionada(s)?',
    remover_transferencia_posse: 'Deseja remover a transferência de posse selecionada?',
    reprovado_sucesso: 'Item reprovado com sucesso!',
    reporte_sem_lista_ou_foto: 'É necessário uma foto ou uma lista de presença.',
    resgate_sucesso: 'Resgate realizado com sucesso!',
    roi: {
      margem_sem_valor: 'Não foi possível calcular o ROI pois não há margem cadastrada para os campos informados.',
      margem_valor_negativo: 'A margem utilizada para o cálculo do ROI é negativa.',
      margem_valor_baixo: 'A margem deste produto foi calculada utilizando um histórico de volume muito baixo ou zero. Por favor, revisar a seleção de grupo de produtos para cálculo do ROI.',
      nao_calculado: 'Não foi possível enviar a ação para aprovação pois está pendente o cálculo do ROI.',
      valores_alterados_e_nao_recalculado: 'Os dados para cálculo do ROI foram alterados e não houve atualização do cálculo. Por favor, atualize o cálculo do ROI para prosseguir.',
    },
    saldo_total_consumido: 'Você está consumindo 100% do seu saldo disponível',
    saldo_disponivel_consumido: 'Você já consumiu todo o seu saldo disponível',
    saldo_indisponivel: 'Operação não efetudada. Saldo indisponível.',
    saldo_insuficiente: 'Saldo insuficiente.',
    saldo_insuficiente_aumentar_consumo: 'Saldo insuficiente para pagamento maior que o budget previsto',
    saldo_insuficiente_conta: 'Não foi possível modificar esta apuração/comprovação. Não há saldo disponível suficiente na conta corrente de {periodo} para o valor do pagamento definido. Saldo disponível {saldo}.',
    saldo_menor_vlr_aportado: 'Saldo insuficiente.',
    salvar: 'Deseja salvar?',
    salvar_aporte: 'Deseja salvar este aporte?',
    salvar_novo_acordo: 'Deseja salvar este novo Acordo?',
    salvar_acordo_valor_divergente: 'A somatória do valor das metas está diferente do valor do acordo. Deseja salvar?',
    salvar_ano_fiscal: 'Este procedimento é irreversível, deseja continuar com a alteração?',
    salvar_contrato_antes_solicitar_aprovacao: 'É necessário salvar o contrato antes de iniciar o fluxo!',
    salvar_nova_alcada: 'Deseja salvar esta nova Alçada?',
    salvar_nova_area_atuacao: 'Deseja salvar esta área de atuação?',
    salvar_nova_categoria: 'Deseja salvar esta nova Categoria?',
    salvar_novo_centro_custo: 'Deseja salvar o Centro de Custo?',
    salvar_nova_divisao: 'Deseja salvar esta divisão?',
    salvar_nova_estrutura_usuario: 'Deseja salvar esta nova Estrutura de Usuário?',
    salvar_nova_filial: 'Deseja salvar esta nova filial?',
    salvar_novo_grupo_produto: 'Deseja salvar este novo Grupo de produto?',
    salvar_nova_loja: 'Deseja salvar esta nova Loja?',
    salvar_nova_marca: 'Deseja salvar esta nova Marca?',
    salvar_nova_negociacao: 'Deseja salvar esta nova negociação?',
    salvar_nova_unidade_negocio: 'Deseja salvar esta nova unidade de negócio?',
    salvar_nova_unidade_medida: 'Deseja salvar esta nova unidade de medida?',
    salvar_nova_regiao: 'Deseja salvar esta nova Região?',
    salvar_novo_ajuste_verba: 'Deseja salvar este ajuste de verba?',
    salvar_novo_transferencia: 'Deseja salvar esta Transferência?',
    salvar_novo_canal: 'Deseja salvar este novo Canal?',
    salvar_nova_cliente: 'Deseja salvar este novo Cliente?',
    salvar_novo_concorrente: 'Deseja salvar este novo concorrente?',
    salvar_novo_registro: 'Deseja salvar novo registro?',
    salvar_novo_orcamento: 'Deseja salvar este novo orçamento?',
    salvar_novo_produto: 'Deseja salvar este novo produto?',
    salvar_novo_segmento: 'Deseja salvar este novo segmento?',
    salvar_novo_tipo_acao: 'Deseja salvar este novo tipo ação?',
    salvar_novo_tipo_investimento: 'Deseja salvar este novo tipo de Linha de Orçamento?',
    salvar_novo_tipoOrcamento: 'Deseja salvar este novo tipo de orçamento?',
    salvar_orcamento: 'Deseja salvar este orçamento?',
    salvar_usuario: 'Deseja salvar este usuário?',
    selecionar_role: 'É necessário escolher uma permissão',
    selecione_foco_acao: 'Selecione foco da ação para prosseguir.',
    selecione_divisao: 'Selecione divisão para prosseguir.',
    selecione_origem_acao: 'Selecione origem da ação para prosseguir.',
    selecione_tipo_acao: 'Selecione o tipo de ação',
    selecione_tipo_acao_para_pesquisar: 'Selecione um tipo de ação para pesquisar',
    selecione_tipo_acao_para_contrato: 'Selecione o tipo de ação para adicionar ao contrato',
    selecione_tipo_contrato: 'Selecione o tipo de contrato',
    selecione_tipo_investimento: 'Selecione tipo de investimento',
    selecionar_tipo_liquidacao: 'Selecione pelo menos uma das duas opções: "Unica liquidação", "Múltiplas liquidações"',
    selecione_uma_acao: 'Selecione pelo menos 1 Ação',
    selecione_divisao_configuracao_orcamento: 'Selecione pelo menos 1 divisão no Passo 2 -> Cadastro de orçamento” para garantir que esse rateio seja selecionado.',
    selecione_vendedores_transferir: 'Selecione todos os vendedores para transferir.',
    sem_dados: 'Sem dados para mostrar',
    sem_dados_exportacao: 'Não existem dados à serem exportados.',
    sem_dados_exportacao_periodo: 'Não existem dados à serem exportados para o período selecionado.',
    sem_dados_exportacao_apuracao: 'Não existem dados à serem exportados para a apuração selecionada.',
    sem_historico: 'Sem Histórico para mostrar',
    sem_passos: 'Sem informações de passos.',
    sem_saldo_disponivel: 'Você não tem saldo disponível para o valor provisionado da meta definida.<br/>Solicite um adendo de verba na respectiva linha de orçamento para<br/>prosseguir com o cadastro da meta',
    senha_redefinida: 'Senha redefinida com sucesso.',
    servico_incluido: 'Serviço incluído com sucesso.',
    servico_quantidade_evidencia_excedida: 'Excedeu a quantidade máxima de evidências permitidas.',
    so_letra_minuscula_e_underline: 'Preencher somente com letras minúsculas e underline',
    solicitacao_aprovacao: 'Solicitação de aprovação realizada!',
    tem_certeza_cancelar: 'Tem certeza que deseja cancelar?',
    tem_certeza_exluir_perfil: 'Tem certeza que deseja excluir o perfil {param}? <br />Lembrando que todos os usuários vinculados a este perfil serão inativados.',
    tem_certeza_limpar_selecao: 'Suas alterações serão perdidas se não salvá-las.<br/>Tem certeza que deseja limpar?',
    tem_certeza_alterar_status: 'Tem certeza que deseja alterar o status de {param}?',
    time_vendedores_serao_transferidos: 'O time de vendedores do usuário {de} serão transferidos para {para};',
    tipo_acao_agrupada_alterar_fluxo: 'Esta ação está vinculada à uma ação agrupadora no(s) seguinte(s) contrato(s): {contratosTipoAcaoAgrupada} e não poderá ter seu fluxo de trademarketing alterado. Para realizar esta alteração, por favor, remover os vínculos dela neste(s) contrato(s).',
    tipo_acao_agrupadora_alterada: 'Esta ação está vinculada à(s) ação(ões): {tipoAcaoHerancaConfig}, que herdam as configurações do passo de apuração. Caso tenha feito alterações neste passo, elas refletirão também nestas ações. Tem certeza que deseja continuar?',
    tipo_acao_agrupadora_vinculada: 'Esta ação está vinculada à(s) ação(ões): {tipoAcaoHerancaConfig} que herdam as configurações do passo de apuração e não poderá ser inativada. Para inativá-la, por favor, remover os vínculos de herança.',
    tipo_acao_agrupadora_contrato_inativar: 'Esta ação está sendo usada como agrupadora no(s) seguinte(s) contrato(s): {contratosTipoAcaoAgrupadora} e não poderá ser inativada. Para inativá-la, por favor, remover os vínculos de dela neste(s) contrato(s).',
    tipo_linha_orcamento: 'Tipo Linha Orçamento',
    tipo_provisao_obrigatorio: 'Tipo de provisão é obrigatório.',
    tipo_orc_associado_param_acao: 'O tipo de investimento está associado a uma parametrização de tipo de ação.',
    transferencia_aprovado: 'Transferência(s) aprovada(s).',
    transferencia_definitiva_carteira_filtro: 'Transferir a carteira selecionada no filtro',
    transferencia_definitiva_carteira_selecionada: 'Transferir apenas os clientes selecionados',
    transferencia_definitiva_cenario_1: 'Alteração do superior imediato de um vendedor - use esta opção quando for necessário alterar o gestor de um vendedor',
    transferencia_definitiva_cenario_2: 'Transferência de carteira de clientes, ações e verba de orçamento entre vendedores - use esta opção quando um vendedor não fizer mais parte do time comercial e outro vendedor irá assumir a sua respectiva carteira de clientes',
    transferencia_definitiva_cenario_3: 'Transferência entre gestores e do time de vendedores do gestor - use esta opção quando um gestor não fizer mais parte do time comercial e outro gestor for assumir a sua respectiva carteira de clientes. Obrigatoriamente você deve transferir também o time de vendedores do Gestor de Origem',
    transferencia_definitiva_cenario_4: 'Alteração de usuários que não atuam como vendedores mas que são responsáveis pela distribuição de verba no orçamento - use esta opção quando for necessário alterar um usuário que não é Gestor e nem Vendedor mas que é responsável pela distribuição de verba no orçamento (Exemplo: Gerente Nacional e Gerente de Canal)',
    transferencia_definitiva_cliente_filtro: 'Transferir todos os clientes selecionados no filtro cujo usuário de origem é o responsável principal',
    transferencia_definitiva_cliente_selecionado: 'Transferir apenas os clientes selecionados cujo usuário de origem é o responsável principal',
    transferencia_definitiva_toda_carteira: 'Transferir toda a carteira de clientes',
    transferencia_definitiva_toda_responsabilidade: 'Transferir toda a responsabilidade',
    transferencia_reprovado: 'Transferência(s) reprovada(s).',
    transferencia_finalizada: 'Transferência finalizada.',
    transferencia_enviada_aprovacao: 'Transferência enviada para aprovação.',
    transferencia: {
      mesma_competencia: 'Não pode realizar transferência para a mesma competência.',
    },
    upload: 'Upload arquivo aceito.',
    upload_anexo_acao_indisponivel: 'O upload de anexos só estará disponível após salvar o planejamento da ação.',
    upload_arquivo_obrigatorio_solicitacao: 'É obrigatório inserir anexo no passo </br>1 - Geral para solicitar aprovação',
    upload_evidencia_obrigatoria: 'Upload de evidência obrigatório',
    habilitar_upload_evidencia: 'Habilitar upload de evidência',
    habilitar_upload_evidencia_status_aprovado: 'Habilitar upload de evidência no status aprovado',
    upload_evidencia_obrigatoria_sellin: 'Upload de evidência obrigatório para ações de Sell In sem comprovação do valor realizado',
    upload_evidencia_obrigatoria_sellout: 'Upload de evidência obrigatório para ações de Sell Out',
    upload_evidencia_pendente: 'Upload de arquivo como evidência pendente',
    upload_notificacao_evidencia_apuracao: 'Notificar usuários inclusão de evidência',
    valor_aportar_maior_que_apurado: 'Valor a aportar maior que o valor apurado!',
    valor_negativo: 'Não é permitido valor negativo!',
    valor_aportar_nao_permitido: 'Não é permitido aportar esse valor.',
    valor_invalido: 'Valor inválido.',
    valor_lista_invalido: 'Valor inválido. Possíveis valores são: {valores}',
    valor_nao_considera_metas: '** Este valor não considera as demais metas com a mesma linha de orçamento deste acordo.',
    valor_meta_maior_zero: 'Valor Meta deve ser maior ou igual a 0,01',
    valor_menor_ja_utilizado: 'O valor não pode ser menor do que o valor já utilizado: {valor}',
    valor_reservado_maior_zero: 'Valor Reservado deve ser maior ou igual a 0,01',
    valor_pagamento_maior_zero: 'Valor Bonificação/Pagamento deve ser maior ou igual a 0,01',
    valor_pagar_maior_que_apurado: 'Valor a pagar maior que o valor apurado!',
    budget_previsto_maior_zero: 'Budget previsto deve ser maior ou igual a 0,01',
    valor_padrao_maximo: 'Valor deve ser menor ou igual a 99.999.999,99',
    valor_remanescente_orcamento: 'Valor total distribuído está diferente do valor planejado. ',
    variaveis_termo_aceite_industria: '<p>Na seção abaixo escreva o conteúdo do termo de aceite que será apresentado para o cliente.</br> Utilize as seguintes variáveis para que o sistema faça a substituição automática no conteúdo do termo: <ul><li>[CLIENTE]: código e nome do cliente (se o usuário estiver associado a mais de 1 cliente serão separados por vírgula);</li> <li>[LINK]: link do Tradelinks;</li> <li>[USUARIO]: usuário (login) para o qual o termo de aceite foi apresentado;</li> <li>[SENHA_PADRAO]: senha padrão definida na tela de Gestão de usuário no Tradelinks.</li></ul> Atenção! Para que o Tradelinks reconheça as variáveis elas devem ser escritas da seguinte forma: entre colchetes e com a descrição em letra maiúscula idêntica às variáveis apresentadas nesse texto de apoio.</p>',
    variaveis_termo_aceite_varejo: '<p>Na seção abaixo escreva o conteúdo do termo de aceite que será apresentado para o fornecedor.</br> Utilize as seguintes variáveis para que o sistema faça a substituição automática no conteúdo do termo: <ul><li>[GRUPO_FORNECEDOR]: nome do grupo fornecedor do usuário;</li> <li>[CNPJ]: CNPJs que compõem o grupo fornecedor do usuário;</li> <li>[NOME_COMPLETO]: nome completo do usuário;</li> <li>[CPF]: número do CPF do usuário;</li> <li>[DATA_ATUAL]: data do momento da abertura do termo;</li> <li>[LINK]: link do Tradelinks;</li> <li>[USUARIO]: usuário (login) para o qual o termo de aceite foi apresentado;</li> <li>[SENHA_PADRAO]: senha padrão definida na tela de Gestão de usuário no Tradelinks.</li></ul> Atenção! Para que o Tradelinks reconheça as variáveis elas devem ser escritas da seguinte forma: entre colchetes e com a descrição em letra maiúscula idêntica às variáveis apresentadas nesse texto de apoio.</p>',
    view_reprocessada: 'View reprocessada com sucesso.',
    verba_gestores_de_para_sera_alterada: 'A verba dos gestores {superiorDe} e {superiorPara} será alterada conforme verba do usuário de origem {de};',
    voltar_orcamento: 'Deseja voltar para a tela de orçamento?',
    voltar_aviso: 'Suas alterações serão perdidas se não salvá-las.',
    voltar_deseja_salvar: 'Deseja salvar as alterações realizadas antes de Voltar?',
    upload_sucesso: 'Sucesso no upload do arquivo!',
    upload_falha: 'Falha no upload do arquivo!',
    usuario_associado_divisao: 'Não foram identificados usuários associados a Divisão {de}, por isso, os fluxos de aprovação em andamento para ela não foram transferidos para a Divisão {para}.”',
    nao_existe_usuario_associado: 'Não foram identificados usuários associados a Divisão {de}, por isso, caso existam fluxos de aprovação em andamento para ela, os mesmos não serão transferidos para a Divisão {para}.”',
  },
  errors: {
    acao: {
      nao_existe: 'Ação inexistente!',
      acao_nao_reportada: 'Ação não reportada. É obrigatório realizar o Reporte da ação antes de enviar o Pagamento para aprovação.',
    },
    acesso: {
      negado: 'Acesso negado.',
      negado_suporte: 'Acesso Tradelinks não liberado, entre em contato com o suporte e solicite liberação.',
    },
    acordo: {
      obrigatorio: 'Acordo é obrigatório.',
      valor_selecao: 'Acordo possui valor da seleção errado.',
      inexistente: 'Acordo inexistente',
      info_bancaria_obrigatoria: 'Informações bancárias do cliente são obrigatórias!',
      multiplos_passos_cliente: 'Múltiplos passos cliente encontrados no fluxo de aprovação. Necessário ajustá-lo.',
      multiplos_passos_fornecedor: 'Múltiplos passos fornecedor encontrados no fluxo de aprovação. Necessário ajustá-lo.',
    },
    adicione_um_servico: 'Adicione pelo menos um serviço',
    ano_fiscal: {
      nao_encontrado: 'Período vigente não encontrado.',
      ja_existente_periodo: 'Período Fiscal já cadastrado.',
    },
    ajuste_verba: {
      saldo_menor_vlr_reducao_transferido: 'Não é possível aprovar o ajuste, pois o saldo disponível não é suficiente. Favor enviar o ajuste para análise ou reprová-lo.',
      processo_em_andamento: (data) => `Este ajuste de verba já esta em processamento desde ${data}. Aguarde a finalização que pode levar até 10 minutos.`,
    },
    aporte: {
      valor_aportado_excede_remanescente: 'Valor a aportar não pode ser maior que o remanescente.',
      valor_aportar: 'Valor a aportar é obrigatório.',
      valor_aportar_invalido: 'Valor a aportar inválido.',
      valor_aportar_lote_obrigatorio: 'É obrigatório preencher no mínimo um valor a aportar.',
      valor_aportar_negativo: 'Valor a aportar não pode ser negativo.',
    },
    apuracao: {
      inexistente: 'Apuracao inexistente',
      em_reivindicacao: 'Apuração em reivindicação.',
      em_fluxo: 'Apuração em fluxo.',
      sem_execucao_metas: 'Apuração sem execução de metas.',
      status_nao_permitido: 'Status da apuração não permitido para a operação.',
      apuracao_ja_aprovada: 'Apuração já aprovada, recarregue sua página.',
    },
    arquivo: {
      obrigatorio: (tipo) => `Arquivo de ${tipo} é obrigatório.`,
      nao_existe: 'Arquivo não',
      anexado: 'Arquivo está anexado em uma entidade.',
    },
    ativacao: {
      massa: 'Falha ao ativar/inativar todos registros em massa.',
    },
    base_calculo: {
      obrigatoria: 'Base de Cálculo é obrigatório.',
    },
    campo_customizado: {
      tipo_existente: 'Nome interno já existente na entidade informada.',
    },
    campos_obrigatorios: {
      nao_informados: 'Campos obrigatórios não informados',
    },
    canal_marca: {
      nao_informados: 'Campos Canal, Marca e Unidade de negócio são obrigatórios.',
    },
    carteira_cliente: {
      associada: 'Não é possível remover unidade de negócio associada a carteira de cliente.',
      existente: 'Vínculo já existente.',
      divisao_impedida_edicao: 'Não é possível editar a divisão pois esta possui clientes em uma de suas carteiras',
    },
    cep: {
      nao_encontrado: 'CEP não encontrado.',
    },
    cliente: {
      obrigatorio: 'Cliente é obrigatório',
      inativo: 'Cliente está inativo',
      nao_cadastrado: 'Cliente não está cadastrado',
      sem_saldo: (cliente) => `Cliente ${cliente} não tem saldo disponível.`,
      config_controlador_nao_alteravel: 'Não é possível alterar configuração de controlador',
      config_vinculo_nao_alteravel: 'Nao é possível alterar configuração de vínculo',
      ja_adicionado: 'Este cliente já foi adicionado',
    },
    cnpj: {
      existente: 'CNPJ já cadastrado no sistema',
      obrigatorio: 'CNPJ é obrigatório',
      tamanho: (min, max) => `CNPJ deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    cnpj_cpf: {
      obrigatorio: 'CPF/CNPJ é obrigatório',
    },
    cpf: {
      existente: 'CPF já cadastrado no sistema',
    },
    codigo: {
      obrigatorio: 'Código é obrigatório',
      existente: 'Código já cadastrado no sistema',
    },
    competencia: {
      obrigatoria: 'Competência é obrigatória',
      destino: {
        obrigatoria: 'Competência de destino é obrigatória',
      },
    },
    condicional: {
      cliente_aprovador: 'Falha na execução do fluxo: condicional de cliente aprovador não pôde ser avaliada pois o passo não possui usuário de um cliente!',
    },
    config_rebate: {
      ja_cadastrado: 'Condição percentual já cadastrada ou duplicada',
      condicao_sem_upload: 'Necessário o upload de arquivo nas condições percentuais',
    },
    conta_corrente: {
      sem_saldo: 'Conta corrente não tem saldo disponível.',
      tipo_sem_saldo: (tipo) => `Conta corrente ${tipo} não tem saldo disponível.`,
      valor_nao_distribuido: 'Valor não distribuído corretamente entre as contas correntes',
      verba_bloqueada: 'Não é possível solicitar aprovação, pois a verba escolhida está bloqueada temporariamente.',
      campo_tipo_verba_obrigatorio: 'O campo Tipo de Verba é obrigatório',
      campo_periodos_obrigatorio: 'O campo Períodos é obrigatório',
      pagamento_liquidado: 'Não é possível cancelar uma apuração com pagamento liquidado',
      saldo_insuficiente: 'Não foi possível realizar a operação, pois a conta corrente não possui saldo suficiente.',
      vlr_reservado_insuficiente: 'Não foi possível realizar a operação, pois a conta corrente não possui valor reservado suficiente.',
      reserva_conta_unica: 'Este tipo de ação não permite distribuição do valor em mais de uma conta corrente. Favor alocar 100% do valor em uma única conta corrente para prosseguir.',
      saldo_liquidado: 'Não foi possível cancelar a apuração, pois já existem pagamentos liquidados.',
    },
    contrato: {
      conflitante: (cliente) => `Já existe um contrato como cliente: ${cliente} .`,
      configuracao_conflitante: 'Já existe um contrato para o mesmo período, tipo e foco.',
      obrigatorio: 'Contrato não existe na base.',
      sem_acao_cadastrada: 'Deve haver pelo menos uma ação em cadastro detalhada.',
      ja_cadastrado: 'Já existe um Contrato cadastrado com essa configuração',
      loja: {
        sem_vinculo_cliente: 'Não há lojas vinculadas',
      },
      solicitar_aprovacao_acao_data_conflito_acao_agrupadora: 'Não é possível seguir com o fluxo de aprovação pois já existem apurações concluídas e não poderão ser agrupadas na ação automática "{nomeAcaoAgrupadora}".',
    },
    data: {
      mesmo_mes: 'Data inicial e final devem pertencer ao mesmo mês',
      finalantesinicial: (entidade) => `Data inicial de ${entidade} maior que data final.`,
      inicio_antes_seguinte: 'Data Inicial menor que Dia Seguinte',
      final_antes_inicial: 'Data Inicial maior que Data Final',
      fora_vigencia: 'Data fora da vigência do contrato',
      japassou: 'Apenas datas futuras podem ser selecionadas',
      obrigatoria: 'Data é obrigatória',
      periodo_excedido: 'O período máximo para exportação são de 3 meses',
      periodo_excedido12: 'O período máximo para exportação são de 12 meses',
      conflitante: (entidade, filha, range) => `Data ${range} de ${filha} está fora do período do ${entidade}.`,
      retroativa_erro: 'Data retroativa maior que a data atual',
      inicial_menor_final: 'Data inicial deve ser menor que data final.',
    },
    divisao: {
      superior_invalida: 'Divisão superior inválida, analise a estrutura de usuário',
      nao_encontrada: 'Divisão não encontrada',
      obrigatoria: 'Divisão é obrigatória!',
      associada: {
        estrutura_usuario: 'Estrutura de usuário já esta associado a uma divisão',
      },
      divisao_diferente: (entidade) => `Você não possui a mesma divisão do ${entidade}.`,
      ja_adicionado: 'Esta divisão já foi adicionada.',
    },
    dta_inicio: {
      obrigatoria: 'Data início é obrigatória',
      menor_atual: 'Data início menor que data atual',
      menor_ultimo_periodo: 'Data início presente em outro período fiscal.',
    },
    dta_fim: {
      obrigatoria: 'Data fim é obrigatória',
    },
    definicao: {
      obrigatoria: 'Definição de usuário é obrigatória',
      inexistente: (dependencia) => `${dependencia} não encontrado no sistema`,
    },
    dependencia: {
      obrigatoria: 'Dependência é obrigatória',
      inexistente: (dependencia) => `${dependencia} não encontrado no sistema`,
    },
    dependencia_especifica: {
      obrigatorio: (dependencia) => `${dependencia} é obrigatório`,
    },
    descricao: {
      existente: 'Descrição já cadastrado no sistema',
    },
    descricao_acao: {
      obrigatorio: 'Descrição da Ação é obrigatória',
    },
    download: {
      arquivo_invalido: 'Arquivo inválido para importação.',
      erro_desconhecido: 'Erro ao baixar o arquivo. Tente novamente.',
    },
    ean: {
      duplicado: 'EAN já está em uso',
      obrigatorio: 'EAN é obrigatório',
      invalido: 'EAN inválido',
      tamanho: (max) => `O EAN deverá ter ${max} caracteres`,
    },
    email: {
      duplicado: 'E-mail já cadastrado no sistema',
      obrigatorio: 'E-mail é obrigatório',
      tamanho: (min, max) => `E-mail deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
      formato_invalido: 'E-mail com formato inválido',
    },
    endereco: {
      bairro_obrigatorio: 'Bairro é obrigatório',
      cep_obrigatorio: 'CEP é obrigatório',
      cidade_obrigatorio: 'Cidade é obrigatória',
      numero_obrigatorio: 'Número é obrigatório',
      endereco_obrigatorio: 'O Endereço é obrigatório',
    },
    entidade: {
      duplicada: (entidade) => `${entidade} em conflito com outro(a) ${entidade} já cadastrado(a) no sistema.`,
      inexistente: (entidade) => `${entidade} não encontrado(a) no sistema.`,
      obrigatoria: (entidade) => `${entidade} é obrigatório(a).`,
      associada: (entidade) => `${entidade} já associado(a).`,
      associada_identificador: (entidade, identificador) => `${identificador} associado(a) com ${entidade}.`,
      nao_associada: (entidade, identificador) => `${entidade} não associado(a) com ${identificador}.`,
      sem_representante: (entidade) => `${entidade} não possui representantes.`,
      campos_obrigatorios: (entidade) => `Campos obrigatórios da ${entidade} não informados`,
    },
    estrutura_usuario: {
      nao_trocar_estrutura_divisao: 'Não é possível trocar a estrutura da divisão, já possui filhos',
      ja_existente: 'Nome de estrutura de usuário já existente!',
      ja_adicionado: 'Esta estrutura de usuário já foi adicionada.',
      obrigatoria: 'Estrutura de usuário é obrigatória!',
    },
    eventos: {
      ja_existente_periodo: 'Já existe um evento cadastrado para este período',
    },
    evidencia: {
      nao_existe: 'Evidência inexistente!',
    },
    execucao: {
      inexistente: 'Arquivo de Execução metas inexistente',
      execucao_ja_concluida: 'A Execução já foi concluído. Não é possível alterar suas informações.',
      bloqueada: 'Existem ações executadas no período fiscal atual que não foram reportadas. Verifique.',
      nao_existe: 'Execução tática inexistente!',
      alteracao_status_erro: (status) => `Não foi possível alterar o status para ${status}.`,
      aprovar: 'Não foi possível aprovar.',
      enviar_aprovacao: 'Erro ao enviar para aprovação.',
      reprovar: 'Não foi possível reprovar.',
    },
    extensao_arquivo: {
      invalido: 'A extensão do arquivo está incorreta',
    },
    extensao: {
      nivel: {
        invalido: 'Nivel da extensão é inválido',
        obrigatorio: 'Nivel da extensão é obrigatório',
      },
      nao_validas_para_unidade_negocio: 'Unidades de negócio inválidas ou duplicadas',
      selecionar: 'Execute a busca e selecione um(a) {text}',
      mal_formatada: (extensao) => `Extensão má formatada: ${extensao}`,
    },
    fluxo: {
      cliente_nao_encontrado: 'Cliente aprovador não encontrado para a configuração do passo',
      existente: 'Workflow existente',
      duplicado_solicitante: 'Workflow já cadastrado para o solicitante',
      fluxo_reivindicacao_inexistente: 'Fluxo reivindicação inexistente.',
      ja_solicitado: 'Workflow já solicitado para a entidade',
      nao_apuravel: 'Fluxo aprovação não apurável.',
      nao_encontrado: 'Workflow não encontrado',
      nao_encontrado_solicitante: 'Workflow não encontrado para o solicitante',
      nao_encontrado_passo: 'Passo não encontrado',
      nao_encontrado_passo_elegivel: 'Fluxo de aprovação não pode ser iniciado pois não foi encontrado passo de aprovação elegível.',
      nao_pode_ser_avaliado: 'Passo não pode ser avaliado',
      nao_transferivel: 'Passo não pode ter transferência de aprovador',
      passo_obrigatorio: 'É necessário pelo menos um passo para cadastro do Workflow',
      passo_elegivel_obrigatorio: 'É necessário pelo menos um passo de aprovação elegível para dar continuidade ao fluxo.',
      passo_unico_nao_atende_condicao: 'O(s) passo(s) ativo(s) não atendem à condição do fluxo.',
      rejeicao_obrigatoria: 'Motivo de rejeição é obrigatório',
      selecionar_configuracao: 'É necessário escolher usuário ou perfil',
      solicitante_pagamento: 'Usuário não pode solicitar pagamentos para a apuração.',
      ultimo_passo_condicional: 'Não é permitido selecionar condicional no último passo do fluxo',
      usuario_nao_aprova: 'Usuário não pode aprovar o passo',
    },
    fornecedor: {
      obrigatorio: 'Fornecedor é obrigatório',
      inativo: 'Fornecedor está inativo',
      nao_cadastrado: 'Fornecedor não está cadastrado',
      ja_adicionado: 'Este fornecedor já foi adicionado',
    },
    id: {
      existente: 'Código já cadastrado no sistema',
      inexistente: 'Código inexistente no sistema',
    },
    id_externo: {
      existente: 'Código já cadastrado no sistema',
      obrigatorio: 'Código externo é obrigatório',
    },
    id_regiao: {
      obrigatorio: 'Região é obrigatória',
    },
    id_tipo_investimento: {
      obrigatorio: 'Tipo de linha de orçamento é obrigatorio',
    },
    id_tipo_orcamento: {
      obrigatorio: 'Tipo de orçamento é obrigatorio',
    },
    inicializacao: 'Ocorreu um erro desconhecido na inicialização da aplicação, tente mais tarde ou contate o administrador',
    inicializacao_sem_perfil: 'O seu usuário não está vinculado a um perfil, contate o administrador',
    justificativa: {
      obrigatoria: 'Justificativa é obrigatória',
    },
    importacao: {
      chave: {
        duplicada: 'Registro duplicado na planilha: {param}',
      },
      data: {
        data_formato_invalido: 'Data em formato inválido: {param}',
        data_preenchimento_indevido: 'Preenchimento indevido da data: {param}',
      },
      generico: 'Erro na importação contate o administrador do sistema: {param}',
      nao_generico: '{param}',
      pagamento: {
        pagamento_inexistente: 'Pagamento não encontrado: {param}',
      },
      produto: {
        inexistente: 'Produto Inexistente',
      },
      quantidade_linhas_ignoradas_data: 'Quantidade de linhas ignoradas por estarem fora do intervalo de datas: {param}',
      status: {
        status_nao_permitido: 'Status não permitido: {param}',
      },
      tamanho_excedente: 'Tamanho excedente: {param}',
      valor: {
        duplicado: 'Valor duplicado na coluna: {param}',
        existente: 'Valor já existente no banco de dados, na coluna: {param}',
        excedente: 'Campo com tamanho excedente: {param}',
        formato: 'Campo com formato inválido na coluna: {param}',
        fora: 'Campo fora do período permitido: {param}',
        inativo: 'Valor inativo na coluna: {param}',
        inexistente: 'Valor inexistente na coluna: {param}',
        marca: 'Valor inexistente na coluna: {param}',
        nao_associado: 'Valor não associado com a dependência obrigatória: {param}',
        negativo: 'Um dos Hierarquia e/ou Unidade de negócio tem de ter o valor SIM',
        vazio: 'Valor vazio na coluna: {param}',
        incorreto: 'Valor incorreto: {param}',
        invalido: 'Valor Inválido: {param}',
        invalido_na_coluna: 'Valor inválido na coluna: {param}',
        decimal_6: 'O {param} deve conter no máximo 6 casas decimais.',
        inteiro_5: 'O {param} deve conter no máximo 5 casas inteiras.',
        sellout: 'Sell Out duplicado.',
      },
      vinculo: {
        existente: 'Vínculo existente',
        inexistente: 'Vínculo inexistente: {param}',
        extensao: {
          nao_remover: 'Os vínculos removidos são utilizados em outros pontos do sistema. Não é possível realizar esta ação!',
        },
      },
      layout: {
        diferente: 'Planilha com o formato inválido: {param}',
        tipo: 'Planilha importada não é do tipo {param}',
      },
      meta: {
        nao_existe: 'Meta inexistente!',
      },
      dependencia: {
        ativa: 'Possui dependência Ativo(a): {param}',
        inexistente: 'Possui dependência(s) Inexistente(s) na coluna(s): {param}',
        duplicada: 'Possui dependência Duplicada: {param}',
      },
      quantidade: {
        colunas: 'A quantidade de colunas com valores está divergente da quantidade esperada: {param}',
      },
      relacionamento: {
        duplicado: 'Registro duplicado. Relacionamento entre o cliente e unidade de negócio já foi realizado.',
      },
      responsavel: {
        principal: {
          duplicado: '{param} - 2 vendedores ativos para o mesmo cliente e unidade de negócio na coluna "Responsável Principal"',
        },
      },
      servico: {
        nao_existe: 'Serviço inexistente!',
      },
    },
    imprimir: 'Falha ao solicitar impressão',
    inconsistencia: {
      registro_inexistente: 'Registro não encontrado. Alguma inconsistência na base?',
    },
    linha: {
      inexistente: (entidade, linha) => `Linha ${linha}: ${entidade} não encontrado(a) no sistema.`,
      obrigatoria: (entidade, linha) => `Linha ${linha}: ${entidade} é obrigatório(a).`,
      nao_associada: (entidade, identificador, linha) => `Linha ${linha}: ${entidade} não associado(a) com ${identificador}.`,
      sem_representante: (entidade, linha) => `Linha ${linha}: ${entidade} não possui representantes.`,
      sem_divisao: (linha) => `Linha não possui divisão, mas o modo de rateio é por divisão: ${linha}`,
      sem_extensao: (extensao, linha) => `Linha não possui ${extensao}, mas o modo de rateio é por ${extensao}: ${linha}`,
      invalida: (entidade, linha) => `Linha ${linha}: ${entidade} inválido(a) para os demais parâmetros especificados na linha de investimento.`,
    },
    linha_investimento: {
      duplicada: (linha, entidades) => `Linha de orçamento ${linha} em conflito com a(s) linha(s): ${entidades}.`,
      obrigatoria: 'Linha de orçamento é obrigatório.',
    },
    login: {
      duplicado: 'Login já cadastrado no sistema.',
    },
    marca: {
      selecionar: 'Execute a busca e selecione uma marca',
    },
    material: {
      estoque_excedido_envio_aprovacao: (material, pedido, estoque) => `Não é possível enviar para Aprovação
        pois não há estoque disponível de [${material}].<br/>
        Quantidade no pedido: ${pedido}. Quantidade em Estoque: ${estoque}`,
      quantidade_minima: 'Quantidade mínima não atingida',
      quantidade_maior_disponivel: 'Quantidade de material informada maior do que o disponível',
      ja_concluido: 'O controle de Materiais já foi concluído. Não é possível alterar suas informações.',
      quantidade_estoque_excedida: 'Quantidade indisponível em estoque para o pedido.',
    },
    matricula: {
      duplicada: 'Matrícula já cadastrada no sistema',
    },
    meta: {
      apuracao: {
        em_fluxo: 'Meta com apuração em fluxo',
      },
      cliente: {
        extensao: {
          obrigatoria: 'Cliente não associado à unidade de negócio referenciada na Meta',
        },
      },
      intervalos: {
        obrigatorios: 'A meta deve ter pelo menos um intervalo cadastrado.',
        conflito: 'Intervalo de valores em conflito com outro intervalo da mesma meta.',
      },
      inexistente: 'Meta inexistente para fazer upload arquivo',
      meta_anterior_ate_maior_meta_atual_de: 'Verifique os campos de data da meta',
      meta_ate: {
        menor_meta_de: 'Data menor que o campo \'meta de\'',
        obrigatoria: 'O campo meta até é origatório',
      },
      negativa: 'Meta não pode ser negativa',
      obrigatoria: 'Meta é obrigatória',
      valor_base_crescimento_obrigatorio: '',
      valor: {
        diferente_acordo: 'A somatória do valor das metas está diferente do valor do acordo',
        disponivel: {
          excedido: 'Excedeu o valor disponível, confira na tabela de metas.',
        },
      },
    },
    motivo: {
      obrigatorio: 'Deve-se enviar o motivo. Campo obrigatório.',
    },
    nome: {
      existente: 'Nome já cadastrado no sistema',
      obrigatorio: 'Nome é obrigatório',
      tamanho: (min, max) => `Nome deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    nota_fiscal: {
      campos_obrigatorios: 'Campos obrigatórios da Nota Fiscal não informados',
    },
    nome_fantasia: {
      existente: 'Nome Fantasia já cadastrado no sistema.',
      obrigatorio: 'Nome Fantasia é obrigatório',
      tamanho: (min, max) => `Nome Fantasia deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    nro_nota: {
      obrigatorio: 'Número da nota é obrigatório',
      tamanho: (min, max) => `Número da nota deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    numerico: {
      tamanho: (campo, inteiro, decimal) => `O ${campo} deve conter no máximo ${inteiro} casas inteiras e ${decimal} casas decimais`,
    },
    orcamento: {
      descricao_obrigatorio: 'Descrição é obrigatória',
      existente: 'Orçamento existente',
      tipo_linha_orcamento_obrigatorio: 'Tipo de linha de orçamento é obrigatório',
      canal_obrigatorio: 'Canal é obrigatório',
      centro_custo_obrigatorio: 'Centro de Custo é obrigatório',
      linha_orcamento_obrigatorio: 'Linha de orçamento é obrigatória.',
      marca_obrigatorio: 'Marca é obrigatório',
      duplicado: (entidades) => `As datas estão conflitantes com outro(s) orçamento(s) do mesmo tipo. Orçamento(s) em conflito: ${entidades}.`,
      descricao_duplicada: 'Já existe um orçamento com essa descrição.',
      orcamento_valor_linha_nova: 'Valor da linha deve ser igual a zero.',
      valor_planejado_obrigatorio: 'Valor planejado deve ser maior que zero.',
      valor_invalido_rateio_competencia: 'Valor inválido no rateio para a competencia {competencia}.',
    },
    pagamento: {
      id_obrigatorio: 'Selecione um ou mais pagamentos',
      iniciado: 'A ação já tem pagamento cadastrado.',
      ja_cancelado: 'Um ou mais pagamentos selecionados já foram cancelados.',
      ja_concluido: 'Manutenção concluída, atualize a página.',
      cancelado_concluido: 'Operação não realizada,o item já estava Concluído/Cancelado.',
    },
    pedido: {
      data_obrigatoria: 'Data de entrega é obrigatória.',
      valor_obrigatorio: 'Valor final frete é obrigatório.',
    },
    perfil: {
      obrigatorio: 'Perfil é obrigatório!',
    },
    planejamento: {
      acoes_sem_materiais: (acoes) => `Ação PDV sem materiais vinculados: ${acoes}. Adicione materiais para prosseguir.`,
      obrigatorio: 'Planejamento é obrigatório.',
      acao_obrigatorio: 'Ação é obrigatória.',
      copia: {
        nao_encontrado: 'Planejamento de origem não foi encontrado.',
      },
    },
    planilha: {
      distribuido: 'Valor distribuído nas linhas não confere com o total planejado',
      entidade_invalida: 'O(a) {entidade} [{valor}] não existe na base de dados',
      informacao_duplicada: 'Informação duplicada na planilha: {valor}',
      linha_investimento_requerida: 'Pelo menos uma linha de orçamento precisa estar preenchida',
      obrigatorio: 'O(a) {entidade} é obrigatório(a) no cadastro',
      relacionamento: 'Relacionamento inconsistente entre o {entidade} [{valor}] e {entidade_ref} [{valor_ref}]',
      valor_menor_zero: 'Valor não pode ser menor que zero: {valor}',
    },
    periodo: {
      utilizado: {
        em_planejamento: 'Período já utilizado em um planejamento',
      },
      intervalos: {
        obrigatorios: 'O período deve ter pelo menos um intervalo cadastrado.',
      },
      com_bloqueio: 'Período Fiscal com bloqueio',
      conflito: 'Período em conflito',
      obrigatorio: 'Período é obrigatório',
      invalido: 'Período inválido',
      fora: (entidade, pai) => `Período de ${entidade}, está fora do período de ${pai}`,
    },
    periodo_orcamentario: {
      intervalos: {
        obrigatorios: 'O período orçamentário deve ter pelo menos um intervalo cadastrado.',
      },
    },
    preco_negociacao: {
      obrigatorio: 'Preço negociação é obrigatório',
    },
    produto: {
      obrigatorio: 'Produto é obrigatório',
    },
    produto_eleito: {
      nao_encontrato: (configuracao) => `Nenhum produto encontrado para a configuração da meta: ${configuracao}`,
    },
    qtde: {
      obrigatoria: 'Quantidade é obrigatória',
      negativa: 'Quantidade precisa ser maior que 0',
    },
    rateio: {
      dist_invalida: 'Distribuição inválida do rateio. Atente-se para o totalizador.',
      dist_invalida_saldo: 'Distribuição inválida do rateio. Atente-se para o saldo disponível.',
      dist_invalida_vlr_alocado: 'Valor distribuído não pode ser menor que o valor já alocado.',
      dist_invalida_vlr_minimo: 'Valor distribuído não pode ser menor que o valor mínimo.',
      sem_estrutura_representante: 'Não é possível fazer rateio sem estrutura de usuário para representante.',
      sem_estrutura_usuario: (estrutura) => `Nenhum usuário encontrado para a estrutura ${estrutura}.`,
      mais_de_um_superior: (usuario) => `Usuário ${usuario} possui mais de um superior imediato no rateio.`,
      saldo_negativo: 'Saldo negativo {saldo} para a divisão {divisao}',
    },
    razao_social: {
      existente: 'Razão Social já cadastrada no sistema.',
      obrigatorio: 'Razão Social é obrigatório',
      tamanho: (min, max) => `Razão Social deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    reducao: {
      valor_reduzir: 'Valor a reduzir é obrigatório.',
      valor_reduzir_invalido: 'Valor a reduzir inválido.',
      valor_reduzir_maior_saldo: 'Valor a reduzir maior que o saldo disponível.',
      valor_reduzir_menor_zero: 'Valor total da redução de verba deve ser maior que zero.',
    },
    registros: {
      ativos: {
        dependentes: 'Não é possivel inativar pois existem cadastros ativos que dependem deste registro.',
      },
      nao_ha_dados: 'Não há dados para serem exportados.',
    },
    required: 'Este campo é obrigatório',
    role: {
      obrigatoria: 'Role é obrigatória',
    },
    saldo_conta_corrente: {
      obrigatorio: 'Saldo da conta corrente é obrigatório',
    },
    selecionar: {
      canal_vendas: 'Execute a busca e selecione um canal de vendas',
      familia_produto: 'Execute a busca e selecione uma família',
      linha_investimento: 'Execute a busca e selecione uma linha de orçamento',
      marca: 'Execute a busca e selecione uma marca',
      tipo_acao: 'Execute a busca e selecione um tipo de ação',
      tipo_linha_orcamento: 'Execute a busca e selecione um tipo de linha de orçamento',
      unidade_medida: 'Execute a busca e selecione uma unidade de medida',
    },
    sem_permissao: {
      alterar: (entidade) => `O usuário não tem permissão para alterar o(a) ${entidade}`,
      visualizar: (entidade) => `O usuário não tem permissão para visualizar o(a) ${entidade}`,
    },
    servico: {
      tipo_verba_nao_permitido: 'O Tipo de Verba informado não permite inserir serviços.',
    },
    sessao_expirada: 'A sessão expirou, será necessário realizar novo login!',
    sigla: {
      obrigatoria: 'Sigla é obrigatória',
      existente: 'Sigla já cadastrada no sistema',
      tamanho: (min, max) => `Sigla deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    sistema: {
      bloqueio: 'O sistema se encontra bloqueado para a funcionalidade solicitada.',
    },
    status: {
      saldo_insuficiente: 'Saldo insuficiente',
      necessario: (...params) => `O status do contrato atual deveria ser: ${params.toString()}`,
      incorreto: (...params) => `O status atual deveria ser: ${params.toString()}`,
      obrigatorio: 'Status é obrigatório',
      transferencia: (...params) => `Transferência não efetuada. O status do Orçamento deve ser: ${params.toString()}`,
      alteracao_inadequada: (...params) => `Não é possível alterar o status para ${params.toString()}`,
      apuracao_invalido: (novoStatus, status) => `Não é permitido atualizar o status do pagamento para ${novoStatus}, pois o status atual da apuração está ${status}`,
    },
    tamanho: {
      rg: (min, max) => `RG deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
      telefone: (min, max) => `Telefone deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
      celular: (min, max) => `Celular deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
      orgao_expedidor: (min, max) => `Órgão expedidor deverá ter um tamanho mínimo de ${min} e máximo de ${max} caracteres`,
    },
    tipo_acordo: {
      obrigatorio: 'Tipo acordo é obrigatório',
      inexistente: 'Tipo acordo inexistente',
    },
    tipo_beneficio: {
      obrigatorio: 'Tipo benefício é obrigatório',
    },
    tipo: {
      beneficio: {
        diferente: (entidade) => `Tipo benefício é diferente do ${entidade}`,
      },
      selecionar: 'Execute a busca e selecione um tipo',
    },
    tipo_linha_orcamento: {
      em_uso: 'Tipo de linha de orçamento está em uso',
    },
    transferencia: {
      saldo_menor_vlr_transferido: 'Saldo insuficiente',
      mesma_linha: 'Você não pode selecionar o mesmo Tipo de linha de orçamento de origem',
      mesmo_controlador_trade: 'Você não pode selecionar a mesma Divisão de origem',
      nao_permite_transf_tipo_verba_diferente: 'O orçamento selecionado não permite transferência entre tipos de verbas diferentes',
    },
    transferencia_posse: {
      ja_realizada_funcionario: 'Transferência de posse já realizada para esse funcionário.',
      ja_realizada_divisao: 'Transferência de posse já realizada para essa divisão.',
    },
    un_medida: {
      obrigatoria: 'Unidade de medida é obrigatória',
    },
    upload: {
      falha_diretorio: 'Diretório não encontrato',
    },
    usuario: {
      asociado_cliente: 'Usuário já associado com um Cliente',
      estrutura_usuario: 'Usuário não tem definição Hirarquia/Unidade de Negócio.',
      obrigatorio: 'Usuário é obrigatório',
      cadastro_nao_finalizado: 'Não foi possível finalizar seu cadastro, tente o login novamente.',
    },
    valor: {
      conflitante: (range) => `Valor limite ${range} conflitante com os limites do fluxo anterior.`,
      disponivel: {
        excedido: 'Excedeu o valor disponível.',
        representante: {
          excedido: (representante) => `Representante ${representante} excedeu o valor disponível.`,
        },
      },
      excedido: (entidade, pai) => `Valor ${entidade} excedeu valor máximo de ${pai}.`,
      final: 'Alçada anterior é a final.',
      intervalos: {
        conflito: 'Intervalo de valores em conflito com outro intervalo.',
      },
      maior: 'Valor inicial está maior que valor final.',
      maior_que_pendente: 'Valor maior que o valor pendente.',
      menor: 'Valor final está menor que valor inicial.',
      maior_zero: 'Valor deve ser maior que 0,00',
      maior_zero_inteiro: 'Valor deve ser maior que 0',
      minimo: 'Valor deve ser maior ou igual a {min}',
      maximo: 'Valor deve ser menor ou igual a {max}',
      negativo: 'Valor não pode ser negativo',
      obrigatorio: (entidade) => `Valor ${entidade} obrigatorio.`,
      valor_padrao_maximo: 'Valor deve ser menor ou igual a 99.999.999,99',
    },
    valor_previsto: {
      obrigatorio: 'Valor Previsto obrigatório',
    },
    vencimento: {
      prazo_notificacao_maior_vencimento: 'O prazo para notificação deve ser menor que o prazo de vencimento.',
    },
    vinculo: {
      existente: 'Vínculo existente',
    },
    vlr_aliquota: {
      obrigatorio: 'Valor da alíquota é obrigatório',
    },
    vlr_impostos: {
      obrigatorio: 'Valor de impostos é obrigatório',
    },
    valor_de_maior_ate: 'Valor até deve ser maior que valor de',
    valor_inicial_mayor_final: 'Valor final deve ser maior que valor inicial',
    valor_maior_saldo: 'Valor do ajuste deve ser menor ou igual ao saldo disponível',
    valor_negativo: 'Valor do ajuste deve ser positivo',
    valor_positivo: 'A quantidade deve ser positiva',
    meta_ate_maior_meta_de: '"Meta até" deve ser maior que "Meta de"',
    vlr_investimento: {
      obrigatorio: 'Valor de Linha de Orçamento é obrigatório',
    },
    vlr_unitario: {
      obrigatorio: 'Valor unitário é obrigatório',
    },
    volume: {
      tamanho: 'O volume deve conter no máximo {inteiro} casas inteiras e {decimal} casas decimais',
      valor_negativo: 'O volume não pode ser negativo',
    },
    volume_base: {
      decimal: 'O volume base não pode ser decimal',
      negativo: 'O volume base não pode ser negativo',
      obrigatorio: 'O campo volume base é obrigatório',
    },
    workflow: {
      fluxo_sem_aprovador_valido: 'Fluxo não tem um aprovador válido.',
    },
  },
  roles: {
    ACAO_CRUD: {
      titulo: 'Manutenção de Planejamento de Ação',
      descricao: 'Permite acesso ao módulo Planejamento de ação para cadastro de uma nova ação e manutenção de suas informações',
    },
    ACAO_PAGINA: {
      titulo: 'Consulta de Planejamento de Ação',
      descricao: 'Permite acesso ao módulo de Planejamento de ação para consulta',
    },
    ACAO_PAGINA_APROVADO: {
      titulo: 'Consulta de Planejamento de Ação Aprovada',
      descricao: 'Permite acesso ao módulo de Planejamento de ação para consulta de ações aprovadas',
    },
    ACORDO_RATEIO_PAGINA: {
      titulo: 'Suporte Rateio Manual',
      descricao: 'Permite acesso à tela de suporte para refazer o rateio manual de acordos aprovados',
    },
    ACESSO_FORNECEDOR: {
      titulo: 'Consultas gerais do fornecedor no Tradelinks',
      descricao: 'Permite ao usuário acesso aos módulos de Planejamento, Apuração e Recebimento para consultas gerais',
    },
    ACESSO_CLIENTE: {
      titulo: 'Consultas gerais do cliente no Tradelinks',
      descricao: 'Permite ao usuário acesso aos módulos de Planejamento, Apuração e Pagamento para consultas gerais',
    },
    ADMINISTRACAO_PAGINA: {
      titulo: 'Administração',
      descricao: 'Permite acesso a página de administração',
    },
    AJUSTE_VERBA_PAGINA: {
      titulo: 'Consultar Ajuste de verba',
      descricao: 'Permite acesso à tela de Ajuste de verba para consulta',
    },
    AJUSTE_VERBA_CRUD: {
      titulo: 'Cadastrar Ajuste de verba',
      descricao: 'Permite acesso à tela de Ajuste de verba para cadastro',
    },
    APU_ACAO_CRUD: {
      titulo: 'Alterar e manter apuração',
      descricao: 'Permite acesso ao módulo de agpuração de ação para alteração, cancelamento e solicitação de aprovação',
    },
    APU_ACAO_PAGINA: {
      titulo: 'Consulta de Apuração de Ação',
      descricao: 'Permite acesso ao módulo de apuração de ação para consulta',
    },
    APU_CANCELA_VENCIDO: {
      titulo: 'Cancela apurações vencidas',
      descricao: 'Permite cancelamento de apurações vencidas',
    },
    APU_RESGATA_VENCIDO: {
      titulo: 'Resgata apurações vencidas',
      descricao: 'Permite resgate de apurações vencidas',
    },
    LOG_DOWN_CAT_PECA: {
      titulo: 'Consulta log dos downloads',
      descricao: 'Permite acesso ao tela de log dos downloads dos catalogos',
    },
    DOWN_CAT_PECAS: {
      titulo: 'Download catalogo de peças',
      descricao: 'Permite ao usuário fazer download do catalogo de pecas',
    },
    CARTEIRA_PAGINA: {
      titulo: 'Consulta de Carteira de Cliente',
      descricao: 'Permite acesso ao Cadastro de Carteira de Cliente apenas para consulta',
    },
    CARTEIRA_CRUD: {
      titulo: 'Cadastro de Carteira de Cliente',
      descricao: 'Permite acesso ao Cadastro de Carteira de Cliente para realização de cadastro e edição de registros de Carteira de Cliente',
    },
    CONFIG_PLAN: {
      titulo: 'Configuração de planejamento',
      descricao: 'Acesso a Configuração de Planejamento',
    },
    CANCELAR_CONTRATO: {
      titulo: 'Cancelamento de contrato',
      descricao: 'Permite acesso a cancelar um contrato aprovado',
    },
    CLIENTE_APU_ACAO_CRUD: {
      titulo: 'Manutenção de Apuração de Ação para Usuário Cliente',
      descricao: 'Permite acesso ao usuário cliente a inserir evidências nas comprovações de ações, cancelamento e solicitação de aprovação',
    },
    CONTRATO_CRUD: {
      titulo: 'Manter planejamento de contrato',
      descricao: 'Permite o acesso ao módulo de Contrato para criar, editar e solicitar aprovação',
    },
    CONTRATO_PAGINA: {
      titulo: 'Consultar planejamento de contrato',
      descricao: 'Permite o acesso ao módulo de Contrato para consulta e acompanhamento do fluxo de aprovação',
    },
    CONT_UPLOAD_ARQ: {
      titulo: 'Realizar upload de arquivos no contrato',
      descricao: 'Permite acesso para realizar upload de arquivos no contrato independente do status do contrato',
    },
    FLUXO_GEST_APRO: {
      titulo: 'Gestão de aprovações',
      descricao: 'Permite alterar o aprovador dos fluxos em andamento ou remover passos.',
    },
    EVT_PAGINA: {
      titulo: 'Consultar Eventos Acesso',
      descricao: 'Consultar eventos de acesso do usuário',
    },
    EXTRATO_PAGINA: {
      titulo: 'Consultar Extrato',
      descricao: 'Permite acesso a consulta de extratos de contas correntes',
    },
    FILIAL_CRUD: {
      titulo: 'Cadastro de filiais',
      descricao: 'Permite acesso à tela de filial para cadastro',
    },
    FILIAL_PAGINA: {
      titulo: 'Consulta de filiais',
      descricao: 'Permite acesso à tela de filial para consulta',
    },
    FUNC_PAGINA: {
      titulo: 'Consulta de Funcionário',
      descricao: 'Permite acesso ao Cadastro de Funcionário apenas para consulta',
    },
    FUNC_CRUD_ATUALIZAR: {
      titulo: 'Cadastro de Funcionário',
      descricao: 'Permite acesso ao Cadastro de Funcionário para realização de edição de registros de Funcionário',
    },
    FUNC_CRUD_INSERIR: {
      titulo: 'Cadastro de Funcionário',
      descricao: 'Permite acesso ao Cadastro de Funcionário para realização de cadastro de registros de Funcionário',
    },
    PECA_PAGINA: {
      titulo: 'Consulta de Peças',
      descricao: 'Permite acesso ao Cadastro de Peças apenas para consulta',
    },
    CAL_PAGINA: {
      titulo: 'Consultar Calendário',
      descricao: 'Permite acesso ao Cadastro de Calendário apenas para consulta',
    },
    CAL_CRUD: {
      titulo: 'Cadastrar Calendário',
      descricao: 'Permite acesso ao Cadastro de Calendário para realização de cadastro',
    },
    CAL_VISUALIZAR: {
      titulo: 'Cadastrar Calendário',
      descricao: 'Permite acesso a visualização do calendário no toolbar',
    },
    PECA_CRUD: {
      titulo: 'Cadastro de Peças',
      descricao: 'Permite acesso ao Cadastro de Peças para realização de cadastro e edição de registros de Peças',
    },
    MATERIAL_PAGINA: {
      titulo: 'Consulta de Materais',
      descricao: 'Permite acesso ao Cadastro de Materiais apenas para consulta',
    },
    MATERIAL_CRUD: {
      titulo: 'Cadastro de Materiais',
      descricao: 'Permite acesso ao Cadastro de Materiais para realização de cadastro e edição de registros de Materiais',
    },
    DIVISAO_PAGINA: {
      titulo: 'Consulta de Divisão',
      descricao: 'Permite acesso ao Cadastro de Divisão apenas para consulta',
    },
    DIVISAO_CRUD: {
      titulo: 'Cadastro de Divisão',
      descricao: 'Permite acesso ao Cadastro de Divisão para realização de cadastro e edição de registros de Divisão',
    },
    APORTE_DIVISAO: {
      titulo: 'Aporte BASF',
      descricao: 'Permite realização de Aporte de Verbas BASF nas Divisões',
    },
    APORTE_CLIENTE: {
      titulo: 'Aporte Cliente',
      descricao: 'Permite realização de Aporte de Verbas para Clientes',
    },
    VERBA_MATERIAIS: {
      titulo: 'Verba Materiais',
      descricao: 'Permite realização de aporte, redução e transferência de verbas para matérias',
    },
    APORTE_INFINITO: {
      titulo: 'Aporte Infinito',
      descricao: 'Permite realização de Aporte de Verbas BASF nas Divisões sem limitar ao Saldo da Conta Corrente',
    },
    RED_DIVISAO: {
      titulo: 'Redução BASF',
      descricao: 'Permite realização de Redução de Verbas BASF nas Divisões',
    },
    REL_ACAO_PAGAMENTO: {
      titulo: 'Relatório Pagamento de Ações',
      descricao: 'Permite acesso ao relatório de Pagamento de Ações',
    },
    REL_ACAO_PGTO: {
      titulo: 'Relatório Ações para Pagamento',
      descricao: 'Permite acesso ao relatório de ações para pagamento',
    },
    REL_PROVISAO: {
      titulo: 'Relatório de Provisão',
      descricao: 'Permite acesso ao relatório de Provisão',
    },
    REL_EXTRATO_REBATE: {
      titulo: 'Relatório de Extrato de Rebate',
      descricao: 'Permite acesso ao relatório de Extrato de Rebate',
    },
    REL_SALDO: {
      titulo: 'Relatório de Saldo',
      descricao: 'Permite acesso ao relatório de Saldo de conta corrente',
    },
    REL_STATUS_ACAO: {
      titulo: 'Relatório Status das Ações',
      descricao: 'Permite acesso ao relatório Status das Ações',
    },
    REL_HOLDING_BLOQ: {
      titulo: 'Relatório Holdings Bloqueadas',
      descricao: 'Permite acesso ao relatório Holdings Bloqueadas',
    },
    REL_ACOES_DE_CONTRATO: {
      titulo: 'Relatório Ações de Contrato',
      descricao: 'Permite acesso ao relatório de ações de contrato',
    },
    LIQ_DTA_CONT_CRUD: {
      titulo: 'Alterar data de contabilização',
      descricao: 'Permite que o usuário informe a Data de Contabilização na aprovação da ação avulsa (planejamento ou apuração)',
    },
    LIQ_DTA_CONT_PAGINA: {
      titulo: 'Consultar nota de débito sem data de contabilização',
      descricao: 'Permite ao usuário consultar as notas de débito pendentes, ou seja, sem data de contabilização informada',
    },
    LIQ_ND_PAGINA: {
      titulo: 'Consultar Nota de débito',
      descricao: 'Permite acesso a tela de consulta de Nota de Débito',
    },
    MARGEM_ROI: {
      titulo: 'Margem ROI',
      descricao: 'Permite acesso a tela de margem ROI',
    },
    MARGEM_ROI_IMPORTAR: {
      titulo: 'Margem ROI Importar',
      descricao: 'Permite acesso ao módulo Importação Margem ROI',
    },
    MARGEM_ROI_EXPORTAR: {
      titulo: 'Margem ROI Exportar',
      descricao: 'Permite acesso ao módulo Exportação do Margem ROI',
    },
    NOTA_PAGINA: {
      titulo: 'Consultar e exportar dados de notas',
      descricao: 'Permite o acesso a tela de nota para consulta e exportação',
    },
    NOTA_CRUD: {
      titulo: 'Importar e resolver inconsistências de integração de notas',
      descricao: 'Permite o acesso a tela de nota para importação e resolução de inconsistências de integração',
    },
    RED_CLIENTE: {
      titulo: 'Redução Cliente',
      descricao: 'Permite realização de Redução de Verbas para Clientes',
    },
    TRANS_PAGINA: {
      titulo: 'Transferência Conta Corrente',
      descricao: 'Permite realização de Transferência de Verbas BASF entre as Divisões',
    },
    SLD_DIVISAO: {
      titulo: 'Consulta Saldo Conta Corrente BASF',
      descricao: 'Permite consulta ao Saldo de Conta Corrente de Verbas BASF das Divisões',
    },
    SLD_CLIENTE: {
      titulo: 'Consulta Saldo Conta Corrente Cliente',
      descricao: 'Permite consulta ao Saldo de Conta Corrente de Verbas dos Clientes',
    },
    EXT_DIVISAO: {
      titulo: 'Consulta Extrato Conta Corrente BASF',
      descricao: 'Permite consulta ao Extrato de Conta Corrente de Verbas BASF das Divisões',
    },
    EXT_CLIENTE: {
      titulo: 'Consulta Extrato Conta Corrente Cliente',
      descricao: 'Permite consulta ao Extrato de Conta Corrente de Verbas dos Clientes',
    },
    ANO_FISCAL: {
      titulo: 'Cadastro Período',
      descricao: 'Permite acesso ao processo de encerramento e cadastro de Períodos, Períodos de planejamento e Períodos orçamentários',
    },
    PERIODO_FISCAL: {
      titulo: 'Fechamento Período de Planejamento',
      descricao: 'Permite acesso ao processo de encerramento Períodos de Planejamento',
    },
    PLAN_PAGINA: {
      titulo: 'Visualizar Planejamento',
      descricao: 'Permite acesso ao Módulo Planejamento apenas para consulta',
    },
    PLAN_DIVISAO: {
      titulo: 'Criar Planejamento Verba BASF',
      descricao: 'Permite acesso ao Módulo Planejamento para Criação de Planejamento com Ações utilizando apenas verba BASF',
    },
    PLAN_CLIENTE: {
      titulo: 'Criar Planejamento Verba Cliente',
      descricao: 'Permite acesso ao Módulo Planejamento para Criação de Planejamento com Ações utilizando verba de CLIENTE',
    },
    PLAN_WORKFLOW: {
      titulo: 'Fluxo Aprovação Planejamento',
      descricao: 'Permite aprovar, reprovar e enviar para analise os planejamentos de acordo com a hierarquia do usuário.',
    },
    EXECUCAO_PAGINA: {
      titulo: 'Consulta de Execução',
      descricao: 'Permite acesso ao Módulo Execução apenas para consulta',
    },
    ORCAMENTO_PAGINA: {
      titulo: 'Visualizar Orçamento Execução',
      descricao: 'Permite acesso ao Módulo Orçamento Execução apenas para consulta',
    },
    ORCAMENTO_AGENCIA: {
      titulo: 'Orçamento Execução - Agência',
      descricao: 'Permite acesso ao Módulo Orçamento Execução para envio de Orçamentos e Layouts para aprovação - Acesso Agência',
    },
    ORCAMENTO_EMPRESA: {
      titulo: 'Orçamento Execução - BASF',
      descricao: 'Permite acesso ao Módulo Orçamento Execução para aprovação/reprovação de Orçamentos e Layouts - Acesso BASF',
    },
    GERAR_GRADE_MAT: {
      titulo: 'Gerar Grade Pedido de Materiais',
      descricao: 'Permite Geração de Grade de Pedidos de Materiais - Acesso Operador Logístico',
    },
    STS_ENTREGA_MAT: {
      titulo: 'Atualizar Status de Entrega Materiais',
      descricao: 'Permite Atualização de Status de Entrega de Pedidos de Materiais - Acesso Operador Logístico',
    },
    REPORTE_PAGINA: {
      titulo: 'Consulta de Reporte',
      descricao: 'Permite acesso ao Módulo Reporte apenas para consulta',
    },
    REPORTE_CRUD: {
      titulo: 'Cadastro de Reporte',
      descricao: 'Permite acesso ao Módulo Reporte para registro e edição de Reporte de Ação',
    },
    PGTO_CRUD_AGENCIA: {
      titulo: 'Cadastro de Pagamento - AGÊNCIA',
      descricao: 'Permite acesso ao Módulo Pagamento para registro de Pagamento de Ação Modalidade 2 e 3 - Acesso Agência',
    },
    PGTO_CRUD_EMPRESA: {
      titulo: 'Cadastro de Pagamento - BASF',
      descricao: 'Permite acesso ao Módulo Pagamento para registro de Pagamento de Ação Modalidade 1 e 2 - Acesso BASF',
    },
    REC_ACAO_CRUD: {
      titulo: 'Manutenção de Recebimento de Ação',
      descricao: 'Permite acesso ao módulo de recebimento de ação para alteração, cancelamento e solicitação de aprovação',
    },
    REC_ACAO_PAGINA: {
      titulo: 'Consulta de Recebimento de Ação',
      descricao: 'Permitr acesso ao módulo de recebimento de ação para consulta',
    },
    REL_USR_ACESSO: {
      titulo: 'Relatório Acesso de Usuário',
      descricao: 'Acesso ao relatório Acesso de Usuário',
    },
    REL_ANALITICO_ACOES: {
      titulo: 'Relatório Analítico de Ações',
      descricao: 'Permite acesso ao Relatório Analítico de Ações',
    },
    REL_INV_AREA: {
      titulo: 'Relatório Investimento por Área de Atuação',
      descricao: 'Permite acesso ao Relatório Investimento por Área de Atuação',
    },
    REL_INV_CLIENTE: {
      titulo: 'Relatório Investimento por Distribuidor/Cliente',
      descricao: 'Permite acesso ao Relatório Investimento por Cliente',
    },
    REL_INV_CULTIVO: {
      titulo: 'Relatório Investimento por Cultivo',
      descricao: 'Permite acesso ao Relatório Investimento por Cultivo',
    },
    REL_PERIODO: {
      titulo: 'Relatório Planejado x Realizado por Período',
      descricao: 'Permite acesso ao Relatório Planejado x Realizado por Período',
    },
    REL_INV_MATERIAIS: {
      titulo: 'Relatório Investimento por Materiais',
      descricao: 'Permite acesso ao Relatório Investimento por Materiais',
    },
    REL_INV_PECAS: {
      titulo: 'Relatório Investimento por Peças',
      descricao: 'Permite acesso ao Relatório Investimento por Peças',
    },
    REL_MAT_PRODUZIR: {
      titulo: 'Relatório Material a Produzir',
      descricao: 'Permite acesso ao Relatório Material a Produzir',
    },
    REL_PENDENCIAS: {
      titulo: 'Dashboard Pendências',
      descricao: 'Permite acesso as pendências no dashboard',
    },
    REL_PENDENCIAS_AGENCIA: {
      titulo: 'Dashboard Pendências Agência',
      descricao: 'Permite acesso as pendências da agência no dashboard',
    },
    TRADELINKS_ADMIN: {
      titulo: 'Acesso Administrador',
      descricao: 'Acesso a todas as divisões independente da hierarquia do usuário em telas como Redução, Planejamento, Relatório, transferência, entre outras.  ',
    },
    TRADELINKS_AGENCIA: {
      titulo: 'Acesso Agência',
      descricao: 'Acesso a todas as divisões independente da hierarquia do usuário (apenas para usuários do tipo agência).  ',
    },
    CLIENTE_PAGINA: {
      titulo: 'Consultar Cliente',
      descricao: 'Permite acesso ao Cadastro de Cliente apenas para consulta',
    },
    CLIENTE_CRUD: {
      titulo: 'Cadastrar Cliente',
      descricao: 'Permite acesso ao Cadastro de Cliente nas operações: Cadastrar / Importar / Exportar / Associar Loja / Associar Vendedor',
    },
    EXECUCAO_CRUD: {
      titulo: 'Alteração de status da ação',
      descricao: 'Permite acesso ao Módulo Planejamento para alteração do status de uma Ação',
    },
    CECO_PAGINA: {
      titulo: 'Consultar Centro de Custo',
      descricao: 'Permite acesso ao Cadastro de Centro de Custo apenas para consulta',
    },
    CECO_CRUD: {
      titulo: 'Cadastrar Centro de Custo',
      descricao: 'Permite acesso ao Cadastro de Centro de Custo para realização de cadastro',
    },
    ESTRUTURA_PAGINA: {
      titulo: 'Consultar Estrutura de Usuário',
      descricao: 'Permite acesso ao Cadastro de Estrutura de Usuário apenas para consulta',
    },
    ESTRUTURA_CRUD: {
      titulo: 'Cadastrar Estrutura de Usuário',
      descricao: 'Permite acesso ao Cadastro de Estrutura de Usuário para realização de cadastro',
    },
    USR_PAGINA: {
      titulo: 'Consultar Gestão de Usuário',
      descricao: 'Permite acesso ao Cadastro de Gestão de Usuário apenas para consulta',
    },
    USR_CRUD: {
      titulo: 'Cadastrar Gestão de Usuário',
      descricao: 'Permite acesso ao Cadastro de Gestão de Usuário para realização de cadastro',
    },
    USR_INSERIR: {
      titulo: 'Cadastrar Usuário',
      descricao: 'Permite acesso ao Cadastro de Gestão de Usuário para realização de cadastro',
    },
    USR_ATUALIZAR: {
      titulo: 'Atualização de Usuário',
      descricao: 'Permite acesso ao Módulo Gestão de Usuário para atualização de Usuário',
    },
    PRE_USR_CRUD: {
      titulo: 'Pré-cadastro de Usuários',
      descricao: 'Permite acesso ao Pré-cadastro de Usuários',
    },
    PARAM_PERIODO_CRUD: {
      titulo: 'Realizar a parametrização de período',
      descricao: 'Permite acesso a parametrização de período',
    },
    PARAM_PERIODO_PAGINA: {
      titulo: 'Visualizar a parametrização de período',
      descricao: 'Permite acesso a visualização de parametrização de período',
    },
    PRD_PAGINA: {
      titulo: 'Consultar Produto',
      descricao: 'Permite acesso ao Cadastro de Produto apenas para consulta',
    },
    PRD_CRUD: {
      titulo: 'Cadastrar Produto',
      descricao: 'Permite acesso ao Cadastro de Produto para realização de cadastro',
    },
    REGIAO_PAGINA: {
      titulo: 'Consultar Região',
      descricao: 'Permite acesso ao Cadastro de Região apenas para consulta',
    },
    REGIAO_CRUD: {
      titulo: 'Cadastrar Região',
      descricao: 'Permite acesso ao Cadastro de Região para realização de cadastro',
    },
    TERMO_ACEITE_PAGINA: {
      titulo: 'Consultar Termo de aceite',
      descricao: 'Permite acesso a tela de consulta de Termo de aceite',
    },
    TERMO_ACEITE_CRUD: {
      titulo: 'Cadastrar Termo de aceite',
      descricao: 'Permite acesso ao Cadastro de Termo de aceite nas operações: Cadastrar / Exportar / Editar / Inativar',
    },
    TIPO_LINHA_PAGINA: {
      titulo: 'Consultar Tipo de Linha de Orçamento',
      descricao: 'Permite acesso ao Cadastro de Tipo de Linha de Orçamento apenas para consulta',
    },
    TIPO_LINHA_CRUD: {
      titulo: 'Cadastrar Tipo de Linha de Orçamento',
      descricao: 'Permite acesso ao Cadastro de Tipo de Linha de Orçamento para realização de cadastro',
    },
    TIPO_ACAO_PAGINA: {
      titulo: 'Consultar Tipo de Ação',
      descricao: 'Permite acesso ao Cadastro de Tipo de Ação apenas para consulta',
    },
    TIPO_ACAO_CRUD: {
      titulo: 'Cadastrar Tipo de Ação',
      descricao: 'Permite acesso ao Cadastro de Tipo de Ação para realização de cadastro',
    },
    TIPO_ORC_PAGINA: {
      titulo: 'Consultar Tipo de Orçamento',
      descricao: 'Permite acesso ao Cadastro de Tipo de Orçamento apenas para consulta',
    },
    TIPO_ORC_CRUD: {
      titulo: 'Cadastrar Tipo de Orçamento',
      descricao: 'Permite acesso ao Cadastro de Tipo de Orçamento para realização de cadastro',
    },
    UNID_MEDIDA_PAGINA: {
      titulo: 'Consultar Unidade de Medida',
      descricao: 'Permite acesso ao Cadastro de Unidade de Medida apenas para consulta',
    },
    UNID_MEDIDA_CRUD: {
      titulo: 'Cadastrar Unidade de Medida',
      descricao: 'Permite acesso ao Cadastro de Unidade de Medida para realização de cadastro',
    },
    FLUXO_PAGINA: {
      titulo: 'Consultar Workflow',
      descricao: 'Permite acesso ao Cadastro de Wokflow apenas para consulta',
    },
    FLUXO_CRUD: {
      titulo: 'Cadastrar Workflow',
      descricao: 'Permite acesso ao Cadastro de Wokflow para realização de cadastro',
    },
    ALCADA_PAGINA: {
      titulo: 'Consultar Alçada',
      descricao: 'Permite acesso ao Cadastro de Alçada apenas para consulta',
    },
    ALCADA_CRUD: {
      titulo: 'Cadastrar Alçada',
      descricao: 'Permite acesso ao Cadastro de Alçada para realização de cadastro',
    },
    CONFIG_FLUXO: {
      titulo: 'Configurar Workflow de Aprovação',
      descricao: 'Permite acesso a alteração de configuações de Workflow de Aprovação',
    },
    CONFIG_NEG: {
      titulo: 'Configurar Negociação',
      descricao: 'Permite acesso a alteração de configuações de Negociação',
    },
    CONFIG_CALENDARIO: {
      titulo: 'Configurar Calendário de Aprovações',
      descricao: 'Permite acesso a alteração de configuações de Calendário de Aprovações',
    },
    CONFIG_ORC: {
      titulo: 'Configurar Orçamento',
      descricao: 'Permite acesso a alteração de configuações de Orçamento',
    },
    CONFIG_APURACAO: {
      titulo: 'Configurar Apuração',
      descricao: 'Permite acesso a alteração de configuações de Apuração',
    },
    CONFIG_PGTO: {
      titulo: 'Configurar Pagamento',
      descricao: 'Permite acesso a alteração de configuações de Pagamento',
    },
    DASH_ACOES: {
      titulo: 'Dashboard de Ações',
      descricao: 'Permite acesso ao Dashboard de Ações',
    },
    DASH_OPER_ORC: {
      titulo: 'Dashboard de Orçamento',
      descricao: 'Permite acesso ao Dashboard Operacional de Orçamento',
    },
    DASH_OPER_NEG: {
      titulo: 'Dashboard de Negociação',
      descricao: 'Permite acesso ao Dashboard Operacional de Negociação',
    },
    DASH_OPER_APURACAO: {
      titulo: 'Dashboard de Apuração',
      descricao: 'Permite acesso ao Dashboard Operacional de Apuração',
    },
    DASH_OPER_PGTO: {
      titulo: 'Dashboard de Pagamento',
      descricao: 'Permite acesso ao Dashboard Operacional de Pagamento',
    },
    DASH_OPER_CLIENTE: {
      titulo: 'Dashboard de Cliente',
      descricao: 'Permite acesso ao Dashboard Operacional de Cliente',
    },
    DASH_GER_ROI: {
      titulo: 'Dashboard ROI',
      descricao: 'Permite acesso ao Dashboard Gerencial ROI',
    },
    DASH_GER_FECHAMENTO: {
      titulo: 'Dashboard Gerencial - Fechamento',
      descricao: 'Permite acesso ao Dashboard Gerencial Fechamento',
    },
    DASH_GER_PLANO_COMPARATIVO: {
      titulo: 'Dashboard Plano Comparativo',
      descricao: 'Permite acesso ao Dashboard Gerencial Plano Comparativo',
    },
    DASH_GER_PART_INVESTIMENTO: {
      titulo: 'Dashboard Participação sobre Investimento',
      descricao: 'Permite acesso ao Dashboard Gerencial Participação sobre Investimento',
    },
    DASH_GER_ANALISE_INVESTIMENTO: {
      titulo: 'Dashboard Análise de Investimento',
      descricao: 'Permite acesso ao Dashboard Gerencial Plano Análise de Investimento',
    },
    DASH_GER_RELATORIOS: {
      titulo: 'Dashboard Relatórios',
      descricao: 'Permite acesso ao Dashboard Gerencial Relatórios',
    },
    DASH_GER_RELATORIOS_FIN: {
      titulo: 'Dashboard Relatórios Financeiros',
      descricao: 'Permite acesso aos relatórios financeiros do Dashboard Gerencial Relatórios',
    },
    DASH_GER_ACOMPANHAMENTO: {
      titulo: 'Dashboard Acompanhamento',
      descricao: 'Permite acesso ao Dashboard Gerencial Acompanhamento',
    },
    ORC_PAGINA: {
      titulo: 'Consultar Orçamento',
      descricao: 'Permite acesso ao módulo Orçamento apenas para consulta',
    },
    ORC_CRUD: {
      titulo: 'Cadastrar Orçamento',
      descricao: 'Permite acesso ao módulo Orçamento para cadastro de um novo Orçamento',
    },
    ORC_RATEIO: {
      titulo: 'Rateio de Orçamento',
      descricao: 'Permite acesso ao módulo Orçamento para realização de Rateio',
    },
    ORC_RATEIO_ADD_VEND: {
      titulo: 'Inclusão de vendedor em rateio',
      descricao: 'Permite acesso a funcionalidade de incluir um novo vendedor em um orçamento que já foi aprovado',
    },
    ORC_TRANSFERENCIA: {
      titulo: 'Transferência de Orçamento',
      descricao: 'Permite acesso ao módulo Orçamento para realização de Transferência de Verba',
    },
    ORC_AJUSTE: {
      titulo: 'Ajuste de Verba de Orçamento',
      descricao: 'Permite acesso ao módulo Orçamento para realização de Adendo e Redução de Verba',
    },
    NEG_PAGINA: {
      titulo: 'Consultar Negociação',
      descricao: 'Permite acesso ao módulo Negociação apenas para consulta',
    },
    NEG_CRUD: {
      titulo: 'Cadastrar/Cancelar Negociação',
      descricao: 'Permite acesso ao módulo Negociação para realização de Cadastro e Cancelamento',
    },
    NEG_ENCERRAR: {
      titulo: 'Encerrar Negociação',
      descricao: 'Permite acesso ao módulo Negociação para realização de Encerramento',
    },
    NEG_IMPRIMIR: {
      titulo: 'Imprimir Negociação',
      descricao: 'Permite acesso ao módulo Negociação para realização de Impressão',
    },
    APU_CONTR_PAGINA: {
      titulo: 'Consultar apuração de contrato',
      descricao: 'Permite ao usuário acesso ao módulo de Apuração de Contrato para consulta e acompanhamento',
    },
    APU_CONTR_CRUD: {
      titulo: 'Alterar e manter apuração de contrato',
      descricao: 'Permite ao usuário acesso ao módulo de Apuração de Contrato para edição e conclusão',
    },
    APU_CRUD: {
      titulo: 'Apuração - Edição',
      descricao: 'Permite acesso ao módulo Apuração para Edição',
    },
    APU_DOWNLOAD: {
      titulo: 'Apuração - Download Evidência',
      descricao: 'Permite acesso ao módulo Apuração para Download de arquivo para Execução de Meta',
    },
    APU_PAGINA: {
      titulo: 'Apuração - Consulta',
      descricao: 'Permite acesso ao módulo Apuração apenas para consulta',
    },
    APU_UPLOAD: {
      titulo: 'Apuração - Upload Evidência',
      descricao: 'Permite acesso ao módulo Apuração para Upload de arquivo para Execução de Meta',
    },
    PGTO_PAGINA: {
      titulo: 'Consultar Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento apenas para consulta',
    },
    PGTO_CRUD: {
      titulo: 'Alterar e manter Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento para Edição',
    },
    PGTO_EXPORTAR: {
      titulo: 'Exportar Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento para Exportação de Pagamento',
    },
    PGTO_IMPORTAR: {
      titulo: 'Importar Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento para Importação de Pagamento',
    },
    PGTO_CANCELAR: {
      titulo: 'Cancelar Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento para Cancelamento de Pagamento',
    },
    PGTO_RESGATAR: {
      titulo: 'Resgatar Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento para Resgate de Pagamento',
    },
    PGTO_REENVIAR: {
      titulo: 'Reenviar Pagamento',
      descricao: 'Permite acesso ao módulo Pagamento para Reenvio de Pagamento',
    },
    REL_CONTA_CORRENTE: {
      titulo: 'Relatório Conta Corrente',
      descricao: 'Permite acesso ao Relatório Conta Corrente',
    },
    REL_CLI_CONTA_CORRENTE: {
      titulo: 'Relatório Cliente Conta Corrente',
      descricao: 'Permite acesso ao Relatório Cliente Conta Corrente',
    },
    REL_PAGAMENTO: {
      titulo: 'Relatório Pagamento',
      descricao: 'Permite acesso ao Relatório Pagamento',
    },
    REL_SINTETICO_ACOES: {
      titulo: 'Relatório Sintético de Ações',
      descricao: 'Permite acesso ao Relatório Sintético de Ações',
    },
    SELLIN_PAGINA: {
      titulo: 'Consultar Sell In',
      descricao: 'Permite acesso ao módulo Sell In apenas para consulta',
    },
    SELLIN_IMPORTAR: {
      titulo: 'Importar Notas',
      descricao: 'Permite acesso ao módulo de Notas para realizar Importação',
    },
    SELLIN_EXPORTAR: {
      titulo: 'Exportar Sell In',
      descricao: 'Permite acesso ao módulo Sell In para realizar Exportação de Sell In',
    },
    SELLOUT_PAGINA: {
      titulo: 'Consultar Sell Out',
      descricao: 'Permite acesso ao módulo Sell Out apenas para consulta',
    },
    SELLOUT_IMPORTAR: {
      titulo: 'Importar Sell Out',
      descricao: 'Permite acesso ao módulo Sell Out para realizar Importação de Sell Out',
    },
    SELLOUT_EXPORTAR: {
      titulo: 'Exportar Sell Out',
      descricao: 'Permite acesso ao módulo Sell Out para realizar Exportação de Sell Out',
    },
    PARAM_ACAO_CRUD: {
      titulo: 'Realizar a parametrização de ação',
      descricao: 'Permite acesso a parametrização de Ação',
    },
    PARAM_CLIENTE_CRUD: {
      titulo: 'Realizar a parametrização de cliente',
      descricao: 'Permite acesso a parametrizacao de Cliente',
    },
    PARAM_CONTRATO_CRUD: {
      titulo: 'Realizar a parametrização de contrato',
      descricao: 'Permite acesso a parametrizacao de Contrato',
    },
    PARAM_ORC_CRUD: {
      titulo: 'Realizar parametrização de orçamento',
      descricao: 'Permite acesso a parametrização de Orçamento',
    },
    PEDIDOS_IMPORTAR: {
      titulo: 'Importar Pedidos',
      descricao: 'Permite acesso ao módulo de Notas para realizar Importação de Pedidos',
    },
    PERFIL_PAGINA: {
      titulo: 'Consultar Perfil',
      descricao: 'Permite acesso ao Cadastro de Perfil apenas para consulta',
    },
    PERFIL_CRUD: {
      titulo: 'Cadastrar Perfil',
      descricao: 'Permite acesso ao Cadastro de Perfil para realização de cadastro',
    },
    TRANS_POSSE: {
      titulo: 'Transferência de Posse',
      descricao: 'Acesso a Transferência de Posse',
    },
    TRANS_POSSE_DIV: {
      titulo: 'Transferência de Posse Divisão',
      descricao: 'Acesso a Transferência de Posse de Divisão',
    },
    TRANS_POSSE_FUN: {
      titulo: 'Transferência de Posse Funcionário',
      descricao: 'Acesso a Transferência de Posse de Funcionário',
    },
    TRANS_POSSE_DEF: {
      titulo: 'Transferência de Posse Definitiva',
      descricao: 'Acesso a Transferência de Posse Definitiva',
    },
    NIVEL_CLIENTE_PAGINA: {
      titulo: 'Consultar Nível de Cliente',
      descricao: 'Permite acesso ao Cadastro de Nível de Cliente apenas para consulta',
    },
    NIVEL_CLIENTE_CRUD: {
      titulo: 'Cadastrar Nível de Cliente',
      descricao: 'Permite acesso ao Cadastro de Nível de Cliente para realização de cadastro e edição de registros de Nível de Cliente',
    },
    NIVEL_FORNECEDOR_PAGINA: {
      titulo: 'Consultar Nível de Fornecedor',
      descricao: 'Permite acesso ao Cadastro de Nível de Fornecedor apenas para consulta',
    },
    NIVEL_FORNECEDOR_CRUD: {
      titulo: 'Cadastrar Nível de Fornecedor',
      descricao: 'Permite acesso ao Cadastro de Nível de Fornecedor para realização de cadastro e edição de registros de Nível de Fornecedor',
    },
    NIVEL_PRD_PAGINA: {
      titulo: 'Consultar Nível de Produto',
      descricao: 'Permite acesso ao Cadastro de Nível de Produto apenas para consulta',
    },
    NIVEL_PRD_CRUD: {
      titulo: 'Cadastrar Nível de Produto',
      descricao: 'Permite acesso ao Cadastro de Nível de Produto para realização de cadastro e edição de registros de Nível de Produto',
    },
    ORC_DETALHES: {
      titulo: 'Detalhes do Orçamento',
      descricao: 'Permite acesso ao módulo Orçamento para visualizar detalhes',
    },
    ORC_RATEIO_ADD_DIV: {
      titulo: 'Inclusão de divisão em orçamento aprovado',
      descricao: 'Permite acesso a funcionalidade de incluir uma nova Divisão em um orçamento que já foi aprovado',
    },
    CONFIG_CAMPOS_CUSTOMIZADOS: {
      titulo: 'Configurar Campos Customizados',
      descricao: 'Permite acesso ao Cadastro de Campos Customizados',
    },
    TIPO_INVEST_PAGINA: {
      titulo: 'Consultar Tipo de Investimento',
      descricao: 'Permite acesso ao Cadastro de Tipo de Investimento apenas para consulta',
    },
    TIPO_INVEST_CRUD: {
      titulo: 'Cadastrar Tipo de Investimento',
      descricao: 'Permite acesso ao Cadastro de Tipo de Investimento para realização de cadastro',
    },
    TRANS_POSSE_CRUD: {
      titulo: 'Cadastrar Transferência de Posse',
      descricao: 'Permite acesso a Transferência de Posse apenas para realização de cadastro',
    },
    TRANS_POSSE_PAGINA: {
      titulo: 'Consultar Transferência de Posse',
      descricao: 'Permite acesso a Transferência de Posse apenas para consulta',
    },
    FORN_PAGINA: {
      titulo: 'Consultar Fornecedor',
      descricao: 'Permite acesso ao Cadastro de Fornecedor apenas para consulta',
    },
    FORN_CRUD: {
      titulo: 'Cadastrar Fornecedor',
      descricao: 'Permite acesso ao Cadastro de Fornecedor para criar ou editar um fornecedor via importação',
    },
    DASH_VAREJO: {
      titulo: 'Acessar o Dashboard inicial na visão do varejo',
      descricao: 'Permite o acesso ao Dashboard Inicial para acompanhamento dos recebimentos e pendências de ações avulsas e contratos',
    },
    DASH_INDUSTRIA: {
      titulo: 'Acessar o Dashboard inicial na visão da indústria',
      descricao: 'Permite o acesso ao Dashboard Inicial para acompanhamento dos pagamentos e pendências de ações avulsas e contratos',
    },
    CONCORRENTE_PAGINA: {
      titulo: 'Consultar Concorrente',
      descricao: 'Permite acesso ao Cadastro de Concorrente apenas para consulta',
    },
    CONCORRENTE_CRUD: {
      titulo: 'Cadastrar Concorrente',
      descricao: 'Permite acesso ao Cadastro de Concorrente para realização de cadastro',
    },
    REL_CONTRATO: {
      titulo: 'Relatório de Contrato',
      descricao: 'Permite acesso aos Relatórios de Contrato',
    },
    SIMULADOR: {
      titulo: 'Simulador',
      descricao: 'Permite acesso ao Simulador',
    },
    REL_EXPORTACAO: {
      titulo: 'Relatório de Exportação',
      descricao: 'Permite acesso aos Relatórios de Exportação',
    },
    PAGAMENTO_MANUAL_CRUD: {
      titulo: 'Cadastrar pagamento manual',
      descricao: 'Permite ao usuário acesso ao módulo de criação de pagamento manual',
    },
    CTRT_PGTO_PAGINA: {
      titulo: 'Consultar pagamento de contrato',
      descricao: 'Permite ao usuário o acesso ao módulo de pagamento de contrato para consulta e acompanhamento',
    },
    CTRT_PGTO_CRUD: {
      titulo: 'Alterar e manter pagamento de contrato',
      descricao: 'Permite ao usuário o acesso módulo de pagamento de contrato para edição',
    },
    ALTERAR_DTA_APU_CONTRATO: {
      titulo: 'Alterar data fim das apurações de ações de Contrato',
      descricao: 'Permite ao usuário alterar a data de fim da apuração de ações do contrato',
    },
  },
  configuracao: {
    // TELA CONFIGURACAO - WORKFLOW APROVACAO
    APROVAR_ACORDO: 'Habilitar Aprovação Acordo',
    APROVAR_ACORDO_DESCRICAO: '(Cadastrar e alterar Acordo)',
    APROVAR_ADENDO_REDUCAO: 'Habilitar Adendos e Reduções',
    APROVAR_ADENDO_REDUCAO_DESCRICAO: '(Realizar e aprovar Adendos e Reduções)',
    APROVAR_ADITIVO_ORCAMENTO: 'Habilitar Aditivos de Orçamento',
    APROVAR_ADITIVO_ORCAMENTO_DESCRICAO: '(Realizar e aprovar Aditivos de Orçamento)',
    APROVAR_APURACAO: 'Habilitar Aprovação de Apuração',
    APROVAR_APURACAO_DESCRICAO: '(Realizar e aprovar Apurações)',
    APROVAR_APURACAO_REINVIDICACAO: 'Habilitar Aprovação de Reivindicação Apuração',
    APROVAR_APURACAO_REINVIDICACAO_DESCRICAO: '(Realizar e aprovar Reivindicação de Apurações)',
    APROVAR_CONTRATO: 'Habilitar Aprovação Contrato',
    APROVAR_CONTRATO_DESCRICAO: '(Cadastrar e alterar Contratos)',
    APROVAR_PLANO_ACAO: 'Habilitar Aprovação Plano de Ação',
    APROVAR_PLANO_ACAO_DESCRICAO: '(Cadastrar e alterar Plano de Ação)',
    APROVAR_ORCAMENTO: 'Habilitar Aprovação de Orçamento (Aprovar Orçamento)',
    APROVAR_PAGAMENTO: 'Habilitar Aprovação de Pagamento',
    APROVAR_PAGAMENTO_DESCRICAO: '(Aprovar e reprovar pagamentos)',
    APROVAR_TRANSFERENCIA: 'Habilitar Aprovação de Transferência',
    APROVAR_TRANSFERENCIA_DESCRICAO: '(Aprovar e reprovar Transferências)',
    AUTOMATICA: 'Automática',
    AUTOMATICA_DESCRICAO: '',
    HABILITAR_CONTROLE_ALCADAS_PASSOS: 'Habilitar controle de alçadas nos passos',
    // TELA CONFIGURACAO - APURACAO
    SELLOUT: 'Sellout',
    SELLOUT_DESCRICAO: '',
    SELLIN: 'Sellin',
    SELLIN_DESCRICAO: '',
    EVIDENCIA_OBRIGATORIA_SELLOUT: 'Arquivo de evidência obrigatório?',
    HABILITAR_UPLOAD_SELLIN: 'Habilitar upload de evidência',
    EVIDENCIA_OBRIGATORIA_SELLIN: 'Arquivo de evidência obrigatório na ausência de comprovação no Sellin?',
    // TELA CONFIGURACAO - ORCAMENTO
    MODULO_ORCAMENTO: 'Orçamento',
    MODULO_ORCAMENTO_DESCRICAO: '(Informar se o módulo de orçamento será utilizado pelo sistema)',
    MODULO_ORCAMENTO_TOGGLE: 'Ativar o módulo de orçamento',
    MANUAL: 'Manual',
    MANUAL_DESCRICAO: '',
    RATEIO: 'Rateio',
    RATEIO_DESCRICAO: '(Informar se o rateio de orçamento será automático)',
    RATEIO_TOGGLE: 'Ativar rateio automático',
    // TELA CONFIGURACAO - PAGAMENTO
    SAP: 'SAP',
    SAP_DESCRICAO: '(Informar se o envio para o SAP será automático)',
    SAP_TOGGLE: 'Ativar envio para SAP automático',
    // TELA CONFIGURACAO - CONTRATO
    CRESCIMENTO: 'Crescimento',
    CRESCIMENTO_DESCRICAO: '(Definir metas de crescimento para os Clientes)',
    HABILITAR_UPLOAD_SELLOUT: 'Habilitar upload de SellOut',
    SORTIMENTO: 'Sortimento',
    SORTIMENTO_DESCRICAO: '',
    VISIBILIDADE: 'Visibilidade',
    VISIBILIDADE_DESCRICAO: '',
    COMPRA_MINIMA: 'Compra Mínima',
    COMPRA_MINIMA_DESCRICAO: '',
    EVIDENCIA: 'Evidência',
    EVIDENCIA_DESCRICAO: '',
    EVIDENCIA_OBRIGATORIA: 'Arquivo de evidência obrigatório?',
    NEGOCIACAO_PRECO: 'Negociação de Preço',
    NEGOCIACAO_PRECO_DESCRICAO: 'Negociação de Preço',
    REBAIXA: 'Rebaixa',
    REBAIXA_DESCRICAO: '',
    TABLOIDE: 'Tablóide',
    TABLOIDE_DESCRICAO: '',
    POSITIVACAO: 'Positivação',
    POSITIVACAO_DESCRICAO: '',
    ABERTURA_INFORMACAO: 'Abertura de Informação',
    ABERTURA_INFORMACAO_DESCRICAO: '',
    FIXO: 'Fixo',
    FIXO_DESCRICAO: '(Valor informado pelo usuário)',
    DOCUMENTO_EVIDENCIA_OBRIGATORIO: 'Documento de evidência obrigatório',
    LOJA_AUDITADA: 'Lojas Auditadas',
    LOJA_AUDITADA_DESCRICAO: '(Selecionar lojas que serão auditadas)',
    SENHA_TEMPORARIA: 'Senha temporária para novos usuários.',
    VALOR_LIQUIDO: 'Valor líquido',
    VALOR_LIQUIDO_DESCRICAO: '',
    VALOR_BRUTO: 'Valor Bruto',
    VALOR_BRUTO_DESCRICAO: '',
    calendario_aprovacoes: {
      PLANEJAMENTO_DESCRICAO: 'Planejamento',
      APURACAO_DESCRICAO: 'Apuração',
      ADENDO_REDUCAO_DESCRICAO: 'Adendo/Redução',
      COLETA_DESCRICAO: 'Coleta',
      PAGAMENTOS_DESCRICAO: 'Pagamentos',
    },
    META_ATE: {
      EDITAVEL: 'Editável',
      EDITAVEL_DESCRICAO: '(Informado pelo usuário)',
      INFINITA: 'Infinita',
      INFINITA_DESCRICAO: '(Campo Meta até não será apresentado no cadastro das metas, havendo apenas um percentual mínimo a set atingido)',
    },
    VALOR_BASE_CRESCIMENTO: {
      FIXO: 'Fixo',
      FIXO_DESCRICAO: '(Valor informado pelo usuário)',
      CALCULADO_SISTEMA: 'Calculado pelo sistema',
      CALCULADO_SISTEMA_DESCRICAO: '(Informação buscada no Sell In ou Sell Out)',
    },
    ROI: {
      BASE_CALCULO: {
        VALOR_BRUTO: 'Valor Bruto',
        VALOR_BRUTO_DESCRICAO: '',
        VALOR_LIQUIDO: 'Valor Líquido',
        VALOR_LIQUIDO_DESCRICAO: '',
      },
      HABILITAR_CALCULO_AUTOMATICO_ROI: 'Habilitar cálculo automático do ROI  ',
      HABILITAR_CALCULO_AUTOMATICO_ROI_DESCRICAO: '',
      PERIODO_HISTORICO_MESES: 'Período histórico de meses',
      PERIODO_HISTORICO_MESES_DESCRICAO: '',
      BASE_CALCULO_VR_BRUTO: 'Base de cálculo Valor Bruto',
      BASE_CALCULO_VR_BRUTO_DESCRICAO: '',
      BASE_CALCULO_VR_LIQUIDO: 'Base de cálculo Valor Líquido',
      BASE_CALCULO_VR_LIQUIDO_DESCRICAO: '',
    },
    BASE_CALCULO: {
      VALOR_BRUTO: 'Valor Bruto',
      VALOR_BRUTO_DESCRICAO: '',
      VALOR_LIQUIDO: 'Valor Líquido',
      VALOR_LIQUIDO_DESCRICAO: '',
      DESCONTO_NOTA: 'Desconto em nota',
      DESCONTO_NOTA_DESCRICAO: '',
      PAGAMENTO_MOEDA: 'Pagamento em moeda',
      PAGAMENTO_MOEDA_DESCRICAO: '',
      BONIFICACAO_PRODUTOS: 'Bonificação em produtos',
      BONIFICACAO_PRODUTOS_DESCRICAO: '',
      CREDITO: 'Crédito',
      CREDITO_DESCRICAO: '',
    },
    BONIFICACAO: {
      VALOR_FIXO: 'Valor Fixo',
      VALOR_FIXO_DESCRICAO: '',
      VALOR_VARIAVEL: 'Valor Variável',
      VALOR_VARIAVEL_DESCRICAO: '',
      PERCENTUAL: 'Percentual',
      PERCENTUAL_DESCRICAO: '',
    },
    METAS_BENEFICIOS: {
      SELLIN_VALOR: 'Sell In Valor',
      SELLIN_VALOR_DESCRICAO: '(Informações de valores monetários do Sell In)',
      SELLIN_VOLUME: 'Sell In Volume',
      SELLIN_VOLUME_DESCRICAO: '(Informações referentes ao volume do Sell In)',
      SELLOUT_VALOR: 'Sell Out Valor',
      SELLOUT_VALOR_DESCRICAO: '(Informações de valores monetários do Sell Out)',
      SELLOUT_VOLUME: 'Sell Out Volume',
      SELLOUT_VOLUME_DESCRICAO: '(Informações referentes ao volume do Sell Out)',
      SELLOUT: 'Sell Out',
      SELLOUT_DESCRICAO: '',
    },
    TIPO_PAGAMENTO: {
      DESCONTO_NOTA: 'Desconto em nota',
      DESCONTO_NOTA_DESCRICAO: '',
      DESCONTO_CONTA: 'Desconto em conta',
      DESCONTO_CONTA_DESCRICAO: '',
      DEPOSITO_CONTA: 'Depósito em conta',
      DEPOSITO_CONTA_DESCRICAO: '',
      BONIFICACAO_PRODUTOS: 'Bonificação em produtos',
      BONIFICACAO_PRODUTOS_DESCRICAO: '',
      CREDITO: 'Crédito',
      CREDITO_DESCRICAO: '',
    },
  },
  historico: {
    ADENDO: 'Adendo por ',
    AJUSTE: 'Ajustado por ',
    ALTERACAO: 'Alteração por ',
    APROVACAO: 'Aprovação por ',
    CANCELAMENTO: 'Cancelamento por ',
    DEDUCAO: 'Dedução por ',
    ENCERRAMENTO: 'Encerrado por ',
    ENVIO_APROVACAO: 'Envio para aprovação por ',
    ENVIO_ANALISE: 'Envio para análise por ',
    INSERCAO: 'Inclusão por ',
    REPROVACAO: 'Reprovação por ',
    TRANSFERENCIA_ADENDO: 'Transferência adendo por ',
    TRANSFERENCIA_DEDUCAO: 'Transferência dedução por ',
    SOLICITA_APROVACAO: 'Envio para aprovação por ',
  },
  status_apuracao_pagamento: {
    AGUARDANDO_NF: 'Aguardando NF',
    AGUARDANDO_CONFIRMACAO_RECEBIMENTO: 'Aguardando a confirmação de recebimento',
    ENVIADO_RECEBIMENTO: 'Enviado ao recebimento',
    FINALIZADO: 'Finalizado',
  },
  status_entidade: {
    apurado: 'Apurado',
    aguardando_analise: 'Aguardando análise',
    aguardando_aprovacao: 'Aguardando aprovação',
    aguardando_apuracao: 'Aguardando apuração',
    aguardando_pagamento: 'Aguardando pagamento',
    aprovado: 'Aprovado | Aprovados',
    apuracao_previa: 'Apuração prévia',
    bloqueado: 'Bloqueado',
    cancelada: 'Cancelada',
    cancelado: 'Cancelado',
    concluido: 'Concluído',
    confirmado_acao: 'Confirmado Ação',
    condicao_nao_atendida: 'Condição não atendida',
    em_alteracao: 'Em alteração',
    em_analise: 'Em análise',
    em_cadastro: 'Em cadastro',
    em_execucao: 'Em execução',
    em_fluxo: 'Em fluxo',
    em_reivindicacao: 'Em reivindicação',
    encerrado: 'Encerrado',
    executado: 'Executado',
    iniciado: 'Iniciado',
    liquidado: 'Liquidado',
    nao_iniciado: 'Não iniciado',
    nao_liquidado: 'Não liquidado',
    novo: 'Novo',
    orcamento_em_cadastro: 'Orçamento em cadastro',
    orcamento_aguardando_aprovacao: 'Orçamento aguardando aprovação',
    orcamento_em_analise: 'Orçamento em análise',
    pagamento_em_andamento: 'Pagamento em andamento',
    pagamento_aguardando_aprovacao: 'Pagamento aguardando aprovação',
    pagamento_aguardando_ajuste: 'Pagamento aguardando ajuste',
    pagamento_concluido: 'Pagamento concluído',
    pendente: 'Pendente',
    programado: 'Programado',
    reivindicado: 'Reivindicado',
    reprovada: 'Reprovada',
    reprovado: 'Reprovado | Reprovados',
    transferido: 'Transferido',
    vencido: 'Vencido',
  },
  status_entidade_pagamento: {
    aguardando_aprovacao: 'Aguardando aprovação',
    aguardando_fluxo: 'Aguardando Fluxo',
    aguardando_pagamento: 'Aguardando pagamento',
    aprovado: 'Aprovado | Aprovados',
    bloqueado: 'Bloqueado',
    cancelado: 'Cancelado',
    concluido: 'Concluído',
    em_analise: 'Em análise',
    em_cadastro: 'Em cadastro',
    iniciado: 'Iniciado',
    liquidado: 'Liquidado',
    liquidado_pendente: 'Liquidado',
    nao_iniciado: 'Não iniciado',
    nao_liquidado: 'Não liquidado',
    programado: 'Programado',
    reprovado: 'Reprovado | Reprovados',
  },
  tipos_pagamentos: {
    DESCONTO_NOTA: 'Desconto em nota',
    DEPOSITO_CONTA: 'Depósito em conta',
    DEPOSITO_CONTA_FISICA: 'Depósito em conta física',
    DEPOSITO_CONTA_JURIDICA: 'Depósito em conta jurídica',
    BONIFICACAO_PRODUTOS: 'Bonificação em produtos',
    CREDITO: 'Crédito',
    DOACAO: 'Doação',
    ENCONTRO_CONTAS: 'Encontro de contas',
    BOLETO: 'Boleto',
    AVISO_CREDITO: 'Aviso de crédito',
    NOTA_CREDITO: 'Nota de Crédito',
    ORDEM_INTERNA: 'Ordem Interna',
    TERMO_QUITACAO: 'Termo de quitação',
  },
  status_item_pagamento: {
    NAO_INICIADO: 'Não iniciado',
    INICIADO: 'Iniciado',
    AGUARDANDO_APROVACAO: 'Aguardando pagamento | Aguardando aprovação',
    APROVADO: 'Em andamento',
    CANCELADO: 'Cancelado',
    CONCLUIDO: 'Pagamento concluído',
    EM_ANALISE: 'Documentos em Análise | Aguardando Alteração',
  },
  status_integracao: {
    INTEGRADO: 'Integrado',
    PENDENTE: 'Pendente',
    PENDENTE_INTEGRACAO: 'Pendente',
    ERRO: 'Erro',
  },
  status_pedido: {
    EM_ROTA: 'Em rota',
    ENTREGUE: 'Entregue',
  },
  meses: {
    '01': 'Janeiro',
    '02': 'Fevereiro',
    '03': 'Março',
    '04': 'Abril',
    '05': 'Maio',
    '06': 'Junho',
    '07': 'Julho',
    '08': 'Agosto',
    '09': 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro',
  },
  motivo_entrega: {
    UMA_REENTREGA: '1 Reentrega',
    DUAS_REENTREGAS: '2 Reentregas',
    TRES_REENTREGAS: '3 Reentregas',
    DEVOLUCAO: 'Devolução',
  },
  tipo_usuario: {
    CLIENTE: 'Cliente',
    FUNCIONARIO: 'Funcionário',
    FORNECEDOR: 'Fornecedor',
  },
  registro_acesso: {
    logou: 'Logou no período',
    nao_logou: 'Não logou no período',
  },
  periodo_apuracao: {
    anual: 'Anual',
    mensal: 'Mensal',
    trimestral: 'Trimestral',
    unica_vez: 'Única vez',
  },
};
